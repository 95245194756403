define("ember-leaflet/index", ["exports", "ember-leaflet/components/circle-layer", "ember-leaflet/components/circle-marker-layer", "ember-leaflet/components/geojson-layer", "ember-leaflet/components/image-layer", "ember-leaflet/components/leaflet-map", "ember-leaflet/components/marker-layer", "ember-leaflet/components/polygon-layer", "ember-leaflet/components/polyline-layer", "ember-leaflet/components/popup-layer", "ember-leaflet/components/tile-layer", "ember-leaflet/components/tooltip-layer", "ember-leaflet/components/wms-tile-layer", "ember-leaflet/macros/to-lat-lng"], function (_exports, _circleLayer, _circleMarkerLayer, _geojsonLayer, _imageLayer, _leafletMap, _markerLayer, _polygonLayer, _polylineLayer, _popupLayer, _tileLayer, _tooltipLayer, _wmsTileLayer, _toLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CircleLayer", {
    enumerable: true,
    get: function () {
      return _circleLayer.default;
    }
  });
  Object.defineProperty(_exports, "CircleMarkerLayer", {
    enumerable: true,
    get: function () {
      return _circleMarkerLayer.default;
    }
  });
  Object.defineProperty(_exports, "GeojsonLayer", {
    enumerable: true,
    get: function () {
      return _geojsonLayer.default;
    }
  });
  Object.defineProperty(_exports, "ImageLayer", {
    enumerable: true,
    get: function () {
      return _imageLayer.default;
    }
  });
  Object.defineProperty(_exports, "LeafletMap", {
    enumerable: true,
    get: function () {
      return _leafletMap.default;
    }
  });
  Object.defineProperty(_exports, "MarkerLayer", {
    enumerable: true,
    get: function () {
      return _markerLayer.default;
    }
  });
  Object.defineProperty(_exports, "PolygonLayer", {
    enumerable: true,
    get: function () {
      return _polygonLayer.default;
    }
  });
  Object.defineProperty(_exports, "PolylineLayer", {
    enumerable: true,
    get: function () {
      return _polylineLayer.default;
    }
  });
  Object.defineProperty(_exports, "PopupLayer", {
    enumerable: true,
    get: function () {
      return _popupLayer.default;
    }
  });
  Object.defineProperty(_exports, "TileLayer", {
    enumerable: true,
    get: function () {
      return _tileLayer.default;
    }
  });
  Object.defineProperty(_exports, "TooltipLayer", {
    enumerable: true,
    get: function () {
      return _tooltipLayer.default;
    }
  });
  Object.defineProperty(_exports, "WmsTileLayer", {
    enumerable: true,
    get: function () {
      return _wmsTileLayer.default;
    }
  });
  Object.defineProperty(_exports, "toLatLng", {
    enumerable: true,
    get: function () {
      return _toLatLng.default;
    }
  });
  _exports.Leaflet = _exports.L = void 0;
  const L = window.L || {};
  _exports.Leaflet = _exports.L = L;
});
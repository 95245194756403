define("ember-leaflet/components/image-layer", ["exports", "ember-leaflet/components/interactive-layer"], function (_exports, _interactiveLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['url', 'bounds']),
    leafletOptions: Object.freeze(['opacity', 'alt', 'crossOrigin', 'errorOverlayUrl', 'zIndex', 'className']),
    leafletProperties: Object.freeze(['url', 'opacity', 'bounds']),
    leafletEvents: Object.freeze(['load', 'error']),

    init() {
      let imageUrl = this.get('imageUrl');

      if (Ember.isPresent(imageUrl)) {
        (true && !(false) && Ember.deprecate('ember-leaflet/image-layer: The `imageUrl` attribute has been deprecated in favor of the observed attribute `url`.', false, {
          id: 'ember-leaflet.image-layer.imageUrl',
          until: '4.0.0',
          url: 'https://github.com/miguelcobain/ember-leaflet/pull/143'
        }));
        this.set('url', imageUrl);
      }

      this._super(...arguments);
    },

    createLayer() {
      return this.L.imageOverlay(...this.get('requiredOptions'), this.get('options'));
    }

  });

  _exports.default = _default;
});
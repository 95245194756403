define("ember-leaflet/templates/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ANVIcfhA",
    "block": "{\"symbols\":[\"components\",\"c\",\"&default\"],\"statements\":[[4,\"let\",[[29,\"hash\",null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"wmsTile\",\"geoJSON\"],[[29,\"component\",[\"tile-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"marker-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"circle-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"circle-marker-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"image-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"video-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"polyline-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"polygon-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[24,0,[]]]]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"ember-leaflet-assign-to\",[[24,1,[]]],[[\"key\",\"value\",\"onChange\"],[[24,2,[\"as\"]],[29,\"component\",[[24,2,[\"name\"]]],[[\"parentComponent\"],[[24,0,[]]]]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"mergedComponents\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[15,3,[[25,[\"mergedComponents\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[15,3,[[24,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/leaflet-map.hbs"
    }
  });

  _exports.default = _default;
});
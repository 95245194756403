define("ember-leaflet/helpers/ember-leaflet-assign-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberLeafletAssignTo = emberLeafletAssignTo;
  _exports.default = void 0;

  function emberLeafletAssignTo([object], {
    key,
    value,
    onChange
  }) {
    object[key] = value; // we need to send out the assigned object because ember freezes helper arguments

    onChange(object);
  }

  var _default = Ember.Helper.helper(emberLeafletAssignTo);

  _exports.default = _default;
});
enifed("@ember/-internals/glimmer", ["exports", "node-module", "@ember/-internals/owner", "@glimmer/opcode-compiler", "@ember/-internals/runtime", "@ember/-internals/utils", "@glimmer/reference", "@ember/-internals/metal", "@ember/debug", "@glimmer/runtime", "@glimmer/util", "@ember/-internals/views", "@ember/-internals/browser-environment", "@ember/instrumentation", "@ember/polyfills", "@ember/service", "@ember/-internals/environment", "@ember/string", "@glimmer/wire-format", "@ember/-internals/container", "@ember/runloop", "rsvp", "@glimmer/node", "@ember/-internals/routing", "@ember/deprecated-features"], function (_exports, _nodeModule, _owner, _opcodeCompiler, _runtime, _utils, _reference, _metal, _debug, _runtime2, _util, _views, _browserEnvironment, _instrumentation, _polyfills, _service, _environment2, _string, _wireFormat, _container, _runloop, _rsvp, _node, _routing, _deprecatedFeatures) {
  "use strict";

  _exports.template = template;
  _exports.helper = helper;
  _exports.escapeExpression = escapeExpression;
  _exports.htmlSafe = htmlSafe;
  _exports.isHTMLSafe = isHTMLSafe;
  _exports._resetRenderers = _resetRenderers;
  _exports.renderSettled = renderSettled;
  _exports.getTemplate = getTemplate;
  _exports.setTemplate = setTemplate;
  _exports.hasTemplate = hasTemplate;
  _exports.getTemplates = getTemplates;
  _exports.setTemplates = setTemplates;
  _exports.setupEngineRegistry = setupEngineRegistry;
  _exports.setupApplicationRegistry = setupApplicationRegistry;
  _exports._registerMacros = registerMacros;
  _exports.iterableFor = iterableFor;
  _exports.capabilities = capabilities;
  _exports.setComponentManager = setComponentManager;
  _exports.getComponentManager = getComponentManager;
  _exports.setModifierManager = setModifierManager;
  _exports.getModifierManager = getModifierManager;
  _exports.modifierCapabilties = capabilities$1;
  Object.defineProperty(_exports, "DOMChanges", {
    enumerable: true,
    get: function () {
      return _runtime2.DOMChanges;
    }
  });
  Object.defineProperty(_exports, "DOMTreeConstruction", {
    enumerable: true,
    get: function () {
      return _runtime2.DOMTreeConstruction;
    }
  });
  Object.defineProperty(_exports, "isSerializationFirstNode", {
    enumerable: true,
    get: function () {
      return _runtime2.isSerializationFirstNode;
    }
  });
  Object.defineProperty(_exports, "NodeDOMTreeConstruction", {
    enumerable: true,
    get: function () {
      return _node.NodeDOMTreeConstruction;
    }
  });
  _exports.OutletView = _exports.DebugStack = _exports.INVOKE = _exports.UpdatableReference = _exports.AbstractComponentManager = _exports._experimentalMacros = _exports.InteractiveRenderer = _exports.InertRenderer = _exports.Renderer = _exports.SafeString = _exports.Environment = _exports.Helper = _exports.ROOT_REF = _exports.Component = _exports.LinkComponent = _exports.TextArea = _exports.TextField = _exports.Checkbox = _exports.RootTemplate = void 0;

  function _templateObject10() {
    const data = _taggedTemplateLiteralLoose(["component:-default"]);

    _templateObject10 = function () {
      return data;
    };

    return data;
  }

  function _templateObject9() {
    const data = _taggedTemplateLiteralLoose(["template-compiler:main"]);

    _templateObject9 = function () {
      return data;
    };

    return data;
  }

  function _templateObject8() {
    const data = _taggedTemplateLiteralLoose(["template-compiler:main"]);

    _templateObject8 = function () {
      return data;
    };

    return data;
  }

  function _templateObject7() {
    const data = _taggedTemplateLiteralLoose(["template-compiler:main"]);

    _templateObject7 = function () {
      return data;
    };

    return data;
  }

  function _templateObject6() {
    const data = _taggedTemplateLiteralLoose(["template:components/-default"]);

    _templateObject6 = function () {
      return data;
    };

    return data;
  }

  function _templateObject5() {
    const data = _taggedTemplateLiteralLoose(["template:-root"]);

    _templateObject5 = function () {
      return data;
    };

    return data;
  }

  function _templateObject4() {
    const data = _taggedTemplateLiteralLoose(["template:-root"]);

    _templateObject4 = function () {
      return data;
    };

    return data;
  }

  function _templateObject3() {
    const data = _taggedTemplateLiteralLoose(["component:-default"]);

    _templateObject3 = function () {
      return data;
    };

    return data;
  }

  function _templateObject2() {
    const data = _taggedTemplateLiteralLoose(["template:components/-default"]);

    _templateObject2 = function () {
      return data;
    };

    return data;
  }

  function _templateObject() {
    const data = _taggedTemplateLiteralLoose(["template:components/-default"]);

    _templateObject = function () {
      return data;
    };

    return data;
  }

  function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

  function template(json) {
    return new FactoryWrapper((0, _opcodeCompiler.templateFactory)(json));
  }

  class FactoryWrapper {
    constructor(factory) {
      this.factory = factory;
      this.id = factory.id;
      this.meta = factory.meta;
    }

    create(injections) {
      const owner = (0, _owner.getOwner)(injections);
      return this.factory.create(injections.compiler, {
        owner
      });
    }

  }

  var RootTemplate = template({
    "id": "T+MWx6cw",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[24,0,[]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/root.hbs"
    }
  });
  /**
  @module @ember/component
  */

  _exports.RootTemplate = RootTemplate;
  const RECOMPUTE_TAG = (0, _utils.symbol)('RECOMPUTE_TAG');

  function isHelperFactory(helper) {
    return typeof helper === 'object' && helper !== null && helper.class && helper.class.isHelperFactory;
  }

  function isSimpleHelper(helper) {
    return helper.destroy === undefined;
  }
  /**
    Ember Helpers are functions that can compute values, and are used in templates.
    For example, this code calls a helper named `format-currency`:
  
    ```handlebars
    <div>{{format-currency cents currency="$"}}</div>
    ```
  
    Additionally a helper can be called as a nested helper (sometimes called a
    subexpression). In this example, the computed value of a helper is passed
    to a component named `show-money`:
  
    ```handlebars
    {{show-money amount=(format-currency cents currency="$")}}
    ```
  
    Helpers defined using a class must provide a `compute` function. For example:
  
    ```app/helpers/format-currency.js
    import Helper from '@ember/component/helper';
  
    export default Helper.extend({
      compute([cents], { currency }) {
        return `${currency}${cents * 0.01}`;
      }
    });
    ```
  
    Each time the input to a helper changes, the `compute` function will be
    called again.
  
    As instances, these helpers also have access to the container and will accept
    injected dependencies.
  
    Additionally, class helpers can call `recompute` to force a new computation.
  
    @class Helper
    @public
    @since 1.13.0
  */


  let Helper = _runtime.FrameworkObject.extend({
    init() {
      this._super(...arguments);

      this[RECOMPUTE_TAG] = _reference.DirtyableTag.create();
    },

    /**
      On a class-based helper, it may be useful to force a recomputation of that
      helpers value. This is akin to `rerender` on a component.
         For example, this component will rerender when the `currentUser` on a
      session service changes:
         ```app/helpers/current-user-email.js
      import Helper from '@ember/component/helper'
      import { inject as service } from '@ember/service'
      import { observer } from '@ember/object'
         export default Helper.extend({
        session: service(),
        onNewUser: observer('session.currentUser', function() {
          this.recompute();
        }),
        compute() {
          return this.get('session.currentUser.email');
        }
      });
      ```
         @method recompute
      @public
      @since 1.13.0
    */
    recompute() {
      this[RECOMPUTE_TAG].inner.dirty();
    }

  });

  _exports.Helper = Helper;
  Helper.isHelperFactory = true;

  class Wrapper {
    constructor(compute) {
      this.compute = compute;
      this.isHelperFactory = true;
    }

    create() {
      // needs new instance or will leak containers
      return {
        compute: this.compute
      };
    }

  }
  /**
    In many cases, the ceremony of a full `Helper` class is not required.
    The `helper` method create pure-function helpers without instances. For
    example:
  
    ```app/helpers/format-currency.js
    import { helper } from '@ember/component/helper';
  
    export default helper(function(params, hash) {
      let cents = params[0];
      let currency = hash.currency;
      return `${currency}${cents * 0.01}`;
    });
    ```
  
    @static
    @param {Function} helper The helper function
    @method helper
    @for @ember/component/helper
    @public
    @since 1.13.0
  */


  function helper(helperFn) {
    return new Wrapper(helperFn);
  }

  function toBool(predicate) {
    if ((0, _runtime.isArray)(predicate)) {
      return predicate.length !== 0;
    } else {
      return Boolean(predicate);
    }
  }

  const UPDATE = (0, _utils.symbol)('UPDATE');
  const INVOKE = (0, _utils.symbol)('INVOKE');
  _exports.INVOKE = INVOKE;
  const ACTION = (0, _utils.symbol)('ACTION');

  class EmberPathReference {
    get(key) {
      return PropertyReference.create(this, key);
    }

  }

  class CachedReference$1 extends EmberPathReference {
    constructor() {
      super();
      this.lastRevision = null;
      this.lastValue = null;
    }

    value() {
      let {
        tag,
        lastRevision,
        lastValue
      } = this;

      if (lastRevision === null || !tag.validate(lastRevision)) {
        lastValue = this.lastValue = this.compute();
        this.lastRevision = tag.value();
      }

      return lastValue;
    }

  }

  class RootReference extends _reference.ConstReference {
    constructor(value) {
      super(value);
      this.children = Object.create(null);
    }

    static create(value) {
      return valueToRef(value);
    }

    get(propertyKey) {
      let ref = this.children[propertyKey];

      if (ref === undefined) {
        ref = this.children[propertyKey] = new RootPropertyReference(this.inner, propertyKey);
      }

      return ref;
    }

  }

  let TwoWayFlushDetectionTag;

  if (true
  /* DEBUG */
  ) {
      TwoWayFlushDetectionTag = class TwoWayFlushDetectionTag {
        constructor(tag, key, ref) {
          this.tag = tag;
          this.key = key;
          this.ref = ref;
          this.parent = null;
        }

        static create(tag, key, ref) {
          return new _reference.TagWrapper(tag.type, new TwoWayFlushDetectionTag(tag, key, ref));
        }

        value() {
          return this.tag.value();
        }

        validate(ticket) {
          let {
            parent,
            key,
            ref
          } = this;
          let isValid = this.tag.validate(ticket);

          if (isValid && parent) {
            (0, _metal.didRender)(parent, key, ref);
          }

          return isValid;
        }

        didCompute(parent) {
          this.parent = parent;
          (0, _metal.didRender)(parent, this.key, this.ref);
        }

      };
    }

  class PropertyReference extends CachedReference$1 {
    static create(parentReference, propertyKey) {
      if ((0, _reference.isConst)(parentReference)) {
        return valueKeyToRef(parentReference.value(), propertyKey);
      } else {
        return new NestedPropertyReference(parentReference, propertyKey);
      }
    }

    get(key) {
      return new NestedPropertyReference(this, key);
    }

  }

  class RootPropertyReference extends PropertyReference {
    constructor(parentValue, propertyKey) {
      super();
      this.parentValue = parentValue;
      this.propertyKey = propertyKey;

      if (false
      /* EMBER_METAL_TRACKED_PROPERTIES */
      ) {
          this.propertyTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
        } else {
        this.propertyTag = _reference.UpdatableTag.create((0, _metal.tagForProperty)(parentValue, propertyKey));
      }

      if (true
      /* DEBUG */
      ) {
          this.tag = TwoWayFlushDetectionTag.create(this.propertyTag, propertyKey, this);
        } else {
        this.tag = this.propertyTag;
      }

      if (true
      /* DEBUG */
      ) {
          (0, _metal.watchKey)(parentValue, propertyKey);
        }
    }

    compute() {
      let {
        parentValue,
        propertyKey
      } = this;

      if (true
      /* DEBUG */
      ) {
          this.tag.inner.didCompute(parentValue);
        }

      let parent = null;
      let tracker = null;

      if (false
      /* EMBER_METAL_TRACKED_PROPERTIES */
      ) {
          parent = (0, _metal.getCurrentTracker)();
          tracker = (0, _metal.setCurrentTracker)();
        }

      let ret = (0, _metal.get)(parentValue, propertyKey);

      if (false
      /* EMBER_METAL_TRACKED_PROPERTIES */
      ) {
          (0, _metal.setCurrentTracker)(parent);
          let tag = tracker.combine();
          if (parent) parent.add(tag);
          this.propertyTag.inner.update(tag);
        }

      return ret;
    }

    [UPDATE](value) {
      (0, _metal.set)(this.parentValue, this.propertyKey, value);
    }

  }

  class NestedPropertyReference extends PropertyReference {
    constructor(parentReference, propertyKey) {
      super();
      this.parentReference = parentReference;
      this.propertyKey = propertyKey;
      let parentReferenceTag = parentReference.tag;

      let propertyTag = this.propertyTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);

      if (true
      /* DEBUG */
      ) {
          let tag = (0, _reference.combine)([parentReferenceTag, propertyTag]);
          this.tag = TwoWayFlushDetectionTag.create(tag, propertyKey, this);
        } else {
        this.tag = (0, _reference.combine)([parentReferenceTag, propertyTag]);
      }
    }

    compute() {
      let {
        parentReference,
        propertyTag,
        propertyKey
      } = this;

      let _parentValue = parentReference.value();

      let parentValueType = typeof _parentValue;

      if (parentValueType === 'string' && propertyKey === 'length') {
        return _parentValue.length;
      }

      if (parentValueType === 'object' && _parentValue !== null || parentValueType === 'function') {
        let parentValue = _parentValue;

        if (true
        /* DEBUG */
        ) {
            (0, _metal.watchKey)(parentValue, propertyKey);
          }

        if (true
        /* DEBUG */
        ) {
            this.tag.inner.didCompute(parentValue);
          }

        let parent = null;
        let tracker = null;

        if (false
        /* EMBER_METAL_TRACKED_PROPERTIES */
        ) {
            parent = (0, _metal.getCurrentTracker)();
            tracker = (0, _metal.setCurrentTracker)();
          }

        let ret = (0, _metal.get)(parentValue, propertyKey);

        if (false
        /* EMBER_METAL_TRACKED_PROPERTIES */
        ) {
            (0, _metal.setCurrentTracker)(parent);
            let tag = tracker.combine();
            if (parent) parent.add(tag);
            propertyTag.inner.update(tag);
          } else {
          propertyTag.inner.update((0, _metal.tagForProperty)(parentValue, propertyKey));
        }

        return ret;
      } else {
        return undefined;
      }
    }

    [UPDATE](value) {
      (0, _metal.set)(this.parentReference.value()
      /* let the other side handle the error */
      , this.propertyKey, value);
    }

  }

  class UpdatableReference extends EmberPathReference {
    constructor(value) {
      super();
      this.tag = _reference.DirtyableTag.create();
      this._value = value;
    }

    value() {
      return this._value;
    }

    update(value) {
      let {
        _value
      } = this;

      if (value !== _value) {
        this.tag.inner.dirty();
        this._value = value;
      }
    }

  }

  _exports.UpdatableReference = UpdatableReference;

  class ConditionalReference$1 extends _runtime2.ConditionalReference {
    static create(reference) {
      if ((0, _reference.isConst)(reference)) {
        let value = reference.value();

        if (!(0, _utils.isProxy)(value)) {
          return _runtime2.PrimitiveReference.create(toBool(value));
        }
      }

      return new ConditionalReference$1(reference);
    }

    constructor(reference) {
      super(reference);
      this.objectTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
      this.tag = (0, _reference.combine)([reference.tag, this.objectTag]);
    }

    toBool(predicate) {
      if ((0, _utils.isProxy)(predicate)) {
        this.objectTag.inner.update((0, _metal.tagForProperty)(predicate, 'isTruthy'));
        return Boolean((0, _metal.get)(predicate, 'isTruthy'));
      } else {
        this.objectTag.inner.update((0, _metal.tagFor)(predicate));
        return toBool(predicate);
      }
    }

  }

  class SimpleHelperReference extends CachedReference$1 {
    constructor(helper$$1, args) {
      super();
      this.helper = helper$$1;
      this.args = args;
      this.tag = args.tag;
    }

    static create(helper$$1, args) {
      if ((0, _reference.isConst)(args)) {
        let {
          positional,
          named
        } = args;
        let positionalValue = positional.value();
        let namedValue = named.value();

        if (true
        /* DEBUG */
        ) {
            (0, _debug.debugFreeze)(positionalValue);
            (0, _debug.debugFreeze)(namedValue);
          }

        let result = helper$$1(positionalValue, namedValue);
        return valueToRef(result);
      } else {
        return new SimpleHelperReference(helper$$1, args);
      }
    }

    compute() {
      let {
        helper: helper$$1,
        args: {
          positional,
          named
        }
      } = this;
      let positionalValue = positional.value();
      let namedValue = named.value();

      if (true
      /* DEBUG */
      ) {
          (0, _debug.debugFreeze)(positionalValue);
          (0, _debug.debugFreeze)(namedValue);
        }

      return helper$$1(positionalValue, namedValue);
    }

  }

  class ClassBasedHelperReference extends CachedReference$1 {
    constructor(instance, args) {
      super();
      this.instance = instance;
      this.args = args;
      this.tag = (0, _reference.combine)([instance[RECOMPUTE_TAG], args.tag]);
    }

    static create(instance, args) {
      return new ClassBasedHelperReference(instance, args);
    }

    compute() {
      let {
        instance,
        args: {
          positional,
          named
        }
      } = this;
      let positionalValue = positional.value();
      let namedValue = named.value();

      if (true
      /* DEBUG */
      ) {
          (0, _debug.debugFreeze)(positionalValue);
          (0, _debug.debugFreeze)(namedValue);
        }

      return instance.compute(positionalValue, namedValue);
    }

  }

  class InternalHelperReference extends CachedReference$1 {
    constructor(helper$$1, args) {
      super();
      this.helper = helper$$1;
      this.args = args;
      this.tag = args.tag;
    }

    compute() {
      let {
        helper: helper$$1,
        args
      } = this;
      return helper$$1(args);
    }

  }

  class UnboundReference extends _reference.ConstReference {
    static create(value) {
      return valueToRef(value, false);
    }

    get(key) {
      return valueToRef(this.inner[key], false);
    }

  }

  class ReadonlyReference extends CachedReference$1 {
    constructor(inner) {
      super();
      this.inner = inner;
      this.tag = inner.tag;
    }

    get [INVOKE]() {
      return this.inner[INVOKE];
    }

    compute() {
      return this.inner.value();
    }

    get(key) {
      return this.inner.get(key);
    }

  }

  function referenceFromParts(root, parts) {
    let reference = root;

    for (let i = 0; i < parts.length; i++) {
      reference = reference.get(parts[i]);
    }

    return reference;
  }

  function isObject(value) {
    return value !== null && typeof value === 'object';
  }

  function isFunction(value) {
    return typeof value === 'function';
  }

  function isPrimitive(value) {
    if (true
    /* DEBUG */
    ) {
        let type = typeof value;
        return value === undefined || value === null || type === 'boolean' || type === 'number' || type === 'string';
      } else {
      return true;
    }
  }

  function valueToRef(value, bound = true) {
    if (isObject(value)) {
      // root of interop with ember objects
      return bound ? new RootReference(value) : new UnboundReference(value);
    } else if (isFunction(value)) {
      // ember doesn't do observing with functions
      return new UnboundReference(value);
    } else if (isPrimitive(value)) {
      return _runtime2.PrimitiveReference.create(value);
    } else if (true
    /* DEBUG */
    ) {
        let type = typeof value;
        let output;

        try {
          output = String(value);
        } catch (e) {
          output = null;
        }

        if (output) {
          throw (0, _util.unreachable)("[BUG] Unexpected " + type + " (" + output + ")");
        } else {
          throw (0, _util.unreachable)("[BUG] Unexpected " + type);
        }
      } else {
      throw (0, _util.unreachable)();
    }
  }

  function valueKeyToRef(value, key) {
    if (isObject(value)) {
      // root of interop with ember objects
      return new RootPropertyReference(value, key);
    } else if (isFunction(value)) {
      // ember doesn't do observing with functions
      return new UnboundReference(value[key]);
    } else if (isPrimitive(value)) {
      return _runtime2.UNDEFINED_REFERENCE;
    } else if (true
    /* DEBUG */
    ) {
        let type = typeof value;
        let output;

        try {
          output = String(value);
        } catch (e) {
          output = null;
        }

        if (output) {
          throw (0, _util.unreachable)("[BUG] Unexpected " + type + " (" + output + ")");
        } else {
          throw (0, _util.unreachable)("[BUG] Unexpected " + type);
        }
      } else {
      throw (0, _util.unreachable)();
    }
  }

  const DIRTY_TAG = (0, _utils.symbol)('DIRTY_TAG');
  const ARGS = (0, _utils.symbol)('ARGS');
  const ROOT_REF = (0, _utils.symbol)('ROOT_REF');
  _exports.ROOT_REF = ROOT_REF;
  const IS_DISPATCHING_ATTRS = (0, _utils.symbol)('IS_DISPATCHING_ATTRS');
  const HAS_BLOCK = (0, _utils.symbol)('HAS_BLOCK');
  const BOUNDS = (0, _utils.symbol)('BOUNDS');
  /**
  @module @ember/component
  */

  /**
    A component is an isolated piece of UI, represented by a template and an
    optional class. When a component has a class, its template's `this` value
    is an instance of the component class.
  
    ## Template-only Components
  
    The simplest way to create a component is to create a template file in
    `app/templates/components`. For example, if you name a template
    `app/templates/components/person-profile.hbs`:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
    <img src={{@person.avatar}}>
    <p class='signature'>{{@person.signature}}</p>
    ```
  
    You will be able to use `<PersonProfile />` to invoke this component elsewhere
    in your application:
  
    ```app/templates/application.hbs
    <PersonProfile @person={{this.currentUser}} />
    ```
  
    Note that component names are capitalized here in order to distinguish them
    from regular HTML elements, but they are dasherized in the file system.
  
    While the angle bracket invocation form is generally preferred, it is also
    possible to invoke the same component with the `{{person-profile}}` syntax:
  
    ```app/templates/application.hbs
    {{person-profile person=this.currentUser}}
    ```
  
    Note that with this syntax, you use dashes in the component name and
    arguments are passed without the `@` sign.
  
    In both cases, Ember will render the content of the component template we
    created above. The end result will be something like this:
  
    ```html
    <h1>Tomster</h1>
    <img src="https://emberjs.com/tomster.jpg">
    <p class='signature'>Out of office this week</p>
    ```
  
    ## File System Nesting
  
    Components can be nested inside sub-folders for logical groupping. For
    example, if we placed our template in
    `app/templates/components/person/short-profile.hbs`, we can invoke it as
    `<Person::ShortProfile />`:
  
    ```app/templates/application.hbs
    <Person::ShortProfile @person={{this.currentUser}} />
    ```
  
    Or equivalently, `{{person/short-profile}}`:
  
    ```app/templates/application.hbs
    {{person/short-profile person=this.currentUser}}
    ```
  
    ## Yielding Contents
  
    You can use `yield` inside a template to include the **contents** of any block
    attached to the component. The block will be executed in its original context:
  
    ```handlebars
    <PersonProfile @person={{this.currentUser}}>
      <p>Admin mode</p>
      {{! Executed in the current context. }}
    </PersonProfile>
    ```
  
    or
  
    ```handlebars
    {{#person-profile person=this.currentUser}}
      <p>Admin mode</p>
      {{! Executed in the current context. }}
    {{/person-profile}}
    ```
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
    {{yield}}
    ```
  
    ## Customizing Components With JavaScript
  
    If you want to customize the component in order to handle events, transform
    arguments or maintain internal state, you implement a subclass of `Component`.
  
    One example is to add computed properties to your component:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      displayName: computed('person.title', 'person.firstName', 'person.lastName', function() {
        let { title, firstName, lastName } = this;
  
        if (title) {
          return `${title} ${lastName}`;
        } else {
          return `${firstName} ${lastName};
        }
      })
    });
    ```
  
    And then use it in the component's template:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{this.displayName}}</h1>
    {{yield}}
    ```
  
    ## Customizing a Component's HTML Element in JavaScript
  
    ### HTML Tag
  
    The default HTML tag name used for a component's HTML representation is `div`.
    This can be customized by setting the `tagName` property.
  
    Consider the following component class:
  
    ```app/components/emphasized-paragraph.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'em'
    });
    ```
  
    When invoked, this component would produce output that looks something like
    this:
  
    ```html
    <em id="ember1" class="ember-view"></em>
    ```
  
    ### HTML `class` Attribute
  
    The HTML `class` attribute of a component's tag can be set by providing a
    `classNames` property that is set to an array of strings:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNames: ['my-class', 'my-other-class']
    });
    ```
  
    Invoking this component will produce output that looks like this:
  
    ```html
    <div id="ember1" class="ember-view my-class my-other-class"></div>
    ```
  
    `class` attribute values can also be set by providing a `classNameBindings`
    property set to an array of properties names for the component. The return
    value of these properties will be added as part of the value for the
    components's `class` attribute. These properties can be computed properties:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
    import { computed } from '@ember/object';
  
    export default Component.extend({
      classNames: ['my-class', 'my-other-class'],
      classNameBindings: ['propertyA', 'propertyB'],
  
      propertyA: 'from-a',
      propertyB: computed(function() {
        if (someLogic) { return 'from-b'; }
      })
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view my-class my-other-class from-a from-b"></div>
    ```
  
    Note that `classNames` and `classNameBindings` is in addition to the `class`
    attribute passed with the angle bracket invocation syntax. Therefore, if this
    component was invoked like so:
  
    ```handlebars
    <MyWidget class="from-invocation" />
    ```
  
    The resulting HTML will look similar to this:
  
    ```html
    <div id="ember1" class="from-invocation ember-view my-class my-other-class from-a from-b"></div>
    ```
  
    If the value of a class name binding returns a boolean the property name
    itself will be used as the class name if the property is true. The class name
    will not be added if the value is `false` or `undefined`.
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['hovered'],
  
      hovered: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view hovered"></div>
    ```
  
    ### Custom Class Names for Boolean Values
  
    When using boolean class name bindings you can supply a string value other
    than the property name for use as the `class` HTML attribute by appending the
    preferred value after a ":" character when defining the binding:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['awesome:so-very-cool'],
  
      awesome: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view so-very-cool"></div>
    ```
  
    Boolean value class name bindings whose property names are in a
    camelCase-style format will be converted to a dasherized format:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['isUrgent'],
  
      isUrgent: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view is-urgent"></div>
    ```
  
    Class name bindings can also refer to object values that are found by
    traversing a path relative to the component itself:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
    import EmberObject from '@ember/object';
  
    export default Component.extend({
      classNameBindings: ['messages.empty'],
  
      messages: EmberObject.create({
        empty: true
      })
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view empty"></div>
    ```
  
    If you want to add a class name for a property which evaluates to true and
    and a different class name if it evaluates to false, you can pass a binding
    like this:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      classNameBindings: ['isEnabled:enabled:disabled'],
      isEnabled: true
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view enabled"></div>
    ```
  
    When isEnabled is `false`, the resulting HTML representation looks like this:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    This syntax offers the convenience to add a class if a property is `false`:
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    // Applies no class when isEnabled is true and class 'disabled' when isEnabled is false
    export default Component.extend({
      classNameBindings: ['isEnabled::disabled'],
      isEnabled: true
    });
    ```
  
    Invoking this component when the `isEnabled` property is true will produce
    HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view"></div>
    ```
  
    Invoking it when the `isEnabled` property on the component is `false` will
    produce HTML that looks like:
  
    ```html
    <div id="ember1" class="ember-view disabled"></div>
    ```
  
    Updates to the value of a class name binding will result in automatic update
    of the  HTML `class` attribute in the component's rendered HTML
    representation. If the value becomes `false` or `undefined` the class name
    will be removed.
  
    Both `classNames` and `classNameBindings` are concatenated properties. See
    [EmberObject](/api/ember/release/classes/EmberObject) documentation for more
    information about concatenated properties.
  
    ### Other HTML Attributes
  
    The HTML attribute section of a component's tag can be set by providing an
    `attributeBindings` property set to an array of property names on the component.
    The return value of these properties will be used as the value of the component's
    HTML associated attribute:
  
    ```app/components/my-anchor.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'a',
      attributeBindings: ['href'],
  
      href: 'http://google.com'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    One property can be mapped on to another by placing a ":" between
    the source property and the destination property:
  
    ```app/components/my-anchor.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'a',
      attributeBindings: ['url:href'],
  
      url: 'http://google.com'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <a id="ember1" class="ember-view" href="http://google.com"></a>
    ```
  
    HTML attributes passed with angle bracket invocations will take precedence
    over those specified in `attributeBindings`. Therefore, if this component was
    invoked like so:
  
    ```handlebars
    <MyAnchor href="http://bing.com" @url="http://google.com" />
    ```
  
    The resulting HTML will looks like this:
  
    ```html
    <a id="ember1" class="ember-view" href="http://bing.com"></a>
    ```
  
    Note that the `href` attribute is ultimately set to `http://bing.com`,
    despite it having attribute binidng to the `url` property, which was
    set to `http://google.com`.
  
    Namespaced attributes (e.g. `xlink:href`) are supported, but have to be
    mapped, since `:` is not a valid character for properties in Javascript:
  
    ```app/components/my-use.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'use',
      attributeBindings: ['xlinkHref:xlink:href'],
  
      xlinkHref: '#triangle'
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <use xlink:href="#triangle"></use>
    ```
  
    If the value of a property monitored by `attributeBindings` is a boolean, the
    attribute will be present or absent depending on the value:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
  
      disabled: false
    });
    ```
  
    Invoking this component will produce HTML that looks like:
  
    ```html
    <input id="ember1" class="ember-view" />
    ```
  
    `attributeBindings` can refer to computed properties:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
    import { computed } from '@ember/object';
  
    export default Component.extend({
      tagName: 'input',
      attributeBindings: ['disabled'],
  
      disabled: computed(function() {
        if (someLogic) {
          return true;
        } else {
          return false;
        }
      })
    });
    ```
  
    To prevent setting an attribute altogether, use `null` or `undefined` as the
    value of the property used in `attributeBindings`:
  
    ```app/components/my-text-input.js
    import Component from '@ember/component';
  
    export default Component.extend({
      tagName: 'form',
      attributeBindings: ['novalidate'],
      novalidate: null
    });
    ```
  
    Updates to the property of an attribute binding will result in automatic
    update of the  HTML attribute in the component's HTML output.
  
    `attributeBindings` is a concatenated property. See
    [EmberObject](/api/ember/release/classes/EmberObject) documentation for more
    information about concatenated properties.
  
    ## Layouts
  
    The `layout` property can be used to dynamically specify a template associated
    with a component class, instead of relying on Ember to link together a
    component class and a template based on file names.
  
    In general, applications should not use this feature, but it's commonly used
    in addons for historical reasons.
  
    The `layout` property should be set to the default export of a template
    module, which is the name of a template file without the `.hbs` extension.
  
    ```app/templates/components/person-profile.hbs
    <h1>Person's Title</h1>
    <div class='details'>{{yield}}</div>
    ```
  
    ```app/components/person-profile.js
      import Component from '@ember/component';
      import layout from '../templates/components/person-profile';
  
      export default Component.extend({
        layout
      });
    ```
  
    If you invoke the component:
  
    ```handlebars
    <PersonProfile>
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    </PersonProfile>
    ```
  
    or
  
    ```handlebars
    {{#person-profile}}
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    {{/person-profile}}
    ```
  
    It will result in the following HTML output:
  
    ```html
    <h1>Person's Title</h1>
      <div class="details">
      <h2>Chief Basket Weaver</h2>
      <h3>Fisherman Industries</h3>
    </div>
    ```
  
    ## Handling Browser Events
  
    Components can respond to user-initiated events in one of two ways: adding
    event handler methods to the component's class, or adding actions to the
    component's template.
  
    ### Event Handler Methods
  
    Components can respond to user-initiated events by implementing a method that
    matches the event name. An event object will be passed as the argument to this
    method.
  
    ```app/components/my-widget.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click(event) {
        // `event.target` is either the component's element or one of its children
        let tag = event.target.tagName.toLowerCase();
        console.log('clicked on a `<${tag}>` HTML element!');
      }
    });
    ```
  
    In this example, whenever the user clicked anywhere inside the component, it
    will log a message to the console.
  
    It is possible to handle event types other than `click` by implementing the
    following event handler methods. In addition, custom events can be registered
    by using `Application.customEvents`.
  
    Touch events:
  
    * `touchStart`
    * `touchMove`
    * `touchEnd`
    * `touchCancel`
  
    Keyboard events:
  
    * `keyDown`
    * `keyUp`
    * `keyPress`
  
    Mouse events:
  
    * `mouseDown`
    * `mouseUp`
    * `contextMenu`
    * `click`
    * `doubleClick`
    * `mouseMove`
    * `focusIn`
    * `focusOut`
    * `mouseEnter`
    * `mouseLeave`
  
    Form events:
  
    * `submit`
    * `change`
    * `focusIn`
    * `focusOut`
    * `input`
  
    Drag and drop events:
  
    * `dragStart`
    * `drag`
    * `dragEnter`
    * `dragLeave`
    * `dragOver`
    * `dragEnd`
    * `drop`
  
    ### `{{action}}` Helper
  
    Instead of handling all events of a particular type anywhere inside the
    component's element, you may instead want to limit it to a particular
    element in the component's template. In this case, it would be more
    convenient to implement an action instead.
  
    For example, you could implement the action `hello` for the `person-profile`
    component:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      actions: {
        hello(name) {
          console.log("Hello", name);
        }
      }
    });
    ```
  
    And then use it in the component's template:
  
    ```app/templates/components/person-profile.hbs
    <h1>{{@person.name}}</h1>
  
    <button {{action 'hello' @person.name}}>
      Say Hello to {{@person.name}}
    </button>
    ```
  
    When the user clicks the button, Ember will invoke the `hello` action,
    passing in the current value of `@person.name` as an argument.
  
    See [Ember.Templates.helpers.action](/api/ember/release/classes/Ember.Templates.helpers/methods/action?anchor=action).
  
    @class Component
    @extends Ember.CoreView
    @uses Ember.TargetActionSupport
    @uses Ember.ClassNamesSupport
    @uses Ember.ActionSupport
    @uses Ember.ViewMixin
    @uses Ember.ViewStateSupport
    @public
  */

  const Component = _views.CoreView.extend(_views.ChildViewsSupport, _views.ViewStateSupport, _views.ClassNamesSupport, _runtime.TargetActionSupport, _views.ActionSupport, _views.ViewMixin, {
    isComponent: true,

    init() {
      this._super(...arguments);

      this[IS_DISPATCHING_ATTRS] = false;
      this[DIRTY_TAG] = _reference.DirtyableTag.create();
      this[ROOT_REF] = new RootReference(this);
      this[BOUNDS] = null;

      if (true
      /* DEBUG */
      && this.renderer._destinedForDOM && this.tagName === '') {
        let eventNames = [];
        let eventDispatcher = (0, _owner.getOwner)(this).lookup('event_dispatcher:main');
        let events = eventDispatcher && eventDispatcher._finalEvents || {}; // tslint:disable-next-line:forin

        for (let key in events) {
          let methodName = events[key];

          if (typeof this[methodName] === 'function') {
            eventNames.push(methodName);
          }
        } // If in a tagless component, assert that no event handlers are defined


        true && !!eventNames.length && (0, _debug.assert)( // tslint:disable-next-line:max-line-length
        "You can not define `" + eventNames + "` function(s) to handle DOM event in the `" + this + "` tagless component since it doesn't have any DOM element.", !eventNames.length);
      }
    },

    rerender() {
      this[DIRTY_TAG].inner.dirty();

      this._super();
    },

    [_metal.PROPERTY_DID_CHANGE](key) {
      if (this[IS_DISPATCHING_ATTRS]) {
        return;
      }

      let args = this[ARGS];
      let reference = args !== undefined ? args[key] : undefined;

      if (reference !== undefined && reference[UPDATE] !== undefined) {
        reference[UPDATE]((0, _metal.get)(this, key));
      }
    },

    getAttr(key) {
      // TODO Intimate API should be deprecated
      return this.get(key);
    },

    /**
      Normally, Ember's component model is "write-only". The component takes a
      bunch of attributes that it got passed in, and uses them to render its
      template.
       One nice thing about this model is that if you try to set a value to the
      same thing as last time, Ember (through HTMLBars) will avoid doing any
      work on the DOM.
       This is not just a performance optimization. If an attribute has not
      changed, it is important not to clobber the element's "hidden state".
      For example, if you set an input's `value` to the same value as before,
      it will clobber selection state and cursor position. In other words,
      setting an attribute is not **always** idempotent.
       This method provides a way to read an element's attribute and also
      update the last value Ember knows about at the same time. This makes
      setting an attribute idempotent.
       In particular, what this means is that if you get an `<input>` element's
      `value` attribute and then re-render the template with the same value,
      it will avoid clobbering the cursor and selection position.
      Since most attribute sets are idempotent in the browser, you typically
      can get away with reading attributes using jQuery, but the most reliable
      way to do so is through this method.
      @method readDOMAttr
       @param {String} name the name of the attribute
      @return String
      @public
     */
    readDOMAttr(name) {
      // TODO revisit this
      let _element = (0, _views.getViewElement)(this);

      true && !(_element !== null) && (0, _debug.assert)("Cannot call `readDOMAttr` on " + this + " which does not have an element", _element !== null);
      let element = _element;
      let isSVG = element.namespaceURI === _runtime2.SVG_NAMESPACE;
      let {
        type,
        normalized
      } = (0, _runtime2.normalizeProperty)(element, name);

      if (isSVG || type === 'attr') {
        return element.getAttribute(normalized);
      }

      return element[normalized];
    },

    /**
     The WAI-ARIA role of the control represented by this view. For example, a
     button may have a role of type 'button', or a pane may have a role of
     type 'alertdialog'. This property is used by assistive software to help
     visually challenged users navigate rich web applications.
      The full list of valid WAI-ARIA roles is available at:
     [https://www.w3.org/TR/wai-aria/#roles_categorization](https://www.w3.org/TR/wai-aria/#roles_categorization)
      @property ariaRole
     @type String
     @default null
     @public
     */

    /**
     Enables components to take a list of parameters as arguments.
     For example, a component that takes two parameters with the names
     `name` and `age`:
      ```app/components/my-component.js
     import Component from '@ember/component';
      let MyComponent = Component.extend();
      MyComponent.reopenClass({
       positionalParams: ['name', 'age']
     });
      export default MyComponent;
     ```
      It can then be invoked like this:
      ```hbs
     {{my-component "John" 38}}
     ```
      The parameters can be referred to just like named parameters:
      ```hbs
     Name: {{name}}, Age: {{age}}.
     ```
      Using a string instead of an array allows for an arbitrary number of
     parameters:
      ```app/components/my-component.js
     import Component from '@ember/component';
      let MyComponent = Component.extend();
      MyComponent.reopenClass({
       positionalParams: 'names'
     });
      export default MyComponent;
     ```
      It can then be invoked like this:
      ```hbs
     {{my-component "John" "Michael" "Scott"}}
     ```
     The parameters can then be referred to by enumerating over the list:
      ```hbs
     {{#each names as |name|}}{{name}}{{/each}}
     ```
      @static
     @public
     @property positionalParams
     @since 1.13.0
     */

    /**
     Called when the attributes passed into the component have been updated.
     Called both during the initial render of a container and during a rerender.
     Can be used in place of an observer; code placed here will be executed
     every time any attribute updates.
     @method didReceiveAttrs
     @public
     @since 1.13.0
     */
    didReceiveAttrs() {},

    /**
     Called when the attributes passed into the component have been updated.
     Called both during the initial render of a container and during a rerender.
     Can be used in place of an observer; code placed here will be executed
     every time any attribute updates.
     @event didReceiveAttrs
     @public
     @since 1.13.0
     */

    /**
     Called after a component has been rendered, both on initial render and
     in subsequent rerenders.
     @method didRender
     @public
     @since 1.13.0
     */
    didRender() {},

    /**
     Called after a component has been rendered, both on initial render and
     in subsequent rerenders.
     @event didRender
     @public
     @since 1.13.0
     */

    /**
     Called before a component has been rendered, both on initial render and
     in subsequent rerenders.
     @method willRender
     @public
     @since 1.13.0
     */
    willRender() {},

    /**
     Called before a component has been rendered, both on initial render and
     in subsequent rerenders.
     @event willRender
     @public
     @since 1.13.0
     */

    /**
     Called when the attributes passed into the component have been changed.
     Called only during a rerender, not during an initial render.
     @method didUpdateAttrs
     @public
     @since 1.13.0
     */
    didUpdateAttrs() {},

    /**
     Called when the attributes passed into the component have been changed.
     Called only during a rerender, not during an initial render.
     @event didUpdateAttrs
     @public
     @since 1.13.0
     */

    /**
     Called when the component is about to update and rerender itself.
     Called only during a rerender, not during an initial render.
     @method willUpdate
     @public
     @since 1.13.0
     */
    willUpdate() {},

    /**
     Called when the component is about to update and rerender itself.
     Called only during a rerender, not during an initial render.
     @event willUpdate
     @public
     @since 1.13.0
     */

    /**
     Called when the component has updated and rerendered itself.
     Called only during a rerender, not during an initial render.
     @method didUpdate
     @public
     @since 1.13.0
     */
    didUpdate() {}

  });

  _exports.Component = Component;

  Component.toString = () => '@ember/component';

  Component.reopenClass({
    isComponentFactory: true,
    positionalParams: []
  });
  var layout = template({
    "id": "hvtsz7RF",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/empty.hbs"
    }
  });
  /**
  @module @ember/component
  */

  /**
    The internal class used to create text inputs when the `{{input}}`
    helper is used with `type` of `checkbox`.
  
    See [Ember.Templates.helpers.input](/api/ember/release/classes/Ember.Templates.helpers/methods/input?anchor=input)  for usage details.
  
    ## Direct manipulation of `checked`
  
    The `checked` attribute of an `Checkbox` object should always be set
    through the Ember object or by interacting with its rendered element
    representation via the mouse, keyboard, or touch. Updating the value of the
    checkbox via jQuery will result in the checked value of the object and its
    element losing synchronization.
  
    ## Layout and LayoutName properties
  
    Because HTML `input` elements are self closing `layout` and `layoutName`
    properties will not be applied.
  
    @class Checkbox
    @extends Component
    @public
  */

  const Checkbox = Component.extend({
    layout,

    /**
      By default, this component will add the `ember-checkbox` class to the component's element.
         @property classNames
      @type Array | String
      @default ['ember-checkbox']
      @public
     */
    classNames: ['ember-checkbox'],
    tagName: 'input',

    /**
      By default this component will forward a number of arguments to attributes on the the
      component's element:
         * indeterminate
      * disabled
      * tabindex
      * name
      * autofocus
      * required
      * form
         When invoked with curly braces, this is the exhaustive list of HTML attributes you can
      customize (i.e. `{{input type="checkbox" disabled=true}}`).
         When invoked with angle bracket invocation, this list is irrelevant, because you can use HTML
      attribute syntax to customize the element (i.e.
      `<Input @type="checkbox" disabled data-custom="custom value" />`). However, `@type` and
      `@checked` must be passed as named arguments, not attributes.
         @property attributeBindings
      @type Array | String
      @default ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form']
      @public
    */
    attributeBindings: ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form'],

    /**
      Sets the `type` attribute of the `Checkbox`'s element
         @property disabled
      @default false
      @private
     */
    type: 'checkbox',

    /**
      Sets the `disabled` attribute of the `Checkbox`'s element
         @property disabled
      @default false
      @public
     */
    disabled: false,

    /**
      Corresponds to the `indeterminate` property of the `Checkbox`'s element
         @property disabled
      @default false
      @public
     */
    indeterminate: false,

    /**
      Whenever the checkbox is inserted into the DOM, perform initialization steps, which include
      setting the indeterminate property if needed.
         If this method is overridden, `super` must be called.
         @method
      @public
     */
    didInsertElement() {
      this._super(...arguments);

      this.element.indeterminate = Boolean(this.indeterminate);
    },

    /**
      Whenever the `change` event is fired on the checkbox, update its `checked` property to reflect
      whether the checkbox is checked.
         If this method is overridden, `super` must be called.
         @method
      @public
     */
    change() {
      (0, _metal.set)(this, 'checked', this.element.checked);
    }

  });
  _exports.Checkbox = Checkbox;

  if (true
  /* DEBUG */
  ) {
      const UNSET = {};
      Checkbox.reopen({
        value: UNSET,

        didReceiveAttrs() {
          this._super();

          true && !!(this.type === 'checkbox' && this.value !== UNSET) && (0, _debug.assert)("`<Input @type='checkbox' @value={{...}} />` is not supported; " + "please use `<Input @type='checkbox' @checked={{...}} />` instead.", !(this.type === 'checkbox' && this.value !== UNSET));
        }

      });
    }

  Checkbox.toString = () => '@ember/component/checkbox';
  /**
  @module @ember/component
  */


  const inputTypes = _browserEnvironment.hasDOM ? Object.create(null) : null;

  function canSetTypeOfInput(type) {
    // if running in outside of a browser always return
    // the original type
    if (!_browserEnvironment.hasDOM) {
      return Boolean(type);
    }

    if (type in inputTypes) {
      return inputTypes[type];
    }

    let inputTypeTestElement = document.createElement('input');

    try {
      inputTypeTestElement.type = type;
    } catch (e) {// ignored
    }

    return inputTypes[type] = inputTypeTestElement.type === type;
  }
  /**
    The internal class used to create text inputs when the `Input` component is used with `type` of `text`.
  
    See [Ember.Templates.components.Input](/api/ember/release/classes/Ember.Templates.components/methods/Input?anchor=Input) for usage details.
  
    ## Layout and LayoutName properties
  
    Because HTML `input` elements are self closing `layout` and `layoutName`
    properties will not be applied.
  
    @class TextField
    @extends Component
    @uses Ember.TextSupport
    @public
  */


  const TextField = Component.extend(_views.TextSupport, {
    layout,

    /**
      By default, this component will add the `ember-text-field` class to the component's element.
         @property classNames
      @type Array | String
      @default ['ember-text-field']
      @public
     */
    classNames: ['ember-text-field'],
    tagName: 'input',

    /**
      By default this component will forward a number of arguments to attributes on the the
      component's element:
         * accept
      * autocomplete
      * autosave
      * dir
      * formaction
      * formenctype
      * formmethod
      * formnovalidate
      * formtarget
      * height
      * inputmode
      * lang
      * list
      * type
      * max
      * min
      * multiple
      * name
      * pattern
      * size
      * step
      * value
      * width
         When invoked with `{{input type="text"}}`, you can only customize these attributes. When invoked
      with `<Input @type="text" />`, you can just use HTML attributes directly.
         @property attributeBindings
      @type Array | String
      @default ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width']
      @public
    */
    attributeBindings: ['accept', 'autocomplete', 'autosave', 'dir', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'inputmode', 'lang', 'list', 'type', 'max', 'min', 'multiple', 'name', 'pattern', 'size', 'step', 'value', 'width'],

    /**
      As the user inputs text, this property is updated to reflect the `value` property of the HTML
      element.
         @property value
      @type String
      @default ""
      @public
    */
    value: '',

    /**
      The `type` attribute of the input element.
         @property type
      @type String
      @default "text"
      @public
    */
    type: (0, _metal.computed)({
      get() {
        return 'text';
      },

      set(_key, value) {
        let type = 'text';

        if (canSetTypeOfInput(value)) {
          type = value;
        }

        return type;
      }

    }),

    /**
      The `size` of the text field in characters.
         @property size
      @type String
      @default null
      @public
    */
    size: null,

    /**
      The `pattern` attribute of input element.
         @property pattern
      @type String
      @default null
      @public
    */
    pattern: null,

    /**
      The `min` attribute of input element used with `type="number"` or `type="range"`.
         @property min
      @type String
      @default null
      @since 1.4.0
      @public
    */
    min: null,

    /**
      The `max` attribute of input element used with `type="number"` or `type="range"`.
         @property max
      @type String
      @default null
      @since 1.4.0
      @public
    */
    max: null
  });
  _exports.TextField = TextField;

  TextField.toString = () => '@ember/component/text-field';
  /**
  @module @ember/component
  */

  /**
    The `Textarea` component inserts a new instance of `<textarea>` tag into the template.
  
    The `@value` argument provides the content of the `<textarea>`.
  
    This template:
  
    ```handlebars
    <Textarea @value="A bunch of text" />
    ```
  
    Would result in the following HTML:
  
    ```html
    <textarea class="ember-text-area">
      A bunch of text
    </textarea>
    ```
  
    The `@value` argument is two-way bound. If the user types text into the textarea, the `@value`
    argument is updated. If the `@value` argument is updated, the text in the textarea is updated.
  
    In the following example, the `writtenWords` property on the component will be updated as the user
    types 'Lots of text' into the text area of their browser's window.
  
    ```app/components/word-editor.js
    import Component from '@glimmer/component';
    import { tracked } from '@glimmer/tracking';
  
    export default class extends Component {
      @tracked writtenWords = "Lots of text that IS bound";
    }
    ```
  
    ```handlebars
    <Textarea @value={{writtenWords}} />
    ```
  
    Would result in the following HTML:
  
    ```html
    <textarea class="ember-text-area">
      Lots of text that IS bound
    </textarea>
    ```
  
    If you wanted a one way binding, you could use the `<textarea>` element directly, and use the
    `value` DOM property and the `input` event.
  
    ### Actions
  
    The `Textarea` component takes a number of arguments with callbacks that are invoked in
    response to user events.
  
    * `enter`
    * `insert-newline`
    * `escape-press`
    * `focus-in`
    * `focus-out`
    * `key-press`
  
    These callbacks are passed to `Textarea` like this:
  
    ```handlebars
    <Textarea @value={{this.searchWord}} @enter={{this.query}} />
    ```
  
    ## Classic Invocation Syntax
  
    The `Textarea` component can also be invoked using curly braces, just like any other Ember
    component.
  
    For example, this is an invocation using angle-bracket notation:
  
    ```handlebars
    <Textarea @value={{this.searchWord}} @enter={{this.query}} />
    ```
  
    You could accomplish the same thing using classic invocation:
  
    ```handlebars
    {{textarea value=this.searchWord enter=this.query}}
    ```
  
    The main difference is that angle-bracket invocation supports any HTML attribute using HTML
    attribute syntax, because attributes and arguments have different syntax when using angle-bracket
    invocation. Curly brace invocation, on the other hand, only has a single syntax for arguments,
    and components must manually map attributes onto component arguments.
  
    When using classic invocation with `{{textarea}}`, only the following attributes are mapped onto
    arguments:
  
    * rows
    * cols
    * name
    * selectionEnd
    * selectionStart
    * autocomplete
    * wrap
    * lang
    * dir
    * value
  
    ## Classic `layout` and `layoutName` properties
  
    Because HTML `textarea` elements do not contain inner HTML the `layout` and
    `layoutName` properties will not be applied.
  
    @method Textarea
    @for Ember.Templates.components
    @see {TextArea}
    @public
  */

  /**
    See Ember.Templates.components.Textarea.
  
    @method textarea
    @for Ember.Templates.helpers
    @see {Ember.Templates.components.textarea}
    @public
  */

  /**
    The internal representation used for `Textarea` invocations.
  
    @class TextArea
    @extends Component
    @see {Ember.Templates.components.Textarea}
    @uses Ember.TextSupport
    @public
  */


  const TextArea = Component.extend(_views.TextSupport, {
    classNames: ['ember-text-area'],
    layout,
    tagName: 'textarea',
    attributeBindings: ['rows', 'cols', 'name', 'selectionEnd', 'selectionStart', 'autocomplete', 'wrap', 'lang', 'dir', 'value'],
    rows: null,
    cols: null
  });
  _exports.TextArea = TextArea;

  TextArea.toString = () => '@ember/component/text-area';

  var layout$1 = template({
    "id": "VKeIRbyr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[15,1]],\"parameters\":[]},{\"statements\":[[1,[24,0,[\"linkTitle\"]],false]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/link-to.hbs"
    }
  });
  /**
  @module ember
  */

  let LinkComponent;

  if (true
  /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
  ) {
      /**
        The `LinkTo` component renders a link to the supplied `routeName` passing an optionally
        supplied model to the route as its `model` context of the route. The block for `LinkTo`
        becomes the contents of the rendered element:
           ```handlebars
        <LinkTo @route='photoGallery'>
          Great Hamster Photos
        </LinkTo>
        ```
           This will result in:
           ```html
        <a href="/hamster-photos">
          Great Hamster Photos
        </a>
        ```
           ### Disabling the `LinkTo` component
           The `LinkTo` component can be disabled by using the `disabled` argument. A disabled link
        doesn't result in a transition when activated, and adds the `disabled` class to the `<a>`
        element.
           (The class name to apply to the element can be overridden by using the `disabledClass`
        argument)
           ```handlebars
        <LinkTo @route='photoGallery' @disabled={{true}}>
          Great Hamster Photos
        </LinkTo>
        ```
           ### Handling `href`
           `<LinkTo>` will use your application's Router to fill the element's `href` property with a URL
        that matches the path to the supplied `routeName`.
           ### Handling current route
           The `LinkTo` component will apply a CSS class name of 'active' when the application's current
        route matches the supplied routeName. For example, if the application's current route is
        'photoGallery.recent', then the following invocation of `LinkTo`:
           ```handlebars
        <LinkTo @route='photoGallery.recent'>
          Great Hamster Photos
        </LinkTo>
        ```
           will result in
           ```html
        <a href="/hamster-photos/this-week" class="active">
          Great Hamster Photos
        </a>
        ```
           The CSS class used for active classes can be customized by passing an `activeClass` argument:
           ```handlebars
        <LinkTo @route='photoGallery.recent' @activeClass="current-url">
          Great Hamster Photos
        </LinkTo>
        ```
           ```html
        <a href="/hamster-photos/this-week" class="current-url">
          Great Hamster Photos
        </a>
        ```
           ### Keeping a link active for other routes
           If you need a link to be 'active' even when it doesn't match the current route, you can use the
        `current-when` argument.
           ```handlebars
        <LinkTo @route='photoGallery' @current-when='photos'>
          Photo Gallery
        </LinkTo>
        ```
           This may be helpful for keeping links active for:
           * non-nested routes that are logically related
        * some secondary menu approaches
        * 'top navigation' with 'sub navigation' scenarios
           A link will be active if `current-when` is `true` or the current
        route is the route this link would transition to.
           To match multiple routes 'space-separate' the routes:
           ```handlebars
        <LinkTo @route='gallery' @current-when='photos drawings paintings'>
          Art Gallery
        </LinkTo>
        ```
           ### Supplying a model
           An optional `model` argument can be used for routes whose
        paths contain dynamic segments. This argument will become
        the model context of the linked route:
           ```javascript
        Router.map(function() {
          this.route("photoGallery", {path: "hamster-photos/:photo_id"});
        });
        ```
           ```handlebars
        <LinkTo @route='photoGallery' @model={{this.aPhoto}}>
          {{aPhoto.title}}
        </LinkTo>
        ```
           ```html
        <a href="/hamster-photos/42">
          Tomster
        </a>
        ```
           ### Supplying multiple models
           For deep-linking to route paths that contain multiple
        dynamic segments, the `models` argument can be used.
           As the router transitions through the route path, each
        supplied model argument will become the context for the
        route with the dynamic segments:
           ```javascript
        Router.map(function() {
          this.route("photoGallery", { path: "hamster-photos/:photo_id" }, function() {
            this.route("comment", {path: "comments/:comment_id"});
          });
        });
        ```
           This argument will become the model context of the linked route:
           ```handlebars
        <LinkTo @route='photoGallery.comment' @models={{array this.aPhoto this.comment}}>
          {{comment.body}}
        </LinkTo>
        ```
           ```html
        <a href="/hamster-photos/42/comments/718">
          A+++ would snuggle again.
        </a>
        ```
           ### Supplying an explicit dynamic segment value
           If you don't have a model object available to pass to `LinkTo`,
        an optional string or integer argument can be passed for routes whose
        paths contain dynamic segments. This argument will become the value
        of the dynamic segment:
           ```javascript
        Router.map(function() {
          this.route("photoGallery", { path: "hamster-photos/:photo_id" });
        });
        ```
           ```handlebars
        <LinkTo @route='photoGallery' @model={{aPhotoId}}>
          {{this.aPhoto.title}}
        </LinkTo>
        ```
           ```html
        <a href="/hamster-photos/42">
          Tomster
        </a>
        ```
           When transitioning into the linked route, the `model` hook will
        be triggered with parameters including this passed identifier.
           ### Allowing Default Action
           By default the `<LinkTo>` component prevents the default browser action by calling
        `preventDefault()` to avoid reloading the browser page.
           If you need to trigger a full browser reload pass `@preventDefault={{false}}`:
           ```handlebars
        <LinkTo @route='photoGallery' @model={{this.aPhotoId}} @preventDefault={{false}}>
          {{this.aPhotoId.title}}
        </LinkTo>
        ```
           ### Supplying a `tagName`
           By default `<LinkTo>` renders an `<a>` element. This can be overridden for a single use of
        `<LinkTo>` by supplying a `tagName` argument:
           ```handlebars
        <LinkTo @route='photoGallery' @tagName='li'>
          Great Hamster Photos
        </LinkTo>
        ```
           This produces:
           ```html
        <li>
          Great Hamster Photos
        </li>
        ```
           In general, this is not recommended. Instead, you can use the `transition-to` helper together
        with a click event handler on the HTML tag of your choosing.
           @for Ember.Templates.components
        @method LinkTo
        @see {LinkComponent}
        @public
      */

      /**
        @module @ember/routing
      */

      /**
        See [Ember.Templates.components.LinkTo](/api/ember/release/classes/Ember.Templates.components/methods/input?anchor=input).
           @for Ember.Templates.helpers
        @method link-to
        @see {Ember.Templates.components.LinkTo}
        @public
      **/

      /**
        `LinkComponent` is the internal component invoked with `<LinkTo>` or `{{link-to}}`.
           @class LinkComponent
        @extends Component
        @see {Ember.Templates.components.LinkTo}
        @public
      **/
      const UNDEFINED = Object.freeze({
        toString() {
          return 'UNDEFINED';
        }

      });
      const EMPTY_QUERY_PARAMS = Object.freeze({});
      LinkComponent = Component.extend({
        layout: layout$1,
        tagName: 'a',

        /**
          @property route
          @category EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS
          @public
        */
        route: UNDEFINED,

        /**
          @property model
          @category EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS
          @public
        */
        model: UNDEFINED,

        /**
          @property models
          @category EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS
          @public
        */
        models: UNDEFINED,

        /**
          @property query
          @category EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS
          @public
        */
        query: UNDEFINED,

        /**
          Used to determine when this `LinkComponent` is active.
               @property current-when
          @public
        */
        'current-when': null,

        /**
          Sets the `title` attribute of the `LinkComponent`'s HTML element.
               @property title
          @default null
          @public
        **/
        title: null,

        /**
          Sets the `rel` attribute of the `LinkComponent`'s HTML element.
               @property rel
          @default null
          @public
        **/
        rel: null,

        /**
          Sets the `tabindex` attribute of the `LinkComponent`'s HTML element.
               @property tabindex
          @default null
          @public
        **/
        tabindex: null,

        /**
          Sets the `target` attribute of the `LinkComponent`'s HTML element.
               @since 1.8.0
          @property target
          @default null
          @public
        **/
        target: null,

        /**
          The CSS class to apply to `LinkComponent`'s element when its `active`
          property is `true`.
               @property activeClass
          @type String
          @default active
          @public
        **/
        activeClass: 'active',

        /**
          The CSS class to apply to `LinkComponent`'s element when its `loading`
          property is `true`.
               @property loadingClass
          @type String
          @default loading
          @private
        **/
        loadingClass: 'loading',

        /**
          The CSS class to apply to a `LinkComponent`'s element when its `disabled`
          property is `true`.
               @property disabledClass
          @type String
          @default disabled
          @private
        **/
        disabledClass: 'disabled',

        /**
          Determines whether the `LinkComponent` will trigger routing via
          the `replaceWith` routing strategy.
               @property replace
          @type Boolean
          @default false
          @public
        **/
        replace: false,

        /**
          By default this component will forward `href`, `title`, `rel`, `tabindex`, and `target`
          arguments to attributes on the component's element. When invoked with `{{link-to}}`, you can
          only customize these attributes. When invoked with `<LinkTo>`, you can just use HTML
          attributes directly.
               @property attributeBindings
          @type Array | String
          @default ['title', 'rel', 'tabindex', 'target']
          @public
        */
        attributeBindings: ['href', 'title', 'rel', 'tabindex', 'target'],

        /**
          By default this component will set classes on its element when any of the following arguments
          are truthy:
               * active
          * loading
          * disabled
               When these arguments are truthy, a class with the same name will be set on the element. When
          falsy, the associated class will not be on the element.
               @property classNameBindings
          @type Array
          @default ['active', 'loading', 'disabled', 'ember-transitioning-in', 'ember-transitioning-out']
          @public
        */
        classNameBindings: ['active', 'loading', 'disabled', 'transitioningIn', 'transitioningOut'],

        /**
          By default this component responds to the `click` event. When the component element is an
          `<a>` element, activating the link in another way, such as using the keyboard, triggers the
          click event.
               @property eventName
          @type String
          @default click
          @private
        */
        eventName: 'click',

        // this is doc'ed here so it shows up in the events
        // section of the API documentation, which is where
        // people will likely go looking for it.

        /**
          Triggers the `LinkComponent`'s routing behavior. If
          `eventName` is changed to a value other than `click`
          the routing behavior will trigger on that custom event
          instead.
               @event click
          @private
        */

        /**
          An overridable method called when `LinkComponent` objects are instantiated.
               Example:
               ```app/components/my-link.js
          import LinkComponent from '@ember/routing/link-component';
               export default LinkComponent.extend({
            init() {
              this._super(...arguments);
              console.log('Event is ' + this.get('eventName'));
            }
          });
          ```
               NOTE: If you do override `init` for a framework class like `Component`,
          be sure to call `this._super(...arguments)` in your
          `init` declaration! If you don't, Ember may not have an opportunity to
          do important setup work, and you'll see strange behavior in your
          application.
               @method init
          @private
        */
        init() {
          this._super(...arguments); // Map desired event name to invoke function


          let {
            eventName
          } = this;
          this.on(eventName, this, this._invoke);
        },

        _routing: (0, _service.inject)('-routing'),
        _currentRoute: (0, _metal.alias)('_routing.currentRouteName'),
        _currentRouterState: (0, _metal.alias)('_routing.currentState'),
        _targetRouterState: (0, _metal.alias)('_routing.targetState'),
        _route: (0, _metal.computed)('route', '_currentRouterState', function computeLinkToComponentRoute() {
          let {
            route
          } = this;
          return route === UNDEFINED ? this._currentRoute : route;
        }),
        _models: (0, _metal.computed)('model', 'models', function computeLinkToComponentModels() {
          let {
            model,
            models
          } = this;
          true && !(model === UNDEFINED || models === UNDEFINED) && (0, _debug.assert)('You cannot provide both the `@model` and `@models` arguments to the <LinkTo> component.', model === UNDEFINED || models === UNDEFINED);

          if (model !== UNDEFINED) {
            return [model];
          } else if (models !== UNDEFINED) {
            true && !Array.isArray(models) && (0, _debug.assert)('The `@models` argument must be an array.', Array.isArray(models));
            return models;
          } else {
            return [];
          }
        }),
        _query: (0, _metal.computed)('query', function computeLinkToComponentQuery() {
          let {
            query
          } = this;

          if (query === UNDEFINED) {
            return EMPTY_QUERY_PARAMS;
          } else {
            return Object.assign({}, query);
          }
        }),

        /**
          Accessed as a classname binding to apply the component's `disabledClass`
          CSS `class` to the element when the link is disabled.
               When `true`, interactions with the element will not trigger route changes.
          @property disabled
          @private
        */
        disabled: (0, _metal.computed)({
          get(_key) {
            // always returns false for `get` because (due to the `set` just below)
            // the cached return value from the set will prevent this getter from _ever_
            // being called after a set has occured
            return false;
          },

          set(_key, value) {
            this._isDisabled = value;
            return value ? this.disabledClass : false;
          }

        }),

        /**
          Accessed as a classname binding to apply the component's `activeClass`
          CSS `class` to the element when the link is active.
               This component is considered active when its `currentWhen` property is `true`
          or the application's current route is the route this component would trigger
          transitions into.
               The `currentWhen` property can match against multiple routes by separating
          route names using the ` ` (space) character.
               @property active
          @private
        */
        active: (0, _metal.computed)('activeClass', '_active', function computeLinkToComponentActiveClass() {
          return this._active ? this.activeClass : false;
        }),
        _active: (0, _metal.computed)('_currentRouterState', '_route', '_models', '_query', 'loading', 'current-when', function computeLinkToComponentActive() {
          let {
            _currentRouterState: state
          } = this;

          if (state) {
            return this._isActive(state);
          } else {
            return false;
          }
        }),
        willBeActive: (0, _metal.computed)('_currentRouterState', '_targetRouterState', '_route', '_models', '_query', 'loading', 'current-when', function computeLinkToComponentWillBeActive() {
          let {
            _currentRouterState: current,
            _targetRouterState: target
          } = this;

          if (current === target) {
            return;
          }

          return this._isActive(target);
        }),

        _isActive(routerState) {
          if (this.loading) {
            return false;
          }

          let currentWhen = this['current-when'];

          if (typeof currentWhen === 'boolean') {
            return currentWhen;
          }

          let isCurrentWhenSpecified = Boolean(currentWhen);

          if (isCurrentWhenSpecified) {
            currentWhen = currentWhen.split(' ');
          } else {
            currentWhen = [this._route];
          }

          let {
            _models: models,
            _query: query,
            _routing: routing
          } = this;

          for (let i = 0; i < currentWhen.length; i++) {
            if (routing.isActiveForRoute(models, query, currentWhen[i], routerState, isCurrentWhenSpecified)) {
              return true;
            }
          }

          return false;
        },

        transitioningIn: (0, _metal.computed)('_active', 'willBeActive', function computeLinkToComponentTransitioningIn() {
          if (this.willBeActive === true && !this._active) {
            return 'ember-transitioning-in';
          } else {
            return false;
          }
        }),
        transitioningOut: (0, _metal.computed)('_active', 'willBeActive', function computeLinkToComponentTransitioningOut() {
          if (this.willBeActive === false && this._active) {
            return 'ember-transitioning-out';
          } else {
            return false;
          }
        }),

        /**
          Event handler that invokes the link, activating the associated route.
               @method _invoke
          @param {Event} event
          @private
        */
        _invoke(event) {
          if (!(0, _views.isSimpleClick)(event)) {
            return true;
          }

          let {
            bubbles,
            preventDefault
          } = this;
          let target = this.element.target;
          let isSelf = !target || target === '_self';

          if (preventDefault !== false && isSelf) {
            event.preventDefault();
          }

          if (bubbles === false) {
            event.stopPropagation();
          }

          if (this._isDisabled) {
            return false;
          }

          if (this.loading) {
            // tslint:disable-next-line:max-line-length
            true && (0, _debug.warn)('This link is in an inactive loading state because at least one of its models ' + 'currently has a null/undefined value, or the provided route name is invalid.', false, {
              id: 'ember-glimmer.link-to.inactive-loading-state'
            });
            return false;
          }

          if (!isSelf) {
            return false;
          }

          let {
            _route: routeName,
            _models: models,
            _query: queryParams,
            replace: shouldReplace
          } = this;
          let payload = {
            queryParams,
            routeName
          };
          (0, _instrumentation.flaggedInstrument)('interaction.link-to', payload, this._generateTransition(payload, routeName, models, queryParams, shouldReplace));
          return false;
        },

        _generateTransition(payload, qualifiedRouteName, models, queryParams, shouldReplace) {
          let {
            _routing: routing
          } = this;
          return () => {
            payload.transition = routing.transitionTo(qualifiedRouteName, models, queryParams, shouldReplace);
          };
        },

        /**
          Sets the element's `href` attribute to the url for
          the `LinkComponent`'s targeted route.
               If the `LinkComponent`'s `tagName` is changed to a value other
          than `a`, this property will be ignored.
               @property href
          @private
        */
        href: (0, _metal.computed)('_currentRouterState', '_route', '_models', '_query', 'tagName', 'loading', 'loadingHref', function computeLinkToComponentHref() {
          if (this.tagName !== 'a') {
            return;
          }

          if (this.loading) {
            return this.loadingHref;
          }

          let {
            _route: route,
            _models: models,
            _query: query,
            _routing: routing
          } = this;

          if (true
          /* DEBUG */
          ) {
              /*
               * Unfortunately, to get decent error messages, we need to do this.
               * In some future state we should be able to use a "feature flag"
               * which allows us to strip this without needing to call it twice.
               *
               * if (isDebugBuild()) {
               *   // Do the useful debug thing, probably including try/catch.
               * } else {
               *   // Do the performant thing.
               * }
               */
              try {
                return routing.generateURL(route, models, query);
              } catch (e) {
                // tslint:disable-next-line:max-line-length
                true && !false && (0, _debug.assert)("You attempted to generate a link for the \"" + this.route + "\" route, but did not " + "pass the models required for generating its dynamic segments. " + e.message);
              }
            } else {
            return routing.generateURL(route, models, query);
          }
        }),
        loading: (0, _metal.computed)('_route', '_modelsAreLoaded', 'loadingClass', function computeLinkToComponentLoading() {
          let {
            _route: route,
            _modelsAreLoaded: loaded
          } = this;

          if (!loaded || route === null || route === undefined) {
            return this.loadingClass;
          }
        }),
        _modelsAreLoaded: (0, _metal.computed)('_models', function computeLinkToComponentModelsAreLoaded() {
          let {
            _models: models
          } = this;

          for (let i = 0; i < models.length; i++) {
            let model = models[i];

            if (model === null || model === undefined) {
              return false;
            }
          }

          return true;
        }),

        /**
          The default href value to use while a link-to is loading.
          Only applies when tagName is 'a'
               @property loadingHref
          @type String
          @default #
          @private
        */
        loadingHref: '#',

        didReceiveAttrs() {
          let {
            disabledWhen
          } = this;

          if (disabledWhen !== undefined) {
            this.set('disabled', disabledWhen);
          }

          let {
            params
          } = this;

          if (!params || params.length === 0) {
            true && !!(this.route === UNDEFINED && this.model === UNDEFINED && this.models === UNDEFINED && this.query === UNDEFINED) && (0, _debug.assert)('You must provide at least one of the `@route`, `@model`, `@models` or `@query` argument to `<LinkTo>`.', !(this.route === UNDEFINED && this.model === UNDEFINED && this.models === UNDEFINED && this.query === UNDEFINED));

            if (true
            /* DEBUG */
            && this.query === UNDEFINED) {
              let {
                _models: models
              } = this;
              let lastModel = models.length > 0 && models[models.length - 1];
              true && !!(lastModel && lastModel.isQueryParams) && (0, _debug.assert)('The `(query-params)` helper can only be used when invoking the `{{link-to}}` component.', !(lastModel && lastModel.isQueryParams));
            }

            return;
          }

          params = params.slice(); // Process the positional arguments, in order.
          // 1. Inline link title comes first, if present.

          if (!this[HAS_BLOCK]) {
            this.set('linkTitle', params.shift());
          } // 2. The last argument is possibly the `query` object.


          let queryParams = params[params.length - 1];

          if (queryParams && queryParams.isQueryParams) {
            this.set('query', params.pop().values);
          } else {
            this.set('query', UNDEFINED);
          } // 3. If there is a `route`, it is now at index 0.


          if (params.length === 0) {
            this.set('route', UNDEFINED);
          } else {
            this.set('route', params.shift());
          } // 4. Any remaining indices (if any) are `models`.


          this.set('model', UNDEFINED);
          this.set('models', params);
        }

      });

      LinkComponent.toString = () => '@ember/routing/link-component';

      LinkComponent.reopenClass({
        positionalParams: 'params'
      });
    } else {
    /**
      The `{{link-to}}` component renders a link to the supplied
      `routeName` passing an optionally supplied model to the
      route as its `model` context of the route. The block
      for `{{link-to}}` becomes the innerHTML of the rendered
      element:
         ```handlebars
      {{#link-to 'photoGallery'}}
        Great Hamster Photos
      {{/link-to}}
      ```
         You can also use an inline form of `{{link-to}}` component by
      passing the link text as the first argument
      to the component:
         ```handlebars
      {{link-to 'Great Hamster Photos' 'photoGallery'}}
      ```
         Both will result in:
         ```html
      <a href="/hamster-photos">
        Great Hamster Photos
      </a>
      ```
         ### Supplying a tagName
      By default `{{link-to}}` renders an `<a>` element. This can
      be overridden for a single use of `{{link-to}}` by supplying
      a `tagName` option:
         ```handlebars
      {{#link-to 'photoGallery' tagName="li"}}
        Great Hamster Photos
      {{/link-to}}
      ```
         ```html
      <li>
        Great Hamster Photos
      </li>
      ```
         To override this option for your entire application, see
      "Overriding Application-wide Defaults".
         ### Disabling the `link-to` component
      By default `{{link-to}}` is enabled.
      any passed value to the `disabled` component property will disable
      the `link-to` component.
         static use: the `disabled` option:
         ```handlebars
      {{#link-to 'photoGallery' disabled=true}}
        Great Hamster Photos
      {{/link-to}}
      ```
         dynamic use: the `disabledWhen` option:
         ```handlebars
      {{#link-to 'photoGallery' disabledWhen=controller.someProperty}}
        Great Hamster Photos
      {{/link-to}}
      ```
         any truthy value passed to `disabled` will disable it except `undefined`.
         See "Overriding Application-wide Defaults" for more.
         ### Handling `href`
      `{{link-to}}` will use your application's Router to
      fill the element's `href` property with a url that
      matches the path to the supplied `routeName` for your
      router's configured `Location` scheme, which defaults
      to HashLocation.
         ### Handling current route
      `{{link-to}}` will apply a CSS class name of 'active'
      when the application's current route matches
      the supplied routeName. For example, if the application's
      current route is 'photoGallery.recent' the following
      use of `{{link-to}}`:
         ```handlebars
      {{#link-to 'photoGallery.recent'}}
        Great Hamster Photos
      {{/link-to}}
      ```
         will result in
         ```html
      <a href="/hamster-photos/this-week" class="active">
        Great Hamster Photos
      </a>
      ```
         The CSS class name used for active classes can be customized
      for a single use of `{{link-to}}` by passing an `activeClass`
      option:
         ```handlebars
      {{#link-to 'photoGallery.recent' activeClass="current-url"}}
        Great Hamster Photos
      {{/link-to}}
      ```
         ```html
      <a href="/hamster-photos/this-week" class="current-url">
        Great Hamster Photos
      </a>
      ```
         To override this option for your entire application, see
      "Overriding Application-wide Defaults".
         ### Keeping a link active for other routes
         If you need a link to be 'active' even when it doesn't match
      the current route, you can use the `current-when` argument.
         ```handlebars
      {{#link-to 'photoGallery' current-when='photos'}}
        Photo Gallery
      {{/link-to}}
      ```
         This may be helpful for keeping links active for:
         * non-nested routes that are logically related
      * some secondary menu approaches
      * 'top navigation' with 'sub navigation' scenarios
         A link will be active if `current-when` is `true` or the current
      route is the route this link would transition to.
         To match multiple routes 'space-separate' the routes:
         ```handlebars
      {{#link-to 'gallery' current-when='photos drawings paintings'}}
        Art Gallery
      {{/link-to}}
      ```
         ### Supplying a model
      An optional model argument can be used for routes whose
      paths contain dynamic segments. This argument will become
      the model context of the linked route:
         ```javascript
      Router.map(function() {
        this.route("photoGallery", {path: "hamster-photos/:photo_id"});
      });
      ```
         ```handlebars
      {{#link-to 'photoGallery' aPhoto}}
        {{aPhoto.title}}
      {{/link-to}}
      ```
         ```html
      <a href="/hamster-photos/42">
        Tomster
      </a>
      ```
         ### Supplying multiple models
      For deep-linking to route paths that contain multiple
      dynamic segments, multiple model arguments can be used.
      As the router transitions through the route path, each
      supplied model argument will become the context for the
      route with the dynamic segments:
         ```javascript
      Router.map(function() {
        this.route("photoGallery", { path: "hamster-photos/:photo_id" }, function() {
          this.route("comment", {path: "comments/:comment_id"});
        });
      });
      ```
      This argument will become the model context of the linked route:
         ```handlebars
      {{#link-to 'photoGallery.comment' aPhoto comment}}
        {{comment.body}}
      {{/link-to}}
      ```
         ```html
      <a href="/hamster-photos/42/comments/718">
        A+++ would snuggle again.
      </a>
      ```
         ### Supplying an explicit dynamic segment value
      If you don't have a model object available to pass to `{{link-to}}`,
      an optional string or integer argument can be passed for routes whose
      paths contain dynamic segments. This argument will become the value
      of the dynamic segment:
         ```javascript
      Router.map(function() {
        this.route("photoGallery", { path: "hamster-photos/:photo_id" });
      });
      ```
         ```handlebars
      {{#link-to 'photoGallery' aPhotoId}}
        {{aPhoto.title}}
      {{/link-to}}
      ```
         ```html
      <a href="/hamster-photos/42">
        Tomster
      </a>
      ```
         When transitioning into the linked route, the `model` hook will
      be triggered with parameters including this passed identifier.
         ### Allowing Default Action
         By default the `{{link-to}}` component prevents the default browser action
      by calling `preventDefault()` as this sort of action bubbling is normally
      handled internally and we do not want to take the browser to a new URL (for
      example).
         If you need to override this behavior specify `preventDefault=false` in
      your template:
         ```handlebars
      {{#link-to 'photoGallery' aPhotoId preventDefault=false}}
        {{aPhotoId.title}}
      {{/link-to}}
      ```
         ### Overriding attributes
      You can override any given property of the `LinkComponent`
      that is generated by the `{{link-to}}` component by passing
      key/value pairs, like so:
         ```handlebars
      {{#link-to  aPhoto tagName='li' title='Following this link will change your life' classNames='pic sweet'}}
        Uh-mazing!
      {{/link-to}}
      ```
         See [LinkComponent](/api/ember/release/classes/LinkComponent) for a
      complete list of overrideable properties. Be sure to also
      check out inherited properties of `LinkComponent`.
         ### Overriding Application-wide Defaults
         ``{{link-to}}`` creates an instance of `LinkComponent` for rendering. To
      override options for your entire application, export your customized
      `LinkComponent` from `app/components/link-to.js` with the desired overrides:
         ```javascript
      // app/components/link-to.js
      import LinkComponent from '@ember/routing/link-component';
         export default LinkComponent.extend({
        activeClass: "is-active",
        tagName: 'li'
      })
      ```
         It is also possible to override the default event in this manner:
         ```javascript
      import LinkComponent from '@ember/routing/link-component';
         export default LinkComponent.extend({
        eventName: 'customEventName'
      });
      ```
         @method link-to
      @for Ember.Templates.helpers
      @param {String} routeName
      @param {Object} [context]*
      @param [options] {Object} Handlebars key/value pairs of options, you can override any property of Ember.LinkComponent
      @return {String} HTML string
      @see {LinkComponent}
      @public
    */

    /**
      @module @ember/routing
    */

    /**
      `LinkComponent` renders an element whose `click` event triggers a
      transition of the application's instance of `Router` to
      a supplied route by name.
         `LinkComponent` components are invoked with {{#link-to}}. Properties
      of this class can be overridden with `reopen` to customize application-wide
      behavior.
         @class LinkComponent
      @extends Component
      @see {Ember.Templates.helpers.link-to}
      @public
    **/
    const EMPTY_QUERY_PARAMS = Object.freeze({
      values: Object.freeze({})
    });
    LinkComponent = Component.extend({
      layout: layout$1,
      tagName: 'a',

      /**
        Used to determine when this `LinkComponent` is active.
             @property current-when
        @public
      */
      'current-when': null,

      /**
        Sets the `title` attribute of the `LinkComponent`'s HTML element.
             @property title
        @default null
        @public
      **/
      title: null,

      /**
        Sets the `rel` attribute of the `LinkComponent`'s HTML element.
             @property rel
        @default null
        @public
      **/
      rel: null,

      /**
        Sets the `tabindex` attribute of the `LinkComponent`'s HTML element.
             @property tabindex
        @default null
        @public
      **/
      tabindex: null,

      /**
        Sets the `target` attribute of the `LinkComponent`'s HTML element.
             @since 1.8.0
        @property target
        @default null
        @public
      **/
      target: null,

      /**
        The CSS class to apply to `LinkComponent`'s element when its `active`
        property is `true`.
             @property activeClass
        @type String
        @default active
        @public
      **/
      activeClass: 'active',

      /**
        The CSS class to apply to `LinkComponent`'s element when its `loading`
        property is `true`.
             @property loadingClass
        @type String
        @default loading
        @private
      **/
      loadingClass: 'loading',

      /**
        The CSS class to apply to a `LinkComponent`'s element when its `disabled`
        property is `true`.
             @property disabledClass
        @type String
        @default disabled
        @private
      **/
      disabledClass: 'disabled',

      /**
        Determines whether the `LinkComponent` will trigger routing via
        the `replaceWith` routing strategy.
             @property replace
        @type Boolean
        @default false
        @public
      **/
      replace: false,

      /**
        By default the `{{link-to}}` component will bind to the `href` and
        `title` attributes. It's discouraged that you override these defaults,
        however you can push onto the array if needed.
             @property attributeBindings
        @type Array | String
        @default ['title', 'rel', 'tabindex', 'target']
        @public
      */
      attributeBindings: ['href', 'title', 'rel', 'tabindex', 'target'],

      /**
        By default the `{{link-to}}` component will bind to the `active`, `loading`,
        and `disabled` classes. It is discouraged to override these directly.
             @property classNameBindings
        @type Array
        @default ['active', 'loading', 'disabled', 'ember-transitioning-in', 'ember-transitioning-out']
        @public
      */
      classNameBindings: ['active', 'loading', 'disabled', 'transitioningIn', 'transitioningOut'],

      /**
        By default the `{{link-to}}` component responds to the `click` event. You
        can override this globally by setting this property to your custom
        event name.
             This is particularly useful on mobile when one wants to avoid the 300ms
        click delay using some sort of custom `tap` event.
             @property eventName
        @type String
        @default click
        @private
      */
      eventName: 'click',

      // this is doc'ed here so it shows up in the events
      // section of the API documentation, which is where
      // people will likely go looking for it.

      /**
        Triggers the `LinkComponent`'s routing behavior. If
        `eventName` is changed to a value other than `click`
        the routing behavior will trigger on that custom event
        instead.
             @event click
        @private
      */

      /**
        An overridable method called when `LinkComponent` objects are instantiated.
             Example:
             ```app/components/my-link.js
        import LinkComponent from '@ember/routing/link-component';
             export default LinkComponent.extend({
          init() {
            this._super(...arguments);
            console.log('Event is ' + this.get('eventName'));
          }
        });
        ```
             NOTE: If you do override `init` for a framework class like `Component`,
        be sure to call `this._super(...arguments)` in your
        `init` declaration! If you don't, Ember may not have an opportunity to
        do important setup work, and you'll see strange behavior in your
        application.
             @method init
        @private
      */
      init() {
        this._super(...arguments); // Map desired event name to invoke function


        let eventName = (0, _metal.get)(this, 'eventName');
        this.on(eventName, this, this._invoke);
      },

      _routing: (0, _service.inject)('-routing'),

      /**
        Accessed as a classname binding to apply the `LinkComponent`'s `disabledClass`
        CSS `class` to the element when the link is disabled.
             When `true` interactions with the element will not trigger route changes.
        @property disabled
        @private
      */
      disabled: (0, _metal.computed)({
        get(_key) {
          // always returns false for `get` because (due to the `set` just below)
          // the cached return value from the set will prevent this getter from _ever_
          // being called after a set has occured
          return false;
        },

        set(_key, value) {
          this._isDisabled = value;
          return value ? (0, _metal.get)(this, 'disabledClass') : false;
        }

      }),

      _isActive(routerState) {
        if ((0, _metal.get)(this, 'loading')) {
          return false;
        }

        let currentWhen = (0, _metal.get)(this, 'current-when');

        if (typeof currentWhen === 'boolean') {
          return currentWhen;
        }

        let isCurrentWhenSpecified = Boolean(currentWhen);
        currentWhen = currentWhen || (0, _metal.get)(this, 'qualifiedRouteName');
        currentWhen = currentWhen.split(' ');
        let routing = this._routing;
        let models = (0, _metal.get)(this, 'models');
        let resolvedQueryParams = (0, _metal.get)(this, 'resolvedQueryParams');

        for (let i = 0; i < currentWhen.length; i++) {
          if (routing.isActiveForRoute(models, resolvedQueryParams, currentWhen[i], routerState, isCurrentWhenSpecified)) {
            return true;
          }
        }

        return false;
      },

      /**
        Accessed as a classname binding to apply the `LinkComponent`'s `activeClass`
        CSS `class` to the element when the link is active.
             A `LinkComponent` is considered active when its `currentWhen` property is `true`
        or the application's current route is the route the `LinkComponent` would trigger
        transitions into.
             The `currentWhen` property can match against multiple routes by separating
        route names using the ` ` (space) character.
             @property active
        @private
      */
      active: (0, _metal.computed)('activeClass', '_active', function computeLinkToComponentActiveClass() {
        return this.get('_active') ? (0, _metal.get)(this, 'activeClass') : false;
      }),
      _active: (0, _metal.computed)('_routing.currentState', 'attrs.params', function computeLinkToComponentActive() {
        let currentState = (0, _metal.get)(this, '_routing.currentState');

        if (!currentState) {
          return false;
        }

        return this._isActive(currentState);
      }),
      willBeActive: (0, _metal.computed)('_routing.targetState', function computeLinkToComponentWillBeActive() {
        let routing = this._routing;
        let targetState = (0, _metal.get)(routing, 'targetState');

        if ((0, _metal.get)(routing, 'currentState') === targetState) {
          return;
        }

        return this._isActive(targetState);
      }),
      transitioningIn: (0, _metal.computed)('active', 'willBeActive', function computeLinkToComponentTransitioningIn() {
        if ((0, _metal.get)(this, 'willBeActive') === true && !(0, _metal.get)(this, '_active')) {
          return 'ember-transitioning-in';
        } else {
          return false;
        }
      }),
      transitioningOut: (0, _metal.computed)('active', 'willBeActive', function computeLinkToComponentTransitioningOut() {
        if ((0, _metal.get)(this, 'willBeActive') === false && (0, _metal.get)(this, '_active')) {
          return 'ember-transitioning-out';
        } else {
          return false;
        }
      }),

      /**
        Event handler that invokes the link, activating the associated route.
             @method _invoke
        @param {Event} event
        @private
      */
      _invoke(event) {
        if (!(0, _views.isSimpleClick)(event)) {
          return true;
        }

        let preventDefault = (0, _metal.get)(this, 'preventDefault');
        let targetAttribute = (0, _metal.get)(this, 'target');

        if (preventDefault !== false && (!targetAttribute || targetAttribute === '_self')) {
          event.preventDefault();
        }

        if ((0, _metal.get)(this, 'bubbles') === false) {
          event.stopPropagation();
        }

        if (this._isDisabled) {
          return false;
        }

        if ((0, _metal.get)(this, 'loading')) {
          // tslint:disable-next-line:max-line-length
          true && (0, _debug.warn)('This link-to is in an inactive loading state because at least one of its parameters presently has a null/undefined value, or the provided route name is invalid.', false, {
            id: 'ember-glimmer.link-to.inactive-loading-state'
          });
          return false;
        }

        if (targetAttribute && targetAttribute !== '_self') {
          return false;
        }

        let qualifiedRouteName = (0, _metal.get)(this, 'qualifiedRouteName');
        let models = (0, _metal.get)(this, 'models');
        let queryParams = (0, _metal.get)(this, 'queryParams.values');
        let shouldReplace = (0, _metal.get)(this, 'replace');
        let payload = {
          queryParams,
          routeName: qualifiedRouteName
        }; // tslint:disable-next-line:max-line-length

        (0, _instrumentation.flaggedInstrument)('interaction.link-to', payload, this._generateTransition(payload, qualifiedRouteName, models, queryParams, shouldReplace));
        return false;
      },

      _generateTransition(payload, qualifiedRouteName, models, queryParams, shouldReplace) {
        let routing = this._routing;
        return () => {
          payload.transition = routing.transitionTo(qualifiedRouteName, models, queryParams, shouldReplace);
        };
      },

      queryParams: EMPTY_QUERY_PARAMS,
      qualifiedRouteName: (0, _metal.computed)('targetRouteName', '_routing.currentState', function computeLinkToComponentQualifiedRouteName() {
        let params = (0, _metal.get)(this, 'params');
        let paramsLength = params.length;
        let lastParam = params[paramsLength - 1];

        if (lastParam && lastParam.isQueryParams) {
          paramsLength--;
        }

        let onlyQueryParamsSupplied = this[HAS_BLOCK] ? paramsLength === 0 : paramsLength === 1;

        if (onlyQueryParamsSupplied) {
          return (0, _metal.get)(this, '_routing.currentRouteName');
        }

        return (0, _metal.get)(this, 'targetRouteName');
      }),
      resolvedQueryParams: (0, _metal.computed)('queryParams', function computeLinkToComponentResolvedQueryParams() {
        let resolvedQueryParams = {};
        let queryParams = (0, _metal.get)(this, 'queryParams');

        if (queryParams !== EMPTY_QUERY_PARAMS) {
          let {
            values
          } = queryParams;
          (0, _polyfills.assign)(resolvedQueryParams, values);
        }

        return resolvedQueryParams;
      }),

      /**
        Sets the element's `href` attribute to the url for
        the `LinkComponent`'s targeted route.
             If the `LinkComponent`'s `tagName` is changed to a value other
        than `a`, this property will be ignored.
             @property href
        @private
      */
      href: (0, _metal.computed)('models', 'qualifiedRouteName', function computeLinkToComponentHref() {
        if ((0, _metal.get)(this, 'tagName') !== 'a') {
          return;
        }

        let qualifiedRouteName = (0, _metal.get)(this, 'qualifiedRouteName');
        let models = (0, _metal.get)(this, 'models');

        if ((0, _metal.get)(this, 'loading')) {
          return (0, _metal.get)(this, 'loadingHref');
        }

        let routing = this._routing;
        let queryParams = (0, _metal.get)(this, 'queryParams.values');

        if (true
        /* DEBUG */
        ) {
            /*
             * Unfortunately, to get decent error messages, we need to do this.
             * In some future state we should be able to use a "feature flag"
             * which allows us to strip this without needing to call it twice.
             *
             * if (isDebugBuild()) {
             *   // Do the useful debug thing, probably including try/catch.
             * } else {
             *   // Do the performant thing.
             * }
             */
            try {
              routing.generateURL(qualifiedRouteName, models, queryParams);
            } catch (e) {
              // tslint:disable-next-line:max-line-length
              true && !false && (0, _debug.assert)('You attempted to define a `{{link-to "' + qualifiedRouteName + '"}}` but did not pass the parameters required for generating its dynamic segments. ' + e.message);
            }
          }

        return routing.generateURL(qualifiedRouteName, models, queryParams);
      }),
      loading: (0, _metal.computed)('_modelsAreLoaded', 'qualifiedRouteName', function computeLinkToComponentLoading() {
        let qualifiedRouteName = (0, _metal.get)(this, 'qualifiedRouteName');
        let modelsAreLoaded = (0, _metal.get)(this, '_modelsAreLoaded');

        if (!modelsAreLoaded || qualifiedRouteName === null || qualifiedRouteName === undefined) {
          return (0, _metal.get)(this, 'loadingClass');
        }
      }),
      _modelsAreLoaded: (0, _metal.computed)('models', function computeLinkToComponentModelsAreLoaded() {
        let models = (0, _metal.get)(this, 'models');

        for (let i = 0; i < models.length; i++) {
          let model = models[i];

          if (model === null || model === undefined) {
            return false;
          }
        }

        return true;
      }),

      /**
        The default href value to use while a link-to is loading.
        Only applies when tagName is 'a'
             @property loadingHref
        @type String
        @default #
        @private
      */
      loadingHref: '#',

      didReceiveAttrs() {
        let queryParams;
        let params = (0, _metal.get)(this, 'params');

        if (params) {
          // Do not mutate params in place
          params = params.slice();
        }

        true && !(params && params.length > 0) && (0, _debug.assert)('You must provide one or more parameters to the `{{link-to}}` component.', params && params.length > 0);
        let disabledWhen = (0, _metal.get)(this, 'disabledWhen');

        if (disabledWhen !== undefined) {
          this.set('disabled', disabledWhen);
        } // Process the positional arguments, in order.
        // 1. Inline link title comes first, if present.


        if (!this[HAS_BLOCK]) {
          this.set('linkTitle', params.shift());
        } // 2. `targetRouteName` is now always at index 0.


        this.set('targetRouteName', params[0]); // 3. The last argument (if still remaining) is the `queryParams` object.

        let lastParam = params[params.length - 1];

        if (lastParam && lastParam.isQueryParams) {
          queryParams = params.pop();
        } else {
          queryParams = EMPTY_QUERY_PARAMS;
        }

        this.set('queryParams', queryParams); // 4. Any remaining indices (excepting `targetRouteName` at 0) are `models`.

        params.shift();
        this.set('models', params);
      }

    });

    LinkComponent.toString = () => '@ember/routing/link-component';

    LinkComponent.reopenClass({
      positionalParams: 'params'
    });
  }

  var LinkToComponent = LinkComponent; // @ts-check

  _exports.LinkComponent = LinkToComponent;
  let DebugStack;

  if (true
  /* DEBUG */
  ) {
      class Element {
        constructor(name) {
          this.name = name;
        }

      }

      class TemplateElement extends Element {}

      class EngineElement extends Element {} // tslint:disable-next-line:no-shadowed-variable


      DebugStack = class DebugStack {
        constructor() {
          this._stack = [];
        }

        push(name) {
          this._stack.push(new TemplateElement(name));
        }

        pushEngine(name) {
          this._stack.push(new EngineElement(name));
        }

        pop() {
          let element = this._stack.pop();

          if (element) {
            return element.name;
          }
        }

        peek() {
          let template = this._currentTemplate();

          let engine = this._currentEngine();

          if (engine) {
            return "\"" + template + "\" (in \"" + engine + "\")";
          } else if (template) {
            return "\"" + template + "\"";
          }
        }

        _currentTemplate() {
          return this._getCurrentByType(TemplateElement);
        }

        _currentEngine() {
          return this._getCurrentByType(EngineElement);
        }

        _getCurrentByType(type) {
          for (let i = this._stack.length; i >= 0; i--) {
            let element = this._stack[i];

            if (element instanceof type) {
              return element.name;
            }
          }
        }

      };
    }

  var DebugStack$1 = DebugStack;
  /**
  @module ember
  */

  /**
    The `{{#each}}` helper loops over elements in a collection. It is an extension
    of the base Handlebars `{{#each}}` helper.
    The default behavior of `{{#each}}` is to yield its inner block once for every
    item in an array passing the item as the first block parameter.
  
    ```javascript
    var developers = [{ name: 'Yehuda' },{ name: 'Tom' }, { name: 'Paul' }];
    ```
  
    ```handlebars
    {{#each developers key="name" as |person|}}
      {{person.name}}
      {{! `this` is whatever it was outside the #each }}
    {{/each}}
    ```
  
    The same rules apply to arrays of primitives.
  
    ```javascript
    var developerNames = ['Yehuda', 'Tom', 'Paul']
    ```
  
    ```handlebars
    {{#each developerNames key="@index" as |name|}}
      {{name}}
    {{/each}}
    ```
  
    During iteration, the index of each item in the array is provided as a second block parameter.
  
    ```handlebars
    <ul>
      {{#each people as |person index|}}
        <li>Hello, {{person.name}}! You're number {{index}} in line</li>
      {{/each}}
    </ul>
    ```
  
    ### Specifying Keys
  
    The `key` option is used to tell Ember how to determine if the array being
    iterated over with `{{#each}}` has changed between renders. By helping Ember
    detect that some elements in the array are the same, DOM elements can be
    re-used, significantly improving rendering speed.
  
    For example, here's the `{{#each}}` helper with its `key` set to `id`:
  
    ```handlebars
    {{#each model key="id" as |item|}}
    {{/each}}
    ```
  
    When this `{{#each}}` re-renders, Ember will match up the previously rendered
    items (and reorder the generated DOM elements) based on each item's `id`
    property.
    By default the item's own reference is used.
  
    ### {{else}} condition
  
    `{{#each}}` can have a matching `{{else}}`. The contents of this block will render
    if the collection is empty.
  
    ```handlebars
    {{#each developers as |person|}}
      {{person.name}}
    {{else}}
      <p>Sorry, nobody is available for this task.</p>
    {{/each}}
    ```
  
    @method each
    @for Ember.Templates.helpers
    @public
   */

  /**
    The `{{each-in}}` helper loops over properties on an object.
  
    For example, given a `user` object that looks like:
  
    ```javascript
    {
      "name": "Shelly Sails",
      "age": 42
    }
    ```
  
    This template would display all properties on the `user`
    object in a list:
  
    ```handlebars
    <ul>
    {{#each-in user as |key value|}}
      <li>{{key}}: {{value}}</li>
    {{/each-in}}
    </ul>
    ```
  
    Outputting their name and age.
  
    @method each-in
    @for Ember.Templates.helpers
    @public
    @since 2.1.0
  */

  _exports.DebugStack = DebugStack$1;
  const EACH_IN_REFERENCE = (0, _utils.symbol)('EACH_IN');

  class EachInReference {
    constructor(inner) {
      this.inner = inner;
      this.tag = inner.tag;
      this[EACH_IN_REFERENCE] = true;
    }

    value() {
      return this.inner.value();
    }

    get(key) {
      return this.inner.get(key);
    }

  }

  function isEachIn(ref) {
    return ref !== null && typeof ref === 'object' && ref[EACH_IN_REFERENCE];
  }

  function eachIn(_vm, args) {
    return new EachInReference(args.positional.at(0));
  }

  const ITERATOR_KEY_GUID = 'be277757-bbbe-4620-9fcb-213ef433cca2';

  function iterableFor(ref, keyPath) {
    if (isEachIn(ref)) {
      return new EachInIterable(ref, keyPath || '@key');
    } else {
      return new EachIterable(ref, keyPath || '@identity');
    }
  }

  class BoundedIterator {
    constructor(length, keyFor) {
      this.length = length;
      this.keyFor = keyFor;
      this.position = 0;
    }

    isEmpty() {
      return false;
    }

    memoFor(position) {
      return position;
    }

    next() {
      let {
        length,
        keyFor,
        position
      } = this;

      if (position >= length) {
        return null;
      }

      let value = this.valueFor(position);
      let memo = this.memoFor(position);
      let key = keyFor(value, memo, position);
      this.position++;
      return {
        key,
        value,
        memo
      };
    }

  }

  class ArrayIterator extends BoundedIterator {
    constructor(array, length, keyFor) {
      super(length, keyFor);
      this.array = array;
    }

    static from(array, keyFor) {
      let {
        length
      } = array;

      if (length === 0) {
        return EMPTY_ITERATOR;
      } else {
        return new this(array, length, keyFor);
      }
    }

    static fromForEachable(object, keyFor) {
      let array = [];
      object.forEach(item => array.push(item));
      return this.from(array, keyFor);
    }

    valueFor(position) {
      return this.array[position];
    }

  }

  class EmberArrayIterator extends BoundedIterator {
    constructor(array, length, keyFor) {
      super(length, keyFor);
      this.array = array;
    }

    static from(array, keyFor) {
      let {
        length
      } = array;

      if (length === 0) {
        return EMPTY_ITERATOR;
      } else {
        return new this(array, length, keyFor);
      }
    }

    valueFor(position) {
      return (0, _metal.objectAt)(this.array, position);
    }

  }

  class ObjectIterator extends BoundedIterator {
    constructor(keys, values, length, keyFor) {
      super(length, keyFor);
      this.keys = keys;
      this.values = values;
    }

    static fromIndexable(obj, keyFor) {
      let keys = Object.keys(obj);
      let {
        length
      } = keys;

      if (length === 0) {
        return EMPTY_ITERATOR;
      } else {
        let values = [];

        for (let i = 0; i < length; i++) {
          values.push((0, _metal.get)(obj, keys[i]));
        }

        return new this(keys, values, length, keyFor);
      }
    }

    static fromForEachable(obj, keyFor) {
      let keys = [];
      let values = [];
      let length = 0;
      let isMapLike = false;
      obj.forEach((value, key) => {
        isMapLike = isMapLike || arguments.length >= 2;

        if (isMapLike) {
          keys.push(key);
        }

        values.push(value);
        length++;
      });

      if (length === 0) {
        return EMPTY_ITERATOR;
      } else if (isMapLike) {
        return new this(keys, values, length, keyFor);
      } else {
        return new ArrayIterator(values, length, keyFor);
      }
    }

    valueFor(position) {
      return this.values[position];
    }

    memoFor(position) {
      return this.keys[position];
    }

  }

  class NativeIterator {
    constructor(iterable, result, keyFor) {
      this.iterable = iterable;
      this.result = result;
      this.keyFor = keyFor;
      this.position = 0;
    }

    static from(iterable, keyFor) {
      let iterator = iterable[Symbol.iterator]();
      let result = iterator.next();
      let {
        value,
        done
      } = result;

      if (done) {
        return EMPTY_ITERATOR;
      } else if (Array.isArray(value) && value.length === 2) {
        return new this(iterator, result, keyFor);
      } else {
        return new ArrayLikeNativeIterator(iterator, result, keyFor);
      }
    }

    isEmpty() {
      return false;
    }

    next() {
      let {
        iterable,
        result,
        position,
        keyFor
      } = this;

      if (result.done) {
        return null;
      }

      let value = this.valueFor(result, position);
      let memo = this.memoFor(result, position);
      let key = keyFor(value, memo, position);
      this.position++;
      this.result = iterable.next();
      return {
        key,
        value,
        memo
      };
    }

  }

  class ArrayLikeNativeIterator extends NativeIterator {
    valueFor(result) {
      return result.value;
    }

    memoFor(_result, position) {
      return position;
    }

  }

  class MapLikeNativeIterator extends NativeIterator {
    valueFor(result) {
      return result.value[1];
    }

    memoFor(result) {
      return result.value[0];
    }

  }

  const EMPTY_ITERATOR = {
    isEmpty() {
      return true;
    },

    next() {
      true && !false && (0, _debug.assert)('Cannot call next() on an empty iterator');
      return null;
    }

  };

  class EachInIterable {
    constructor(ref, keyPath) {
      this.ref = ref;
      this.keyPath = keyPath;
      this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
      this.tag = (0, _reference.combine)([ref.tag, this.valueTag]);
    }

    iterate() {
      let {
        ref,
        valueTag
      } = this;
      let iterable = ref.value();
      let tag = (0, _metal.tagFor)(iterable);

      if ((0, _utils.isProxy)(iterable)) {
        // this is because the each-in doesn't actually get(proxy, 'key') but bypasses it
        // and the proxy's tag is lazy updated on access
        iterable = (0, _runtime._contentFor)(iterable);
      }

      valueTag.inner.update(tag);

      if (!isIndexable(iterable)) {
        return EMPTY_ITERATOR;
      }

      if (Array.isArray(iterable) || (0, _runtime.isEmberArray)(iterable)) {
        return ObjectIterator.fromIndexable(iterable, this.keyFor(true));
      } else if (_utils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
        return MapLikeNativeIterator.from(iterable, this.keyFor());
      } else if (hasForEach(iterable)) {
        return ObjectIterator.fromForEachable(iterable, this.keyFor());
      } else {
        return ObjectIterator.fromIndexable(iterable, this.keyFor(true));
      }
    }

    valueReferenceFor(item) {
      return new UpdatableReference(item.value);
    }

    updateValueReference(ref, item) {
      ref.update(item.value);
    }

    memoReferenceFor(item) {
      return new UpdatableReference(item.memo);
    }

    updateMemoReference(ref, item) {
      ref.update(item.memo);
    }

    keyFor(hasUniqueKeys = false) {
      let {
        keyPath
      } = this;

      switch (keyPath) {
        case '@key':
          return hasUniqueKeys ? ObjectKey : Unique(MapKey);

        case '@index':
          return Index;

        case '@identity':
          return Unique(Identity);

        default:
          true && !(keyPath[0] !== '@') && (0, _debug.assert)("Invalid key: " + keyPath, keyPath[0] !== '@');
          return Unique(KeyPath(keyPath));
      }
    }

  }

  class EachIterable {
    constructor(ref, keyPath) {
      this.ref = ref;
      this.keyPath = keyPath;
      this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
      this.tag = (0, _reference.combine)([ref.tag, this.valueTag]);
    }

    iterate() {
      let {
        ref,
        valueTag
      } = this;
      let iterable = ref.value();
      valueTag.inner.update((0, _metal.tagForProperty)(iterable, '[]'));

      if (iterable === null || typeof iterable !== 'object') {
        return EMPTY_ITERATOR;
      }

      let keyFor = this.keyFor();

      if (Array.isArray(iterable)) {
        return ArrayIterator.from(iterable, keyFor);
      } else if ((0, _runtime.isEmberArray)(iterable)) {
        return EmberArrayIterator.from(iterable, keyFor);
      } else if (_utils.HAS_NATIVE_SYMBOL && isNativeIterable(iterable)) {
        return ArrayLikeNativeIterator.from(iterable, keyFor);
      } else if (hasForEach(iterable)) {
        return ArrayIterator.fromForEachable(iterable, keyFor);
      } else {
        return EMPTY_ITERATOR;
      }
    }

    valueReferenceFor(item) {
      return new UpdatableReference(item.value);
    }

    updateValueReference(ref, item) {
      ref.update(item.value);
    }

    memoReferenceFor(item) {
      return new UpdatableReference(item.memo);
    }

    updateMemoReference(ref, item) {
      ref.update(item.memo);
    }

    keyFor() {
      let {
        keyPath
      } = this;

      switch (keyPath) {
        case '@index':
          return Index;

        case '@identity':
          return Unique(Identity);

        default:
          true && !(keyPath[0] !== '@') && (0, _debug.assert)("Invalid key: " + keyPath, keyPath[0] !== '@');
          return Unique(KeyPath(keyPath));
      }
    }

  }

  function hasForEach(value) {
    return typeof value['forEach'] === 'function';
  }

  function isNativeIterable(value) {
    return typeof value[Symbol.iterator] === 'function';
  }

  function isIndexable(value) {
    return value !== null && (typeof value === 'object' || typeof value === 'function');
  } // Position in an array is guarenteed to be unique


  function Index(_value, _memo, position) {
    return String(position);
  } // Object.keys(...) is guarenteed to be strings and unique


  function ObjectKey(_value, memo) {
    return memo;
  } // Map keys can be any objects


  function MapKey(_value, memo) {
    return Identity(memo);
  }

  function Identity(value) {
    switch (typeof value) {
      case 'string':
        return value;

      case 'number':
        return String(value);

      default:
        return (0, _utils.guidFor)(value);
    }
  }

  function KeyPath(keyPath) {
    return value => String((0, _metal.get)(value, keyPath));
  }

  function Unique(func) {
    let seen = {};
    return (value, memo, position) => {
      let key = func(value, memo, position);
      let count = seen[key];

      if (count === undefined) {
        seen[key] = 0;
        return key;
      } else {
        seen[key] = ++count;
        return "" + key + ITERATOR_KEY_GUID + count;
      }
    };
  }
  /**
  @module @ember/template
  */


  class SafeString {
    constructor(string) {
      this.string = string;
    }

    toString() {
      return "" + this.string;
    }

    toHTML() {
      return this.toString();
    }

  }

  _exports.SafeString = SafeString;
  const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
  };
  const possible = /[&<>"'`=]/;
  const badChars = /[&<>"'`=]/g;

  function escapeChar(chr) {
    return escape[chr];
  }

  function escapeExpression(string) {
    if (typeof string !== 'string') {
      // don't escape SafeStrings, since they're already safe
      if (string && string.toHTML) {
        return string.toHTML();
      } else if (string === null || string === undefined) {
        return '';
      } else if (!string) {
        return String(string);
      } // Force a string conversion as this will be done by the append regardless and
      // the regex test will do this transparently behind the scenes, causing issues if
      // an object's to string has escaped characters in it.


      string = String(string);
    }

    if (!possible.test(string)) {
      return string;
    }

    return string.replace(badChars, escapeChar);
  }
  /**
    Mark a string as safe for unescaped output with Ember templates. If you
    return HTML from a helper, use this function to
    ensure Ember's rendering layer does not escape the HTML.
  
    ```javascript
    import { htmlSafe } from '@ember/template';
  
    htmlSafe('<div>someString</div>')
    ```
  
    @method htmlSafe
    @for @ember/template
    @static
    @return {SafeString} A string that will not be HTML escaped by Handlebars.
    @public
  */


  function htmlSafe(str) {
    if (str === null || str === undefined) {
      str = '';
    } else if (typeof str !== 'string') {
      str = String(str);
    }

    return new SafeString(str);
  }
  /**
    Detects if a string was decorated using `htmlSafe`.
  
    ```javascript
    import { htmlSafe, isHTMLSafe } from '@ember/template';
  
    var plainString = 'plain string',
        safeString = htmlSafe('<div>someValue</div>');
  
    isHTMLSafe(plainString); // false
    isHTMLSafe(safeString);  // true
    ```
  
    @method isHTMLSafe
    @for @ember/template
    @static
    @return {Boolean} `true` if the string was decorated with `htmlSafe`, `false` otherwise.
    @public
  */


  function isHTMLSafe(str) {
    return str !== null && typeof str === 'object' && typeof str.toHTML === 'function';
  }
  /* globals module, URL */


  let nodeURL;
  let parsingNode;

  function installProtocolForURL(environment) {
    let protocol;

    if (_browserEnvironment.hasDOM) {
      protocol = browserProtocolForURL.call(environment, 'foobar:baz');
    } // Test to see if our DOM implementation parses
    // and normalizes URLs.


    if (protocol === 'foobar:') {
      // Swap in the method that doesn't do this test now that
      // we know it works.
      environment.protocolForURL = browserProtocolForURL;
    } else if (typeof URL === 'object') {
      // URL globally provided, likely from FastBoot's sandbox
      nodeURL = URL;
      environment.protocolForURL = nodeProtocolForURL;
    } else if (typeof _nodeModule.require === 'function') {
      // Otherwise, we need to fall back to our own URL parsing.
      // Global `require` is shadowed by Ember's loader so we have to use the fully
      // qualified `module.require`.
      // tslint:disable-next-line:no-require-imports
      nodeURL = (0, _nodeModule.require)("url");
      environment.protocolForURL = nodeProtocolForURL;
    } else {
      throw new Error('Could not find valid URL parsing mechanism for URL Sanitization');
    }
  }

  function browserProtocolForURL(url) {
    if (!parsingNode) {
      parsingNode = document.createElement('a');
    }

    parsingNode.href = url;
    return parsingNode.protocol;
  }

  function nodeProtocolForURL(url) {
    let protocol = null;

    if (typeof url === 'string') {
      protocol = nodeURL.parse(url).protocol;
    }

    return protocol === null ? ':' : protocol;
  }

  class Environment$1 extends _runtime2.Environment {
    constructor(injections) {
      super(injections);
      this.inTransaction = false;
      this.owner = injections[_owner.OWNER];
      this.isInteractive = this.owner.lookup('-environment:main').isInteractive; // can be removed once https://github.com/tildeio/glimmer/pull/305 lands

      this.destroyedComponents = [];
      installProtocolForURL(this);

      if (true
      /* DEBUG */
      ) {
          this.debugStack = new DebugStack$1();
        }
    }

    static create(options) {
      return new this(options);
    } // this gets clobbered by installPlatformSpecificProtocolForURL
    // it really should just delegate to a platform specific injection


    protocolForURL(s) {
      return s;
    }

    lookupComponent(name, meta) {
      return (0, _views.lookupComponent)(meta.owner, name, meta);
    }

    toConditionalReference(reference) {
      return ConditionalReference$1.create(reference);
    }

    iterableFor(ref, key) {
      return iterableFor(ref, key);
    }

    scheduleInstallModifier(modifier, manager) {
      if (this.isInteractive) {
        super.scheduleInstallModifier(modifier, manager);
      }
    }

    scheduleUpdateModifier(modifier, manager) {
      if (this.isInteractive) {
        super.scheduleUpdateModifier(modifier, manager);
      }
    }

    didDestroy(destroyable) {
      destroyable.destroy();
    }

    begin() {
      this.inTransaction = true;
      super.begin();
    }

    commit() {
      let destroyedComponents = this.destroyedComponents;
      this.destroyedComponents = []; // components queued for destruction must be destroyed before firing
      // `didCreate` to prevent errors when removing and adding a component
      // with the same name (would throw an error when added to view registry)

      for (let i = 0; i < destroyedComponents.length; i++) {
        destroyedComponents[i].destroy();
      }

      try {
        super.commit();
      } finally {
        this.inTransaction = false;
      }
    }

  }

  _exports.Environment = Environment$1;

  if (true
  /* DEBUG */
  ) {
      class StyleAttributeManager extends _runtime2.SimpleDynamicAttribute {
        set(dom, value, env) {
          true && (0, _debug.warn)((0, _views.constructStyleDeprecationMessage)(value), (() => {
            if (value === null || value === undefined || isHTMLSafe(value)) {
              return true;
            }

            return false;
          })(), {
            id: 'ember-htmlbars.style-xss-warning'
          });
          super.set(dom, value, env);
        }

        update(value, env) {
          true && (0, _debug.warn)((0, _views.constructStyleDeprecationMessage)(value), (() => {
            if (value === null || value === undefined || isHTMLSafe(value)) {
              return true;
            }

            return false;
          })(), {
            id: 'ember-htmlbars.style-xss-warning'
          });
          super.update(value, env);
        }

      }

      Environment$1.prototype.attributeFor = function (element, attribute, isTrusting, namespace) {
        if (attribute === 'style' && !isTrusting) {
          return new StyleAttributeManager({
            element,
            name: attribute,
            namespace
          });
        }

        return _runtime2.Environment.prototype.attributeFor.call(this, element, attribute, isTrusting, namespace);
      };
    } // implements the ComponentManager interface as defined in glimmer:
  // tslint:disable-next-line:max-line-length
  // https://github.com/glimmerjs/glimmer-vm/blob/v0.24.0-beta.4/packages/%40glimmer/runtime/lib/component/interfaces.ts#L21


  class AbstractManager {
    constructor() {
      this.debugStack = undefined;
    }

    prepareArgs(_state, _args) {
      return null;
    }

    didCreateElement(_component, _element, _operations) {} // noop
    // inheritors should also call `this.debugStack.pop()` to
    // ensure the rerendering assertion messages are properly
    // maintained


    didRenderLayout(_component, _bounds) {// noop
    }

    didCreate(_bucket) {} // noop
    // inheritors should also call `this._pushToDebugStack`
    // to ensure the rerendering assertion messages are
    // properly maintained


    update(_bucket, _dynamicScope) {} // noop
    // inheritors should also call `this.debugStack.pop()` to
    // ensure the rerendering assertion messages are properly
    // maintained


    didUpdateLayout(_bucket, _bounds) {// noop
    }

    didUpdate(_bucket) {// noop
    }

  }

  _exports.AbstractComponentManager = AbstractManager;

  if (true
  /* DEBUG */
  ) {
      AbstractManager.prototype._pushToDebugStack = function (name, environment) {
        this.debugStack = environment.debugStack;
        this.debugStack.push(name);
      };

      AbstractManager.prototype._pushEngineToDebugStack = function (name, environment) {
        this.debugStack = environment.debugStack;
        this.debugStack.pushEngine(name);
      };
    }

  function instrumentationPayload(def) {
    return {
      object: def.name + ":" + def.outlet
    };
  }

  const CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    createCaller: true,
    dynamicScope: true,
    updateHook: false,
    createInstance: true
  };

  class OutletComponentManager extends AbstractManager {
    create(environment, definition, _args, dynamicScope) {
      if (true
      /* DEBUG */
      ) {
          this._pushToDebugStack("template:" + definition.template.referrer.moduleName, environment);
        }

      dynamicScope.outletState = definition.ref;
      let controller = definition.controller;
      let self = controller === undefined ? _runtime2.UNDEFINED_REFERENCE : new RootReference(controller);
      return {
        self,
        finalize: (0, _instrumentation._instrumentStart)('render.outlet', instrumentationPayload, definition)
      };
    }

    getLayout({
      template
    }, _resolver) {
      // The router has already resolved the template
      const layout = template.asLayout();
      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

    getCapabilities() {
      return CAPABILITIES;
    }

    getSelf({
      self
    }) {
      return self;
    }

    getTag() {
      // an outlet has no hooks
      return _reference.CONSTANT_TAG;
    }

    didRenderLayout(state) {
      state.finalize();

      if (true
      /* DEBUG */
      ) {
          this.debugStack.pop();
        }
    }

    getDestructor() {
      return null;
    }

  }

  const OUTLET_MANAGER = new OutletComponentManager();

  class OutletComponentDefinition {
    constructor(state, manager = OUTLET_MANAGER) {
      this.state = state;
      this.manager = manager;
    }

  }

  function createRootOutlet(outletView) {
    if (_environment2.ENV._APPLICATION_TEMPLATE_WRAPPER) {
      const WRAPPED_CAPABILITIES = (0, _polyfills.assign)({}, CAPABILITIES, {
        dynamicTag: true,
        elementHook: true
      });
      const WrappedOutletComponentManager = class extends OutletComponentManager {
        getTagName(_component) {
          return 'div';
        }

        getLayout(state) {
          // The router has already resolved the template
          const template = state.template;
          const layout = template.asWrappedLayout();
          return {
            handle: layout.compile(),
            symbolTable: layout.symbolTable
          };
        }

        getCapabilities() {
          return WRAPPED_CAPABILITIES;
        }

        didCreateElement(component, element, _operations) {
          // to add GUID id and class
          element.setAttribute('class', 'ember-view');
          element.setAttribute('id', (0, _utils.guidFor)(component));
        }

      };
      const WRAPPED_OUTLET_MANAGER = new WrappedOutletComponentManager();
      return new OutletComponentDefinition(outletView.state, WRAPPED_OUTLET_MANAGER);
    } else {
      return new OutletComponentDefinition(outletView.state);
    }
  } // tslint:disable-next-line:no-empty


  function NOOP() {}
  /**
    @module ember
  */

  /**
    Represents the internal state of the component.
  
    @class ComponentStateBucket
    @private
  */


  class ComponentStateBucket {
    constructor(environment, component, args, finalizer, hasWrappedElement) {
      this.environment = environment;
      this.component = component;
      this.args = args;
      this.finalizer = finalizer;
      this.hasWrappedElement = hasWrappedElement;
      this.classRef = null;
      this.classRef = null;
      this.argsRevision = args === null ? 0 : args.tag.value();
    }

    destroy() {
      let {
        component,
        environment
      } = this;

      if (environment.isInteractive) {
        component.trigger('willDestroyElement');
        component.trigger('willClearRender');
        let element = (0, _views.getViewElement)(component);

        if (element) {
          (0, _views.clearElementView)(element);
          (0, _views.clearViewElement)(component);
        }
      }

      environment.destroyedComponents.push(component);
    }

    finalize() {
      let {
        finalizer
      } = this;
      finalizer();
      this.finalizer = NOOP;
    }

  }

  function referenceForKey(component, key) {
    return component[ROOT_REF].get(key);
  }

  function referenceForParts(component, parts) {
    let isAttrs = parts[0] === 'attrs'; // TODO deprecate this

    if (isAttrs) {
      parts.shift();

      if (parts.length === 1) {
        return referenceForKey(component, parts[0]);
      }
    }

    return referenceFromParts(component[ROOT_REF], parts);
  } // TODO we should probably do this transform at build time


  function wrapComponentClassAttribute(hash) {
    if (hash === null) {
      return;
    }

    let [keys, values] = hash;
    let index = keys === null ? -1 : keys.indexOf('class');

    if (index !== -1) {
      let value = values[index];

      if (!Array.isArray(value)) {
        return;
      }

      let [type] = value;

      if (type === _wireFormat.Ops.Get || type === _wireFormat.Ops.MaybeLocal) {
        let path = value[value.length - 1];
        let propName = path[path.length - 1];
        values[index] = [_wireFormat.Ops.Helper, '-class', [value, propName], null];
      }
    }
  }

  const AttributeBinding = {
    parse(microsyntax) {
      let colonIndex = microsyntax.indexOf(':');

      if (colonIndex === -1) {
        true && !(microsyntax !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', microsyntax !== 'class');
        return [microsyntax, microsyntax, true];
      } else {
        let prop = microsyntax.substring(0, colonIndex);
        let attribute = microsyntax.substring(colonIndex + 1);
        true && !(attribute !== 'class') && (0, _debug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', attribute !== 'class');
        return [prop, attribute, false];
      }
    },

    install(_element, component, parsed, operations) {
      let [prop, attribute, isSimple] = parsed;

      if (attribute === 'id') {
        let elementId = (0, _metal.get)(component, prop);

        if (elementId === undefined || elementId === null) {
          elementId = component.elementId;
        }

        elementId = _runtime2.PrimitiveReference.create(elementId);
        operations.setAttribute('id', elementId, true, null); // operations.addStaticAttribute(element, 'id', elementId);

        return;
      }

      let isPath = prop.indexOf('.') > -1;
      let reference = isPath ? referenceForParts(component, prop.split('.')) : referenceForKey(component, prop);
      true && !!(isSimple && isPath) && (0, _debug.assert)("Illegal attributeBinding: '" + prop + "' is not a valid attribute name.", !(isSimple && isPath));

      if (attribute === 'style') {
        reference = new StyleBindingReference(reference, referenceForKey(component, 'isVisible'));
      }

      operations.setAttribute(attribute, reference, false, null); // operations.addDynamicAttribute(element, attribute, reference, false);
    }

  };
  const DISPLAY_NONE = 'display: none;';
  const SAFE_DISPLAY_NONE = htmlSafe(DISPLAY_NONE);

  class StyleBindingReference extends _reference.CachedReference {
    constructor(inner, isVisible) {
      super();
      this.inner = inner;
      this.isVisible = isVisible;
      this.tag = (0, _reference.combine)([inner.tag, isVisible.tag]);
    }

    compute() {
      let value = this.inner.value();
      let isVisible = this.isVisible.value();

      if (isVisible !== false) {
        return value;
      } else if (!value) {
        return SAFE_DISPLAY_NONE;
      } else {
        let style = value + ' ' + DISPLAY_NONE;
        return isHTMLSafe(value) ? htmlSafe(style) : style;
      }
    }

  }

  const IsVisibleBinding = {
    install(_element, component, operations) {
      operations.setAttribute('style', (0, _reference.map)(referenceForKey(component, 'isVisible'), this.mapStyleValue), false, null); // // the upstream type for addDynamicAttribute's `value` argument
      // // appears to be incorrect. It is currently a Reference<string>, I
      // // think it should be a Reference<string|null>.
      // operations.addDynamicAttribute(element, 'style', ref as any as Reference<string>, false);
    },

    mapStyleValue(isVisible) {
      return isVisible === false ? SAFE_DISPLAY_NONE : null;
    }

  };
  const ClassNameBinding = {
    install(_element, component, microsyntax, operations) {
      let [prop, truthy, falsy] = microsyntax.split(':');
      let isStatic = prop === '';

      if (isStatic) {
        operations.setAttribute('class', _runtime2.PrimitiveReference.create(truthy), true, null);
      } else {
        let isPath = prop.indexOf('.') > -1;
        let parts = isPath ? prop.split('.') : [];
        let value = isPath ? referenceForParts(component, parts) : referenceForKey(component, prop);
        let ref;

        if (truthy === undefined) {
          ref = new SimpleClassNameBindingReference(value, isPath ? parts[parts.length - 1] : prop);
        } else {
          ref = new ColonClassNameBindingReference(value, truthy, falsy);
        }

        operations.setAttribute('class', ref, false, null); // // the upstream type for addDynamicAttribute's `value` argument
        // // appears to be incorrect. It is currently a Reference<string>, I
        // // think it should be a Reference<string|null>.
        // operations.addDynamicAttribute(element, 'class', ref as any as Reference<string>, false);
      }
    }

  };

  class SimpleClassNameBindingReference extends _reference.CachedReference {
    constructor(inner, path) {
      super();
      this.inner = inner;
      this.path = path;
      this.tag = inner.tag;
      this.inner = inner;
      this.path = path;
      this.dasherizedPath = null;
    }

    compute() {
      let value = this.inner.value();

      if (value === true) {
        let {
          path,
          dasherizedPath
        } = this;
        return dasherizedPath || (this.dasherizedPath = (0, _string.dasherize)(path));
      } else if (value || value === 0) {
        return String(value);
      } else {
        return null;
      }
    }

  }

  class ColonClassNameBindingReference extends _reference.CachedReference {
    constructor(inner, truthy = null, falsy = null) {
      super();
      this.inner = inner;
      this.truthy = truthy;
      this.falsy = falsy;
      this.tag = inner.tag;
    }

    compute() {
      let {
        inner,
        truthy,
        falsy
      } = this;
      return inner.value() ? truthy : falsy;
    }

  } // ComponentArgs takes EvaluatedNamedArgs and converts them into the
  // inputs needed by CurlyComponents (attrs and props, with mutable
  // cells, etc).


  function processComponentArgs(namedArgs) {
    let keys = namedArgs.names;
    let attrs = namedArgs.value();
    let props = Object.create(null);
    let args = Object.create(null);
    props[ARGS] = args;

    for (let i = 0; i < keys.length; i++) {
      let name = keys[i];
      let ref = namedArgs.get(name);
      let value = attrs[name];

      if (typeof value === 'function' && value[ACTION]) {
        attrs[name] = value;
      } else if (ref[UPDATE]) {
        attrs[name] = new MutableCell(ref, value);
      }

      args[name] = ref;
      props[name] = value;
    }

    props.attrs = attrs;
    return props;
  }

  const REF = (0, _utils.symbol)('REF');

  class MutableCell {
    constructor(ref, value) {
      this[_views.MUTABLE_CELL] = true;
      this[REF] = ref;
      this.value = value;
    }

    update(val) {
      this[REF][UPDATE](val);
    }

  }

  function aliasIdToElementId(args, props) {
    if (args.named.has('id')) {
      // tslint:disable-next-line:max-line-length
      true && !!args.named.has('elementId') && (0, _debug.assert)("You cannot invoke a component with both 'id' and 'elementId' at the same time.", !args.named.has('elementId'));
      props.elementId = props.id;
    }
  }

  function isTemplateFactory(template) {
    return typeof template.create === 'function';
  } // We must traverse the attributeBindings in reverse keeping track of
  // what has already been applied. This is essentially refining the concatenated
  // properties applying right to left.


  function applyAttributeBindings(element, attributeBindings, component, operations) {
    let seen = [];
    let i = attributeBindings.length - 1;

    while (i !== -1) {
      let binding = attributeBindings[i];
      let parsed = AttributeBinding.parse(binding);
      let attribute = parsed[1];

      if (seen.indexOf(attribute) === -1) {
        seen.push(attribute);
        AttributeBinding.install(element, component, parsed, operations);
      }

      i--;
    }

    if (seen.indexOf('id') === -1) {
      let id = component.elementId ? component.elementId : (0, _utils.guidFor)(component);
      operations.setAttribute('id', _runtime2.PrimitiveReference.create(id), false, null);
    }

    if (seen.indexOf('style') === -1) {
      IsVisibleBinding.install(element, component, operations);
    }
  }

  const DEFAULT_LAYOUT = (0, _container.privatize)(_templateObject());
  const EMPTY_POSITIONAL_ARGS = [];
  (0, _debug.debugFreeze)(EMPTY_POSITIONAL_ARGS);

  class CurlyComponentManager extends AbstractManager {
    getLayout(state, _resolver) {
      return {
        // TODO fix
        handle: state.handle,
        symbolTable: state.symbolTable
      };
    }

    templateFor(component, resolver) {
      let {
        layout,
        layoutName
      } = component;
      let owner = (0, _owner.getOwner)(component);

      if (layout !== undefined) {
        // This needs to be cached by template.id
        if (isTemplateFactory(layout)) {
          return resolver.createTemplate(layout, (0, _owner.getOwner)(component));
        } else {
          // we were provided an instance already
          return layout;
        }
      }

      if (layoutName) {
        let template = owner.lookup('template:' + layoutName);

        if (template) {
          return template;
        }
      }

      return owner.lookup(DEFAULT_LAYOUT);
    }

    getDynamicLayout({
      component
    }, resolver) {
      const template = this.templateFor(component, resolver);
      const layout = template.asWrappedLayout();
      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

    getTagName(state) {
      const {
        component,
        hasWrappedElement
      } = state;

      if (!hasWrappedElement) {
        return null;
      }

      return component && component.tagName || 'div';
    }

    getCapabilities(state) {
      return state.capabilities;
    }

    prepareArgs(state, args) {
      if (args.named.has('__ARGS__')) {
        let __args__ = args.named.get('__ARGS__').value();

        let prepared = {
          positional: EMPTY_POSITIONAL_ARGS,
          named: Object.assign({}, args.named.capture().map, __args__)
        };

        if (true
        /* DEBUG */
        ) {
            delete prepared.named.__ARGS__;
          }

        return prepared;
      }

      const {
        positionalParams
      } = state.ComponentClass.class; // early exits

      if (positionalParams === undefined || positionalParams === null || args.positional.length === 0) {
        return null;
      }

      let named;

      if (typeof positionalParams === 'string') {
        true && !!args.named.has(positionalParams) && (0, _debug.assert)("You cannot specify positional parameters and the hash argument `" + positionalParams + "`.", !args.named.has(positionalParams));
        named = {
          [positionalParams]: args.positional.capture()
        };
        (0, _polyfills.assign)(named, args.named.capture().map);
      } else if (Array.isArray(positionalParams) && positionalParams.length > 0) {
        const count = Math.min(positionalParams.length, args.positional.length);
        named = {};
        (0, _polyfills.assign)(named, args.named.capture().map);

        for (let i = 0; i < count; i++) {
          const name = positionalParams[i];
          true && !!args.named.has(name) && (0, _debug.assert)("You cannot specify both a positional param (at position " + i + ") and the hash argument `" + name + "`.", !args.named.has(name));
          named[name] = args.positional.at(i);
        }
      } else {
        return null;
      }

      return {
        positional: _util.EMPTY_ARRAY,
        named
      };
    }
    /*
     * This hook is responsible for actually instantiating the component instance.
     * It also is where we perform additional bookkeeping to support legacy
     * features like exposed by view mixins like ChildViewSupport, ActionSupport,
     * etc.
     */


    create(environment, state, args, dynamicScope, callerSelfRef, hasBlock) {
      if (true
      /* DEBUG */
      ) {
          this._pushToDebugStack("component:" + state.name, environment);
        } // Get the nearest concrete component instance from the scope. "Virtual"
      // components will be skipped.


      let parentView = dynamicScope.view; // Get the Ember.Component subclass to instantiate for this component.

      let factory = state.ComponentClass; // Capture the arguments, which tells Glimmer to give us our own, stable
      // copy of the Arguments object that is safe to hold on to between renders.

      let capturedArgs = args.named.capture();
      let props = processComponentArgs(capturedArgs); // Alias `id` argument to `elementId` property on the component instance.

      aliasIdToElementId(args, props); // Set component instance's parentView property to point to nearest concrete
      // component.

      props.parentView = parentView; // Set whether this component was invoked with a block
      // (`{{#my-component}}{{/my-component}}`) or without one
      // (`{{my-component}}`).

      props[HAS_BLOCK] = hasBlock; // Save the current `this` context of the template as the component's
      // `_target`, so bubbled actions are routed to the right place.

      props._target = callerSelfRef.value(); // static layout asserts CurriedDefinition

      if (state.template) {
        props.layout = state.template;
      } // Now that we've built up all of the properties to set on the component instance,
      // actually create it.


      let component = factory.create(props);
      let finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component); // We become the new parentView for downstream components, so save our
      // component off on the dynamic scope.

      dynamicScope.view = component; // Unless we're the root component, we need to add ourselves to our parent
      // component's childViews array.

      if (parentView !== null && parentView !== undefined) {
        (0, _views.addChildView)(parentView, component);
      }

      component.trigger('didReceiveAttrs');
      let hasWrappedElement = component.tagName !== ''; // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components

      if (!hasWrappedElement) {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      } // Track additional lifecycle metadata about this component in a state bucket.
      // Essentially we're saving off all the state we'll need in the future.


      let bucket = new ComponentStateBucket(environment, component, capturedArgs, finalizer, hasWrappedElement);

      if (args.named.has('class')) {
        bucket.classRef = args.named.get('class');
      }

      if (true
      /* DEBUG */
      ) {
          processComponentInitializationAssertions(component, props);
        }

      if (environment.isInteractive && hasWrappedElement) {
        component.trigger('willRender');
      }

      return bucket;
    }

    getSelf({
      component
    }) {
      return component[ROOT_REF];
    }

    didCreateElement({
      component,
      classRef,
      environment
    }, element, operations) {
      (0, _views.setViewElement)(component, element);
      (0, _views.setElementView)(element, component);
      let {
        attributeBindings,
        classNames,
        classNameBindings
      } = component;

      if (attributeBindings && attributeBindings.length) {
        applyAttributeBindings(element, attributeBindings, component, operations);
      } else {
        let id = component.elementId ? component.elementId : (0, _utils.guidFor)(component);
        operations.setAttribute('id', _runtime2.PrimitiveReference.create(id), false, null);
        IsVisibleBinding.install(element, component, operations);
      }

      if (classRef) {
        const ref = new SimpleClassNameBindingReference(classRef, classRef['propertyKey']);
        operations.setAttribute('class', ref, false, null);
      }

      if (classNames && classNames.length) {
        classNames.forEach(name => {
          operations.setAttribute('class', _runtime2.PrimitiveReference.create(name), false, null);
        });
      }

      if (classNameBindings && classNameBindings.length) {
        classNameBindings.forEach(binding => {
          ClassNameBinding.install(element, component, binding, operations);
        });
      }

      operations.setAttribute('class', _runtime2.PrimitiveReference.create('ember-view'), false, null);

      if ('ariaRole' in component) {
        operations.setAttribute('role', referenceForKey(component, 'ariaRole'), false, null);
      }

      component._transitionTo('hasElement');

      if (environment.isInteractive) {
        component.trigger('willInsertElement');
      }
    }

    didRenderLayout(bucket, bounds) {
      bucket.component[BOUNDS] = bounds;
      bucket.finalize();

      if (true
      /* DEBUG */
      ) {
          this.debugStack.pop();
        }
    }

    getTag({
      args,
      component
    }) {
      return args ? (0, _reference.combine)([args.tag, component[DIRTY_TAG]]) : component[DIRTY_TAG];
    }

    didCreate({
      component,
      environment
    }) {
      if (environment.isInteractive) {
        component._transitionTo('inDOM');

        component.trigger('didInsertElement');
        component.trigger('didRender');
      }
    }

    update(bucket) {
      let {
        component,
        args,
        argsRevision,
        environment
      } = bucket;

      if (true
      /* DEBUG */
      ) {
          this._pushToDebugStack(component._debugContainerKey, environment);
        }

      bucket.finalizer = (0, _instrumentation._instrumentStart)('render.component', rerenderInstrumentDetails, component);

      if (args && !args.tag.validate(argsRevision)) {
        let props = processComponentArgs(args);
        bucket.argsRevision = args.tag.value();
        component[IS_DISPATCHING_ATTRS] = true;
        component.setProperties(props);
        component[IS_DISPATCHING_ATTRS] = false;
        component.trigger('didUpdateAttrs');
        component.trigger('didReceiveAttrs');
      }

      if (environment.isInteractive) {
        component.trigger('willUpdate');
        component.trigger('willRender');
      }
    }

    didUpdateLayout(bucket) {
      bucket.finalize();

      if (true
      /* DEBUG */
      ) {
          this.debugStack.pop();
        }
    }

    didUpdate({
      component,
      environment
    }) {
      if (environment.isInteractive) {
        component.trigger('didUpdate');
        component.trigger('didRender');
      }
    }

    getDestructor(stateBucket) {
      return stateBucket;
    }

  }

  function processComponentInitializationAssertions(component, props) {
    true && !(() => {
      let {
        classNameBindings
      } = component;

      for (let i = 0; i < classNameBindings.length; i++) {
        let binding = classNameBindings[i];

        if (typeof binding !== 'string' || binding.length === 0) {
          return false;
        }
      }

      return true;
    })() && (0, _debug.assert)("classNameBindings must be non-empty strings: " + component, (() => {
      let {
        classNameBindings
      } = component;

      for (let i = 0; i < classNameBindings.length; i++) {
        let binding = classNameBindings[i];

        if (typeof binding !== 'string' || binding.length === 0) {
          return false;
        }
      }

      return true;
    })());
    true && !(() => {
      let {
        classNameBindings
      } = component;

      for (let i = 0; i < classNameBindings.length; i++) {
        let binding = classNameBindings[i];

        if (binding.split(' ').length > 1) {
          return false;
        }
      }

      return true;
    })() && (0, _debug.assert)("classNameBindings must not have spaces in them: " + component, (() => {
      let {
        classNameBindings
      } = component;

      for (let i = 0; i < classNameBindings.length; i++) {
        let binding = classNameBindings[i];

        if (binding.split(' ').length > 1) {
          return false;
        }
      }

      return true;
    })());
    true && !(component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0) && (0, _debug.assert)("You cannot use `classNameBindings` on a tag-less component: " + component, component.tagName !== '' || !component.classNameBindings || component.classNameBindings.length === 0);
    true && !(component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== '') && (0, _debug.assert)("You cannot use `elementId` on a tag-less component: " + component, component.tagName !== '' || props.id === component.elementId || !component.elementId && component.elementId !== '');
    true && !(component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0) && (0, _debug.assert)("You cannot use `attributeBindings` on a tag-less component: " + component, component.tagName !== '' || !component.attributeBindings || component.attributeBindings.length === 0);
  }

  function initialRenderInstrumentDetails(component) {
    return component.instrumentDetails({
      initialRender: true
    });
  }

  function rerenderInstrumentDetails(component) {
    return component.instrumentDetails({
      initialRender: false
    });
  }

  const CURLY_CAPABILITIES = {
    dynamicLayout: true,
    dynamicTag: true,
    prepareArgs: true,
    createArgs: true,
    attributeHook: true,
    elementHook: true,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true
  };
  const CURLY_COMPONENT_MANAGER = new CurlyComponentManager();

  class CurlyComponentDefinition {
    // tslint:disable-next-line:no-shadowed-variable
    constructor(name, ComponentClass, handle, template, args) {
      this.name = name;
      this.ComponentClass = ComponentClass;
      this.handle = handle;
      this.manager = CURLY_COMPONENT_MANAGER;
      const layout = template && template.asLayout();
      const symbolTable = layout ? layout.symbolTable : undefined;
      this.symbolTable = symbolTable;
      this.template = template;
      this.args = args;
      this.state = {
        name,
        ComponentClass,
        handle,
        template,
        capabilities: CURLY_CAPABILITIES,
        symbolTable
      };
    }

  }

  class RootComponentManager extends CurlyComponentManager {
    constructor(component) {
      super();
      this.component = component;
    }

    getLayout(_state, resolver) {
      const template = this.templateFor(this.component, resolver);
      const layout = template.asWrappedLayout();
      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

    create(environment, _state, _args, dynamicScope) {
      let component = this.component;

      if (true
      /* DEBUG */
      ) {
          this._pushToDebugStack(component._debugContainerKey, environment);
        }

      let finalizer = (0, _instrumentation._instrumentStart)('render.component', initialRenderInstrumentDetails, component);
      dynamicScope.view = component;
      let hasWrappedElement = component.tagName !== ''; // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components

      if (!hasWrappedElement) {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      if (true
      /* DEBUG */
      ) {
          processComponentInitializationAssertions(component, {});
        }

      return new ComponentStateBucket(environment, component, null, finalizer, hasWrappedElement);
    }

  } // ROOT is the top-level template it has nothing but one yield.
  // it is supposed to have a dummy element


  const ROOT_CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: true,
    prepareArgs: false,
    createArgs: false,
    attributeHook: true,
    elementHook: true,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true
  };

  class RootComponentDefinition {
    constructor(component) {
      this.component = component;
      let manager = new RootComponentManager(component);
      this.manager = manager;

      let factory = _container.FACTORY_FOR.get(component);

      this.state = {
        name: factory.fullName.slice(10),
        capabilities: ROOT_CAPABILITIES,
        ComponentClass: factory,
        handle: null
      };
    }

    getTag({
      component
    }) {
      return component[DIRTY_TAG];
    }

  }

  class DynamicScope {
    constructor(view, outletState) {
      this.view = view;
      this.outletState = outletState;
    }

    child() {
      return new DynamicScope(this.view, this.outletState);
    }

    get(key) {
      // tslint:disable-next-line:max-line-length
      true && !(key === 'outletState') && (0, _debug.assert)("Using `-get-dynamic-scope` is only supported for `outletState` (you used `" + key + "`).", key === 'outletState');
      return this.outletState;
    }

    set(key, value) {
      // tslint:disable-next-line:max-line-length
      true && !(key === 'outletState') && (0, _debug.assert)("Using `-with-dynamic-scope` is only supported for `outletState` (you used `" + key + "`).", key === 'outletState');
      this.outletState = value;
      return value;
    }

  }

  class RootState {
    constructor(root, env, template, self, parentElement, dynamicScope, builder) {
      true && !(template !== undefined) && (0, _debug.assert)("You cannot render `" + self.value() + "` without a template.", template !== undefined);
      this.id = (0, _views.getViewId)(root);
      this.env = env;
      this.root = root;
      this.result = undefined;
      this.shouldReflush = false;
      this.destroyed = false;
      let options = this.options = {
        alwaysRevalidate: false
      };

      this.render = () => {
        let layout = template.asLayout();
        let handle = layout.compile();
        let iterator = (0, _runtime2.renderMain)(layout['compiler'].program, env, self, dynamicScope, builder(env, {
          element: parentElement,
          nextSibling: null
        }), handle);
        let iteratorResult;

        do {
          iteratorResult = iterator.next();
        } while (!iteratorResult.done);

        let result = this.result = iteratorResult.value; // override .render function after initial render

        this.render = () => result.rerender(options);
      };
    }

    isFor(possibleRoot) {
      return this.root === possibleRoot;
    }

    destroy() {
      let {
        result,
        env
      } = this;
      this.destroyed = true;
      this.env = undefined;
      this.root = null;
      this.result = undefined;
      this.render = undefined;

      if (result) {
        /*
         Handles these scenarios:
                * When roots are removed during standard rendering process, a transaction exists already
           `.begin()` / `.commit()` are not needed.
         * When roots are being destroyed manually (`component.append(); component.destroy() case), no
           transaction exists already.
         * When roots are being destroyed during `Renderer#destroy`, no transaction exists
                */
        let needsTransaction = !env.inTransaction;

        if (needsTransaction) {
          env.begin();
        }

        try {
          result.destroy();
        } finally {
          if (needsTransaction) {
            env.commit();
          }
        }
      }
    }

  }

  const renderers = [];

  function _resetRenderers() {
    renderers.length = 0;
  }

  function register(renderer) {
    true && !(renderers.indexOf(renderer) === -1) && (0, _debug.assert)('Cannot register the same renderer twice', renderers.indexOf(renderer) === -1);
    renderers.push(renderer);
  }

  function deregister(renderer) {
    let index = renderers.indexOf(renderer);
    true && !(index !== -1) && (0, _debug.assert)('Cannot deregister unknown unregistered renderer', index !== -1);
    renderers.splice(index, 1);
  }

  function loopBegin() {
    for (let i = 0; i < renderers.length; i++) {
      renderers[i]._scheduleRevalidate();
    }
  }

  function K() {
    /* noop */
  }

  let renderSettledDeferred = null;
  /*
    Returns a promise which will resolve when rendering has settled. Settled in
    this context is defined as when all of the tags in use are "current" (e.g.
    `renderers.every(r => r._isValid())`). When this is checked at the _end_ of
    the run loop, this essentially guarantees that all rendering is completed.
  
    @method renderSettled
    @returns {Promise<void>} a promise which fulfills when rendering has settled
  */

  function renderSettled() {
    if (renderSettledDeferred === null) {
      renderSettledDeferred = _rsvp.default.defer(); // if there is no current runloop, the promise created above will not have
      // a chance to resolve (because its resolved in backburner's "end" event)

      if (!(0, _runloop.getCurrentRunLoop)()) {
        // ensure a runloop has been kicked off
        _runloop.backburner.schedule('actions', null, K);
      }
    }

    return renderSettledDeferred.promise;
  }

  function resolveRenderPromise() {
    if (renderSettledDeferred !== null) {
      let resolve = renderSettledDeferred.resolve;
      renderSettledDeferred = null;

      _runloop.backburner.join(null, resolve);
    }
  }

  let loops = 0;

  function loopEnd() {
    for (let i = 0; i < renderers.length; i++) {
      if (!renderers[i]._isValid()) {
        if (loops > _environment2.ENV._RERENDER_LOOP_LIMIT) {
          loops = 0; // TODO: do something better

          renderers[i].destroy();
          throw new Error('infinite rendering invalidation detected');
        }

        loops++;
        return _runloop.backburner.join(null, K);
      }
    }

    loops = 0;
    resolveRenderPromise();
  }

  _runloop.backburner.on('begin', loopBegin);

  _runloop.backburner.on('end', loopEnd);

  class Renderer {
    constructor(env, rootTemplate, viewRegistry, destinedForDOM = false, builder = _runtime2.clientBuilder) {
      this._env = env;
      this._rootTemplate = rootTemplate;
      this._viewRegistry = viewRegistry;
      this._destinedForDOM = destinedForDOM;
      this._destroyed = false;
      this._roots = [];
      this._lastRevision = -1;
      this._isRenderingRoots = false;
      this._removedRoots = [];
      this._builder = builder;
    } // renderer HOOKS


    appendOutletView(view, target) {
      let definition = createRootOutlet(view);

      this._appendDefinition(view, (0, _runtime2.curry)(definition), target);
    }

    appendTo(view, target) {
      let definition = new RootComponentDefinition(view);

      this._appendDefinition(view, (0, _runtime2.curry)(definition), target);
    }

    _appendDefinition(root, definition, target) {
      let self = new UnboundReference(definition);
      let dynamicScope = new DynamicScope(null, _runtime2.UNDEFINED_REFERENCE);
      let rootState = new RootState(root, this._env, this._rootTemplate, self, target, dynamicScope, this._builder);

      this._renderRoot(rootState);
    }

    rerender() {
      this._scheduleRevalidate();
    }

    register(view) {
      let id = (0, _views.getViewId)(view);
      true && !!this._viewRegistry[id] && (0, _debug.assert)('Attempted to register a view with an id already in use: ' + id, !this._viewRegistry[id]);
      this._viewRegistry[id] = view;
    }

    unregister(view) {
      delete this._viewRegistry[(0, _views.getViewId)(view)];
    }

    remove(view) {
      view._transitionTo('destroying');

      this.cleanupRootFor(view);

      if (this._destinedForDOM) {
        view.trigger('didDestroyElement');
      }
    }

    cleanupRootFor(view) {
      // no need to cleanup roots if we have already been destroyed
      if (this._destroyed) {
        return;
      }

      let roots = this._roots; // traverse in reverse so we can remove items
      // without mucking up the index

      let i = this._roots.length;

      while (i--) {
        let root = roots[i];

        if (root.isFor(view)) {
          root.destroy();
          roots.splice(i, 1);
        }
      }
    }

    destroy() {
      if (this._destroyed) {
        return;
      }

      this._destroyed = true;

      this._clearAllRoots();
    }

    getBounds(view) {
      let bounds = view[BOUNDS];
      let parentElement = bounds.parentElement();
      let firstNode = bounds.firstNode();
      let lastNode = bounds.lastNode();
      return {
        parentElement,
        firstNode,
        lastNode
      };
    }

    createElement(tagName) {
      return this._env.getAppendOperations().createElement(tagName);
    }

    _renderRoot(root) {
      let {
        _roots: roots
      } = this;
      roots.push(root);

      if (roots.length === 1) {
        register(this);
      }

      this._renderRootsTransaction();
    }

    _renderRoots() {
      let {
        _roots: roots,
        _env: env,
        _removedRoots: removedRoots
      } = this;
      let globalShouldReflush = false;
      let initialRootsLength;

      do {
        env.begin();

        try {
          // ensure that for the first iteration of the loop
          // each root is processed
          initialRootsLength = roots.length;
          globalShouldReflush = false;

          for (let i = 0; i < roots.length; i++) {
            let root = roots[i];

            if (root.destroyed) {
              // add to the list of roots to be removed
              // they will be removed from `this._roots` later
              removedRoots.push(root); // skip over roots that have been marked as destroyed

              continue;
            }

            let {
              shouldReflush
            } = root; // when processing non-initial reflush loops,
            // do not process more roots than needed

            if (i >= initialRootsLength && !shouldReflush) {
              continue;
            }

            root.options.alwaysRevalidate = shouldReflush; // track shouldReflush based on this roots render result

            shouldReflush = root.shouldReflush = (0, _metal.runInTransaction)(root, 'render'); // globalShouldReflush should be `true` if *any* of
            // the roots need to reflush

            globalShouldReflush = globalShouldReflush || shouldReflush;
          }

          this._lastRevision = _reference.CURRENT_TAG.value();
        } finally {
          env.commit();
        }
      } while (globalShouldReflush || roots.length > initialRootsLength); // remove any roots that were destroyed during this transaction


      while (removedRoots.length) {
        let root = removedRoots.pop();
        let rootIndex = roots.indexOf(root);
        roots.splice(rootIndex, 1);
      }

      if (this._roots.length === 0) {
        deregister(this);
      }
    }

    _renderRootsTransaction() {
      if (this._isRenderingRoots) {
        // currently rendering roots, a new root was added and will
        // be processed by the existing _renderRoots invocation
        return;
      } // used to prevent calling _renderRoots again (see above)
      // while we are actively rendering roots


      this._isRenderingRoots = true;
      let completedWithoutError = false;

      try {
        this._renderRoots();

        completedWithoutError = true;
      } finally {
        if (!completedWithoutError) {
          this._lastRevision = _reference.CURRENT_TAG.value();

          if (this._env.inTransaction === true) {
            this._env.commit();
          }
        }

        this._isRenderingRoots = false;
      }
    }

    _clearAllRoots() {
      let roots = this._roots;

      for (let i = 0; i < roots.length; i++) {
        let root = roots[i];
        root.destroy();
      }

      this._removedRoots.length = 0;
      this._roots = []; // if roots were present before destroying
      // deregister this renderer instance

      if (roots.length) {
        deregister(this);
      }
    }

    _scheduleRevalidate() {
      _runloop.backburner.scheduleOnce('render', this, this._revalidate);
    }

    _isValid() {
      return this._destroyed || this._roots.length === 0 || _reference.CURRENT_TAG.validate(this._lastRevision);
    }

    _revalidate() {
      if (this._isValid()) {
        return;
      }

      this._renderRootsTransaction();
    }

  }

  _exports.Renderer = Renderer;

  class InertRenderer extends Renderer {
    static create({
      env,
      rootTemplate,
      _viewRegistry,
      builder
    }) {
      return new this(env, rootTemplate, _viewRegistry, false, builder);
    }

    getElement(_view) {
      throw new Error('Accessing `this.element` is not allowed in non-interactive environments (such as FastBoot).');
    }

  }

  _exports.InertRenderer = InertRenderer;

  class InteractiveRenderer extends Renderer {
    static create({
      env,
      rootTemplate,
      _viewRegistry,
      builder
    }) {
      return new this(env, rootTemplate, _viewRegistry, true, builder);
    }

    getElement(view) {
      return (0, _views.getViewElement)(view);
    }

  }

  _exports.InteractiveRenderer = InteractiveRenderer;
  let TEMPLATES = {};

  function setTemplates(templates) {
    TEMPLATES = templates;
  }

  function getTemplates() {
    return TEMPLATES;
  }

  function getTemplate(name) {
    if (TEMPLATES.hasOwnProperty(name)) {
      return TEMPLATES[name];
    }
  }

  function hasTemplate(name) {
    return TEMPLATES.hasOwnProperty(name);
  }

  function setTemplate(name, template) {
    return TEMPLATES[name] = template;
  }

  class InternalComponentDefinition {
    constructor(manager, ComponentClass, layout) {
      this.manager = manager;
      this.state = {
        ComponentClass,
        layout
      };
    }

  }

  class InternalManager extends AbstractManager {
    constructor(owner) {
      super();
      this.owner = owner;
    }

    getLayout({
      layout: _layout
    }) {
      let layout = _layout.asLayout();

      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

  }

  const CAPABILITIES$1 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: true,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    createCaller: true,
    dynamicScope: false,
    updateHook: true,
    createInstance: true
  };
  const EMPTY_POSITIONAL_ARGS$1 = [];
  (0, _debug.debugFreeze)(EMPTY_POSITIONAL_ARGS$1);

  class InputComponentManager extends InternalManager {
    getCapabilities() {
      return CAPABILITIES$1;
    }

    prepareArgs(_state, args) {
      true && !(args.positional.length === 0) && (0, _debug.assert)('The `<Input />` component does not take any positional arguments', args.positional.length === 0);
      let __ARGS__ = args.named.capture().map;
      return {
        positional: EMPTY_POSITIONAL_ARGS$1,
        named: {
          __ARGS__: new RootReference(__ARGS__),
          type: args.named.get('type')
        }
      };
    }

    create(_env, {
      ComponentClass
    }, args, _dynamicScope, caller) {
      true && !(0, _reference.isConst)(caller) && (0, _debug.assert)('caller must be const', (0, _reference.isConst)(caller));
      let type = args.named.get('type');
      let instance = ComponentClass.create({
        caller: caller.value(),
        type: type.value()
      });
      return {
        type,
        instance
      };
    }

    getSelf({
      instance
    }) {
      return new RootReference(instance);
    }

    getTag() {
      return _reference.CONSTANT_TAG;
    }

    update({
      type,
      instance
    }) {
      (0, _metal.set)(instance, 'type', type.value());
    }

    getDestructor({
      instance
    }) {
      return instance;
    }

  }

  const InputComponentManagerFactory = owner => {
    return new InputComponentManager(owner);
  };

  const MANAGERS = new WeakMap();
  const getPrototypeOf = Object.getPrototypeOf;

  function setManager(wrapper, obj) {
    MANAGERS.set(obj, wrapper);
    return obj;
  }

  function getManager(obj) {
    let pointer = obj;

    while (pointer !== undefined && pointer !== null) {
      if (MANAGERS.has(pointer)) {
        return MANAGERS.get(pointer);
      }

      pointer = getPrototypeOf(pointer);
    }

    return null;
  }
  /**
  @module @ember/component
  */


  let Input;

  if (true
  /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
  ) {
      /**
        See [Ember.Templates.components.Input](/api/ember/release/classes/Ember.Templates.components/methods/Input?anchor=Input).
           @method input
        @for Ember.Templates.helpers
        @param {Hash} options
        @public
       */

      /**
        The `Input` component lets you create an HTML `<input>` element.
           ```handlebars
        <Input @value="987" />
        ```
           creates an `<input>` element with `type="text"` and value set to 987.
           ### Text field
           If no `type` argument is specified, a default of type 'text' is used.
           ```handlebars
        Search:
        <Input @value={{this.searchWord}}>
        ```
           In this example, the initial value in the `<input>` will be set to the value of
        `this.searchWord`. If the user changes the text, the value of `this.searchWord` will also be
        updated.
           ### Actions
           The `Input` component takes a number of arguments with callbacks that are invoked in response to
        user events.
           * `enter`
        * `insert-newline`
        * `escape-press`
        * `focus-in`
        * `focus-out`
        * `key-press`
        * `key-up`
           These callbacks are passed to `Input` like this:
           ```handlebars
        <Input @value={{this.searchWord}} @enter={{this.query}} />
        ```
           ### `<input>` HTML Attributes to Avoid
           In most cases, if you want to pass an attribute to the underlying HTML `<input>` element, you
        can pass the attribute directly, just like any other Ember component.
           ```handlebars
        <Input @type="text" size="10" />
        ```
           In this example, the `size` attribute will be applied to the underlying `<input>` element in the
        outputted HTML.
           However, there are a few attributes where you **must** use the `@` version.
           * `@type`: This argument is used to control which Ember component is used under the hood
        * `@value`: The `@value` argument installs a two-way binding onto the element. If you wanted a
          one-way binding, use `<input>` with the `value` property and the `input` event instead.
        * `@checked` (for checkboxes): like `@value`, the `@checked` argument installs a two-way binding
          onto the element. If you wanted a one-way binding, use `<input type="checkbox">` with
          `checked` and the `input` event instead.
           ### Extending `TextField`
           Internally, `<Input @type="text" />` creates an instance of `TextField`, passing arguments from
        the helper to `TextField`'s `create` method. Subclassing `TextField` is supported but not
        recommended.
           See [TextField](/api/ember/release/classes/TextField)
           ### Checkbox
           To create an `<input type="checkbox">`:
           ```handlebars
        Emberize Everything:
        <Input @type="checkbox" @checked={{this.isEmberized}} name="isEmberized" />
        ```
           This will bind the checked state of this checkbox to the value of `isEmberized` -- if either one
        changes, it will be reflected in the other.
           ### Extending `Checkbox`
           Internally, `<Input @type="checkbox" />` creates an instance of `Checkbox`. Subclassing
        `TextField` is supported but not recommended.
           See [Checkbox](/api/ember/release/classes/Checkbox)
           @method Input
        @for Ember.Templates.components
        @see {TextField}
        @see {Checkbox}
        @param {Hash} options
        @public
      */
      Input = _runtime.Object.extend({
        isCheckbox: (0, _metal.computed)('type', function () {
          return this.type === 'checkbox';
        })
      });
      setManager({
        factory: InputComponentManagerFactory,
        internal: true,
        type: 'component'
      }, Input);

      Input.toString = () => '@ember/component/input';
    }

  var Input$1 = Input; ///<reference path="./simple-dom.d.ts" />

  /**
  @module ember
  */

  /**
    Calls [loc](/api/classes/Ember.String.html#method_loc) with the
    provided string. This is a convenient way to localize text within a template.
    For example:
  
    ```javascript
    Ember.STRINGS = {
      '_welcome_': 'Bonjour'
    };
    ```
  
    ```handlebars
    <div class='message'>
      {{loc '_welcome_'}}
    </div>
    ```
  
    ```html
    <div class='message'>
      Bonjour
    </div>
    ```
  
    See [String.loc](/api/ember/release/classes/String/methods/loc?anchor=loc) for how to
    set up localized string references.
  
    @method loc
    @for Ember.Templates.helpers
    @param {String} str The string to format.
    @see {String#loc}
    @public
  */

  var loc$1 = helper(function (params) {
    return _string.loc.apply(null, params
    /* let the other side handle errors */
    );
  });

  class CompileTimeLookup {
    constructor(resolver) {
      this.resolver = resolver;
    }

    getCapabilities(handle) {
      let definition = this.resolver.resolve(handle);
      let {
        manager,
        state
      } = definition;
      return manager.getCapabilities(state);
    }

    getLayout(handle) {
      const {
        manager,
        state
      } = this.resolver.resolve(handle);
      const capabilities = manager.getCapabilities(state);

      if (capabilities.dynamicLayout) {
        return null;
      }

      const invocation = manager.getLayout(state, this.resolver);
      return {
        // TODO: this seems weird, it already is compiled
        compile() {
          return invocation.handle;
        },

        symbolTable: invocation.symbolTable
      };
    }

    lookupHelper(name, referrer) {
      return this.resolver.lookupHelper(name, referrer);
    }

    lookupModifier(name, referrer) {
      return this.resolver.lookupModifier(name, referrer);
    }

    lookupComponentDefinition(name, referrer) {
      return this.resolver.lookupComponentHandle(name, referrer);
    }

    lookupPartial(name, referrer) {
      return this.resolver.lookupPartial(name, referrer);
    }

  }

  const CAPABILITIES$2 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    createCaller: false,
    dynamicScope: true,
    updateHook: true,
    createInstance: true
  };

  function capabilities(managerAPI, options = {}) {
    true && !(managerAPI === '3.4') && (0, _debug.assert)('Invalid component manager compatibility specified', managerAPI === '3.4');
    return {
      asyncLifeCycleCallbacks: Boolean(options.asyncLifecycleCallbacks),
      destructor: Boolean(options.destructor)
    };
  }

  function hasAsyncLifeCycleCallbacks(delegate) {
    return delegate.capabilities.asyncLifeCycleCallbacks;
  }

  function hasDestructors(delegate) {
    return delegate.capabilities.destructor;
  }
  /**
    The CustomComponentManager allows addons to provide custom component
    implementations that integrate seamlessly into Ember. This is accomplished
    through a delegate, registered with the custom component manager, which
    implements a set of hooks that determine component behavior.
  
    To create a custom component manager, instantiate a new CustomComponentManager
    class and pass the delegate as the first argument:
  
    ```js
    let manager = new CustomComponentManager({
      // ...delegate implementation...
    });
    ```
  
    ## Delegate Hooks
  
    Throughout the lifecycle of a component, the component manager will invoke
    delegate hooks that are responsible for surfacing those lifecycle changes to
    the end developer.
  
    * `create()` - invoked when a new instance of a component should be created
    * `update()` - invoked when the arguments passed to a component change
    * `getContext()` - returns the object that should be
  */


  class CustomComponentManager extends AbstractManager {
    create(_env, definition, args) {
      const {
        delegate
      } = definition;
      const capturedArgs = args.capture();
      const component = delegate.createComponent(definition.ComponentClass.class, capturedArgs.value());
      return new CustomComponentState(delegate, component, capturedArgs);
    }

    update({
      delegate,
      component,
      args
    }) {
      delegate.updateComponent(component, args.value());
    }

    didCreate({
      delegate,
      component
    }) {
      if (hasAsyncLifeCycleCallbacks(delegate)) {
        delegate.didCreateComponent(component);
      }
    }

    didUpdate({
      delegate,
      component
    }) {
      if (hasAsyncLifeCycleCallbacks(delegate)) {
        delegate.didUpdateComponent(component);
      }
    }

    getContext({
      delegate,
      component
    }) {
      delegate.getContext(component);
    }

    getSelf({
      delegate,
      component
    }) {
      return RootReference.create(delegate.getContext(component));
    }

    getDestructor(state) {
      if (hasDestructors(state.delegate)) {
        return state;
      } else {
        return null;
      }
    }

    getCapabilities() {
      return CAPABILITIES$2;
    }

    getTag({
      args
    }) {
      return args.tag;
    }

    didRenderLayout() {}

    getLayout(state) {
      return {
        handle: state.template.asLayout().compile(),
        symbolTable: state.symbolTable
      };
    }

  }

  const CUSTOM_COMPONENT_MANAGER = new CustomComponentManager();
  /**
   * Stores internal state about a component instance after it's been created.
   */

  class CustomComponentState {
    constructor(delegate, component, args) {
      this.delegate = delegate;
      this.component = component;
      this.args = args;
    }

    destroy() {
      const {
        delegate,
        component
      } = this;

      if (hasDestructors(delegate)) {
        delegate.destroyComponent(component);
      }
    }

  }

  class CustomManagerDefinition {
    constructor(name, ComponentClass, delegate, template) {
      this.name = name;
      this.ComponentClass = ComponentClass;
      this.delegate = delegate;
      this.template = template;
      this.manager = CUSTOM_COMPONENT_MANAGER;
      const layout = template.asLayout();
      const symbolTable = layout.symbolTable;
      this.symbolTable = symbolTable;
      this.state = {
        name,
        ComponentClass,
        template,
        symbolTable,
        delegate
      };
    }

  }

  const CAPABILITIES$3 = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    createCaller: false,
    dynamicScope: false,
    updateHook: false,
    createInstance: true
  };

  class TemplateOnlyComponentManager extends AbstractManager {
    getLayout(template) {
      const layout = template.asLayout();
      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

    getCapabilities() {
      return CAPABILITIES$3;
    }

    create() {
      return null;
    }

    getSelf() {
      return _runtime2.NULL_REFERENCE;
    }

    getTag() {
      return _reference.CONSTANT_TAG;
    }

    getDestructor() {
      return null;
    }

  }

  const MANAGER = new TemplateOnlyComponentManager();

  class TemplateOnlyComponentDefinition {
    constructor(state) {
      this.state = state;
      this.manager = MANAGER;
    }

  }

  let helper$1;

  if (true
  /* DEBUG */
  ) {
      class ComponentAssertionReference {
        constructor(component, message) {
          this.component = component;
          this.message = message;
          this.tag = component.tag;
        }

        value() {
          let value = this.component.value();
          true && !(typeof value !== 'string') && (0, _debug.assert)(this.message, typeof value !== 'string');
          return value;
        }

        get(property) {
          return this.component.get(property);
        }

      }

      helper$1 = (_vm, args) => new ComponentAssertionReference(args.positional.at(0), args.positional.at(1).value());
    } else {
    helper$1 = (_vm, args) => args.positional.at(0);
  }

  var componentAssertionHelper = helper$1;

  function classHelper({
    positional
  }) {
    let path = positional.at(0);
    let args = positional.length;
    let value = path.value();

    if (value === true) {
      if (args > 1) {
        return (0, _string.dasherize)(positional.at(1).value());
      }

      return null;
    }

    if (value === false) {
      if (args > 2) {
        return (0, _string.dasherize)(positional.at(2).value());
      }

      return null;
    }

    return value;
  }

  function classHelper$1(_vm, args) {
    return new InternalHelperReference(classHelper, args.capture());
  }

  function inputTypeHelper({
    positional
  }) {
    let type = positional.at(0).value();

    if (type === 'checkbox') {
      return '-checkbox';
    }

    return '-text-field';
  }

  function inputTypeHelper$1(_vm, args) {
    return new InternalHelperReference(inputTypeHelper, args.capture());
  }

  function normalizeClass({
    positional
  }) {
    let classNameParts = positional.at(0).value().split('.');
    let className = classNameParts[classNameParts.length - 1];
    let value = positional.at(1).value();

    if (value === true) {
      return (0, _string.dasherize)(className);
    } else if (!value && value !== 0) {
      return '';
    } else {
      return String(value);
    }
  }

  function normalizeClassHelper(_vm, args) {
    return new InternalHelperReference(normalizeClass, args.capture());
  }
  /**
  @module ember
  */

  /**
    The `{{action}}` helper provides a way to pass triggers for behavior (usually
    just a function) between components, and into components from controllers.
  
    ### Passing functions with the action helper
  
    There are three contexts an action helper can be used in. The first two
    contexts to discuss are attribute context, and Handlebars value context.
  
    ```handlebars
    {{! An example of attribute context }}
    <div onclick={{action "save"}}></div>
    {{! Examples of Handlebars value context }}
    {{input on-input=(action "save")}}
    {{yield (action "refreshData") andAnotherParam}}
    ```
  
    In these contexts,
    the helper is called a "closure action" helper. Its behavior is simple:
    If passed a function name, read that function off the `actions` property
    of the current context. Once that function is read, or immediately if a function was
    passed, create a closure over that function and any arguments.
    The resulting value of an action helper used this way is simply a function.
  
    For example, in the attribute context:
  
    ```handlebars
    {{! An example of attribute context }}
    <div onclick={{action "save"}}></div>
    ```
  
    The resulting template render logic would be:
  
    ```js
    var div = document.createElement('div');
    var actionFunction = (function(context){
      return function() {
        return context.actions.save.apply(context, arguments);
      };
    })(context);
    div.onclick = actionFunction;
    ```
  
    Thus when the div is clicked, the action on that context is called.
    Because the `actionFunction` is just a function, closure actions can be
    passed between components and still execute in the correct context.
  
    Here is an example action handler on a component:
  
    ```app/components/my-component.js
    import Component from '@ember/component';
  
    export default Component.extend({
      actions: {
        save() {
          this.get('model').save();
        }
      }
    });
    ```
  
    Actions are always looked up on the `actions` property of the current context.
    This avoids collisions in the naming of common actions, such as `destroy`.
    Two options can be passed to the `action` helper when it is used in this way.
  
    * `target=someProperty` will look to `someProperty` instead of the current
      context for the `actions` hash. This can be useful when targeting a
      service for actions.
    * `value="target.value"` will read the path `target.value` off the first
      argument to the action when it is called and rewrite the first argument
      to be that value. This is useful when attaching actions to event listeners.
  
    ### Invoking an action
  
    Closure actions curry both their scope and any arguments. When invoked, any
    additional arguments are added to the already curried list.
    Actions should be invoked using the [sendAction](/api/ember/release/classes/Component/methods/sendAction?anchor=sendAction)
    method. The first argument to `sendAction` is the action to be called, and
    additional arguments are passed to the action function. This has interesting
    properties combined with currying of arguments. For example:
  
    ```app/templates/components/my-component.hbs
    {{input on-input=(action (action 'setName' model) value="target.value")}}
    ```
  
    ```app/components/my-component.js
    import Component from '@ember/component';
  
    export default Component.extend({
      actions: {
        setName(model, name) {
          model.set('name', name);
        }
      }
    });
    ```
  
    The first argument (`model`) was curried over, and the run-time argument (`event`)
    becomes a second argument. Action calls can be nested this way because each simply
    returns a function. Any function can be passed to the `{{action}}` helper, including
    other actions.
  
    Actions invoked with `sendAction` have the same currying behavior as demonstrated
    with `on-input` above. For example:
  
    ```app/components/my-input.js
    import Component from '@ember/component';
  
    export default Component.extend({
      actions: {
        setName(model, name) {
          model.set('name', name);
        }
      }
    });
    ```
  
    ```handlebars
    <MyInput @submit={{action 'setName' this.model}} />
    ```
    or
    ```handlebars
    {{my-input submit=(action 'setName' model)}}
    ```
  
    ```app/components/my-component.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click() {
        // Note that model is not passed, it was curried in the template
        this.sendAction('submit', 'bob');
      }
    });
    ```
  
    ### Attaching actions to DOM elements
  
    The third context of the `{{action}}` helper can be called "element space".
    For example:
  
    ```handlebars
    {{! An example of element space }}
    <div {{action "save"}}></div>
    ```
  
    Used this way, the `{{action}}` helper provides a useful shortcut for
    registering an HTML element in a template for a single DOM event and
    forwarding that interaction to the template's context (controller or component).
    If the context of a template is a controller, actions used this way will
    bubble to routes when the controller does not implement the specified action.
    Once an action hits a route, it will bubble through the route hierarchy.
  
    ### Event Propagation
  
    `{{action}}` helpers called in element space can control event bubbling. Note
    that the closure style actions cannot.
  
    Events triggered through the action helper will automatically have
    `.preventDefault()` called on them. You do not need to do so in your event
    handlers. If you need to allow event propagation (to handle file inputs for
    example) you can supply the `preventDefault=false` option to the `{{action}}` helper:
  
    ```handlebars
    <div {{action "sayHello" preventDefault=false}}>
      <input type="file" />
      <input type="checkbox" />
    </div>
    ```
  
    To disable bubbling, pass `bubbles=false` to the helper:
  
    ```handlebars
    <button {{action 'edit' post bubbles=false}}>Edit</button>
    ```
  
    To disable bubbling with closure style actions you must create your own
    wrapper helper that makes use of `event.stopPropagation()`:
  
    ```handlebars
    <div onclick={{disable-bubbling (action "sayHello")}}>Hello</div>
    ```
  
    ```app/helpers/disable-bubbling.js
    import { helper } from '@ember/component/helper';
  
    export function disableBubbling([action]) {
      return function(event) {
        event.stopPropagation();
        return action(event);
      };
    }
    export default helper(disableBubbling);
    ```
  
    If you need the default handler to trigger you should either register your
    own event handler, or use event methods on your view class. See
    ["Responding to Browser Events"](/api/ember/release/classes/Component)
    in the documentation for `Component` for more information.
  
    ### Specifying DOM event type
  
    `{{action}}` helpers called in element space can specify an event type.
    By default the `{{action}}` helper registers for DOM `click` events. You can
    supply an `on` option to the helper to specify a different DOM event name:
  
    ```handlebars
    <div {{action "anActionName" on="doubleClick"}}>
      click me
    </div>
    ```
  
    See ["Event Names"](/api/ember/release/classes/Component) for a list of
    acceptable DOM event names.
  
    ### Specifying whitelisted modifier keys
  
    `{{action}}` helpers called in element space can specify modifier keys.
    By default the `{{action}}` helper will ignore click events with pressed modifier
    keys. You can supply an `allowedKeys` option to specify which keys should not be ignored.
  
    ```handlebars
    <div {{action "anActionName" allowedKeys="alt"}}>
      click me
    </div>
    ```
  
    This way the action will fire when clicking with the alt key pressed down.
    Alternatively, supply "any" to the `allowedKeys` option to accept any combination of modifier keys.
  
    ```handlebars
    <div {{action "anActionName" allowedKeys="any"}}>
      click me with any key pressed
    </div>
    ```
  
    ### Specifying a Target
  
    A `target` option can be provided to the helper to change
    which object will receive the method call. This option must be a path
    to an object, accessible in the current context:
  
    ```app/templates/application.hbs
    <div {{action "anActionName" target=someService}}>
      click me
    </div>
    ```
  
    ```app/controllers/application.js
    import Controller from '@ember/controller';
    import { inject as service } from '@ember/service';
  
    export default Controller.extend({
      someService: service()
    });
    ```
  
    @method action
    @for Ember.Templates.helpers
    @public
  */


  function action(_vm, args) {
    let {
      named,
      positional
    } = args;
    let capturedArgs = positional.capture(); // The first two argument slots are reserved.
    // pos[0] is the context (or `this`)
    // pos[1] is the action name or function
    // Anything else is an action argument.

    let [context, action, ...restArgs] = capturedArgs.references; // TODO: Is there a better way of doing this?

    let debugKey = action.propertyKey;
    let target = named.has('target') ? named.get('target') : context;
    let processArgs = makeArgsProcessor(named.has('value') && named.get('value'), restArgs);
    let fn;

    if (typeof action[INVOKE] === 'function') {
      fn = makeClosureAction(action, action, action[INVOKE], processArgs, debugKey);
    } else if ((0, _reference.isConst)(target) && (0, _reference.isConst)(action)) {
      fn = makeClosureAction(context.value(), target.value(), action.value(), processArgs, debugKey);
    } else {
      fn = makeDynamicClosureAction(context.value(), target, action, processArgs, debugKey);
    }

    fn[ACTION] = true;
    return new UnboundReference(fn);
  }

  function NOOP$1(args) {
    return args;
  }

  function makeArgsProcessor(valuePathRef, actionArgsRef) {
    let mergeArgs;

    if (actionArgsRef.length > 0) {
      mergeArgs = args => {
        return actionArgsRef.map(ref => ref.value()).concat(args);
      };
    }

    let readValue;

    if (valuePathRef) {
      readValue = args => {
        let valuePath = valuePathRef.value();

        if (valuePath && args.length > 0) {
          args[0] = (0, _metal.get)(args[0], valuePath);
        }

        return args;
      };
    }

    if (mergeArgs && readValue) {
      return args => {
        return readValue(mergeArgs(args));
      };
    } else {
      return mergeArgs || readValue || NOOP$1;
    }
  }

  function makeDynamicClosureAction(context, targetRef, actionRef, processArgs, debugKey) {
    // We don't allow undefined/null values, so this creates a throw-away action to trigger the assertions
    if (true
    /* DEBUG */
    ) {
        makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey);
      }

    return (...args) => {
      return makeClosureAction(context, targetRef.value(), actionRef.value(), processArgs, debugKey)(...args);
    };
  }

  function makeClosureAction(context, target, action, processArgs, debugKey) {
    let self;
    let fn;
    true && !(action !== undefined && action !== null) && (0, _debug.assert)("Action passed is null or undefined in (action) from " + target + ".", action !== undefined && action !== null);

    if (typeof action[INVOKE] === 'function') {
      self = action;
      fn = action[INVOKE];
    } else {
      let typeofAction = typeof action;

      if (typeofAction === 'string') {
        self = target;
        fn = target.actions && target.actions[action];
        true && !fn && (0, _debug.assert)("An action named '" + action + "' was not found in " + target, fn);
      } else if (typeofAction === 'function') {
        self = context;
        fn = action;
      } else {
        // tslint:disable-next-line:max-line-length
        true && !false && (0, _debug.assert)("An action could not be made for `" + (debugKey || action) + "` in " + target + ". Please confirm that you are using either a quoted action name (i.e. `(action '" + (debugKey || 'myAction') + "')`) or a function available in " + target + ".", false);
      }
    }

    return (...args) => {
      let payload = {
        target: self,
        args,
        label: '@glimmer/closure-action'
      };
      return (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
        return (0, _runloop.join)(self, fn, ...processArgs(args));
      });
    };
  }
  /**
  @module ember
  */

  /**
     Use the `{{array}}` helper to create an array to pass as an option to your
     components.
  
     ```handlebars
     <MyComponent @people={{array
       'Tom Dade'
       'Yehuda Katz'
       this.myOtherPerson}}
     />
     ```
      or
     ```handlebars
     {{my-component people=(array
       'Tom Dade'
       'Yehuda Katz'
       this.myOtherPerson)
     }}
     ```
  
     Would result in an object such as:
  
     ```js
     ['Tom Date', 'Yehuda Katz', this.get('myOtherPerson')]
     ```
  
     Where the 3rd item in the array is bound to updates of the `myOtherPerson` property.
  
     @method array
     @for Ember.Templates.helpers
     @param {Array} options
     @return {Array} Array
     @since 3.8.0
     @public
   */


  function array(_vm, args) {
    return args.positional.capture();
  }

  const isEmpty = value => {
    return value === null || value === undefined || typeof value.toString !== 'function';
  };

  const normalizeTextValue = value => {
    if (isEmpty(value)) {
      return '';
    }

    return String(value);
  };
  /**
  @module ember
  */

  /**
    Concatenates the given arguments into a string.
  
    Example:
  
    ```handlebars
    {{some-component name=(concat firstName " " lastName)}}
  
    {{! would pass name="<first name value> <last name value>" to the component}}
    ```
  
    or for angle bracket invocation, you actually don't need concat at all.
  
    ```handlebars
    <SomeComponent @name="{{firstName}} {{lastName}}" />
    ```
  
    @public
    @method concat
    @for Ember.Templates.helpers
    @since 1.13.0
  */


  function concat({
    positional
  }) {
    return positional.value().map(normalizeTextValue).join('');
  }

  function concat$1(_vm, args) {
    return new InternalHelperReference(concat, args.capture());
  }
  /**
  @module ember
  */

  /**
    Dynamically look up a property on an object. The second argument to `{{get}}`
    should have a string value, although it can be bound.
  
    For example, these two usages are equivalent:
  
    ```handlebars
    {{person.height}}
    {{get person "height"}}
    ```
  
    If there were several facts about a person, the `{{get}}` helper can dynamically
    pick one:
  
    ```handlebars
    {{get person factName}}
    ```
  
    For a more complex example, this template would allow the user to switch
    between showing the user's height and weight with a click:
  
    ```handlebars
    {{get person factName}}
    <button {{action (action (mut factName)) "height"}}>Show height</button>
    <button {{action (action (mut factName)) "weight"}}>Show weight</button>
    ```
  
    The `{{get}}` helper can also respect mutable values itself. For example:
  
    ```handlebars
    {{input value=(mut (get person factName)) type="text"}}
    <button {{action (action (mut factName)) "height"}}>Show height</button>
    <button {{action (action (mut factName)) "weight"}}>Show weight</button>
    ```
  
    Would allow the user to swap what fact is being displayed, and also edit
    that fact via a two-way mutable binding.
  
    @public
    @method get
    @for Ember.Templates.helpers
    @since 2.1.0
   */


  function get$1(_vm, args) {
    return GetHelperReference.create(args.positional.at(0), args.positional.at(1));
  }

  function referenceFromPath(source, path) {
    let innerReference;

    if (path === undefined || path === null || path === '') {
      innerReference = _runtime2.NULL_REFERENCE;
    } else if (typeof path === 'string' && path.indexOf('.') > -1) {
      innerReference = referenceFromParts(source, path.split('.'));
    } else {
      innerReference = source.get(path);
    }

    return innerReference;
  }

  class GetHelperReference extends CachedReference$1 {
    static create(sourceReference, pathReference) {
      if ((0, _reference.isConst)(pathReference)) {
        let path = pathReference.value();
        return referenceFromPath(sourceReference, path);
      } else {
        return new GetHelperReference(sourceReference, pathReference);
      }
    }

    constructor(sourceReference, pathReference) {
      super();
      this.sourceReference = sourceReference;
      this.pathReference = pathReference;
      this.lastPath = null;
      this.innerReference = _runtime2.NULL_REFERENCE;

      let innerTag = this.innerTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);

      this.tag = (0, _reference.combine)([sourceReference.tag, pathReference.tag, innerTag]);
    }

    compute() {
      let {
        lastPath,
        innerReference,
        innerTag
      } = this;
      let path = this.pathReference.value();

      if (path !== lastPath) {
        innerReference = referenceFromPath(this.sourceReference, path);
        innerTag.inner.update(innerReference.tag);
        this.innerReference = innerReference;
        this.lastPath = path;
      }

      return innerReference.value();
    }

    [UPDATE](value) {
      (0, _metal.set)(this.sourceReference.value(), this.pathReference.value(), value);
    }

  }
  /**
  @module ember
  */

  /**
     Use the `{{hash}}` helper to create a hash to pass as an option to your
     components. This is specially useful for contextual components where you can
     just yield a hash:
  
     ```handlebars
     {{yield (hash
        name='Sarah'
        title=office
     )}}
     ```
  
     Would result in an object such as:
  
     ```js
     { name: 'Sarah', title: this.get('office') }
     ```
  
     Where the `title` is bound to updates of the `office` property.
  
     Note that the hash is an empty object with no prototype chain, therefore
     common methods like `toString` are not available in the resulting hash.
     If you need to use such a method, you can use the `call` or `apply`
     approach:
  
     ```js
     function toString(obj) {
       return Object.prototype.toString.apply(obj);
     }
     ```
  
     @method hash
     @for Ember.Templates.helpers
     @param {Object} options
     @return {Object} Hash
     @since 2.3.0
     @public
   */


  function hash(_vm, args) {
    return args.named.capture();
  }
  /**
  @module ember
  */


  class ConditionalHelperReference extends CachedReference$1 {
    static create(_condRef, truthyRef, falsyRef) {
      let condRef = ConditionalReference$1.create(_condRef);

      if ((0, _reference.isConst)(condRef)) {
        return condRef.value() ? truthyRef : falsyRef;
      } else {
        return new ConditionalHelperReference(condRef, truthyRef, falsyRef);
      }
    }

    constructor(cond, truthy, falsy) {
      super();
      this.branchTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
      this.tag = (0, _reference.combine)([cond.tag, this.branchTag]);
      this.cond = cond;
      this.truthy = truthy;
      this.falsy = falsy;
    }

    compute() {
      let branch = this.cond.value() ? this.truthy : this.falsy;
      this.branchTag.inner.update(branch.tag);
      return branch.value();
    }

  }
  /**
    The `if` helper allows you to conditionally render one of two branches,
    depending on the "truthiness" of a property.
    For example the following values are all falsey: `false`, `undefined`, `null`, `""`, `0`, `NaN` or an empty array.
  
    This helper has two forms, block and inline.
  
    ## Block form
  
    You can use the block form of `if` to conditionally render a section of the template.
  
    To use it, pass the conditional value to the `if` helper,
    using the block form to wrap the section of template you want to conditionally render.
    Like so:
  
    ```handlebars
    {{! will not render if foo is falsey}}
    {{#if foo}}
      Welcome to the {{foo.bar}}
    {{/if}}
    ```
  
    You can also specify a template to show if the property is falsey by using
    the `else` helper.
  
    ```handlebars
    {{! is it raining outside?}}
    {{#if isRaining}}
      Yes, grab an umbrella!
    {{else}}
      No, it's lovely outside!
    {{/if}}
    ```
  
    You are also able to combine `else` and `if` helpers to create more complex
    conditional logic.
  
    ```handlebars
    {{#if isMorning}}
      Good morning
    {{else if isAfternoon}}
      Good afternoon
    {{else}}
      Good night
    {{/if}}
    ```
  
    ## Inline form
  
    The inline `if` helper conditionally renders a single property or string.
  
    In this form, the `if` helper receives three arguments, the conditional value,
    the value to render when truthy, and the value to render when falsey.
  
    For example, if `useLongGreeting` is truthy, the following:
  
    ```handlebars
    {{if useLongGreeting "Hello" "Hi"}} Alex
    ```
  
    Will render:
  
    ```html
    Hello Alex
    ```
  
    ### Nested `if`
  
    You can use the `if` helper inside another helper as a nested helper:
  
    ```handlebars
    <SomeComponent @height={{if isBig "100" "10"}} />
    ```
  
    or
  
    ```handlebars
    {{some-component height=(if isBig "100" "10")}}
    ```
  
    One detail to keep in mind is that both branches of the `if` helper will be evaluated,
    so if you have `{{if condition "foo" (expensive-operation "bar")`,
    `expensive-operation` will always calculate.
  
    @method if
    @for Ember.Templates.helpers
    @public
  */


  function inlineIf(_vm, {
    positional
  }) {
    true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `if` helper expects two or three arguments, e.g. ' + '`{{if trialExpired "Expired" expiryDate}}`.', positional.length === 3 || positional.length === 2);
    return ConditionalHelperReference.create(positional.at(0), positional.at(1), positional.at(2));
  }
  /**
    The `unless` helper is the inverse of the `if` helper. It displays if a value
    is falsey ("not true" or "is false"). Example values that will display with
    `unless`: `false`, `undefined`, `null`, `""`, `0`, `NaN` or an empty array.
  
    ## Inline form
  
    The inline `unless` helper conditionally renders a single property or string.
    This helper acts like a ternary operator. If the first property is falsy,
    the second argument will be displayed, otherwise, the third argument will be
    displayed
  
    For example, if `useLongGreeting` is false below:
  
    ```handlebars
    {{unless useLongGreeting "Hi" "Hello"}} Ben
    ```
  
    Then it will display:
  
    ```html
    Hi
    ```
  
    You can use the `unless` helper inside another helper as a subexpression.
    If isBig is not true, it will set the height to 10:
  
    ```handlebars
    {{! If isBig is not true, it will set the height to 10.}}
    <SomeComponent @height={{unless isBig "10" "100"}} />
    ```
  
    or
  
    ```handlebars
    {{some-component height=(unless isBig "10" "100")}}
    ```
  
    ## Block form
  
    Like the `if` helper, `unless` helper also has a block form.
  
    ```handlebars
    {{! If greetings are found, the text below will not render.}}
    {{#unless greetings}}
      No greetings were found. Why not set one?
    {{/unless}}
    ```
  
    You can also use an `else` helper with the `unless` block. The
    `else` will display if the value is truthy.
  
    ```handlebars
    {{! Is the user logged in?}}
    {{#unless userData}}
      Please login.
    {{else}}
      Welcome back!
    {{/unless}}
    ```
  
    If `userData` is false, undefined, null, or empty in the above example,
    then it will render:
  
    ```html
    Please login.
    ```
  
    @method unless
    @for Ember.Templates.helpers
    @public
  */


  function inlineUnless(_vm, {
    positional
  }) {
    true && !(positional.length === 3 || positional.length === 2) && (0, _debug.assert)('The inline form of the `unless` helper expects two or three arguments, e.g. ' + '`{{unless isFirstLogin "Welcome back!"}}`.', positional.length === 3 || positional.length === 2);
    return ConditionalHelperReference.create(positional.at(0), positional.at(2), positional.at(1));
  }
  /**
  @module ember
  */

  /**
    `log` allows you to output the value of variables in the current rendering
    context. `log` also accepts primitive types such as strings or numbers.
  
    ```handlebars
    {{log "myVariable:" myVariable }}
    ```
  
    @method log
    @for Ember.Templates.helpers
    @param {Array} params
    @public
  */


  function log({
    positional
  }) {
    /* eslint-disable no-console */
    console.log(...positional.value());
    /* eslint-enable no-console */
  }

  function log$1(_vm, args) {
    return new InternalHelperReference(log, args.capture());
  }
  /**
  @module ember
  */

  /**
    The `mut` helper lets you __clearly specify__ that a child `Component` can update the
    (mutable) value passed to it, which will __change the value of the parent component__.
  
    To specify that a parameter is mutable, when invoking the child `Component`:
  
    ```handlebars
    <MyChild @childClickCount={{action (mut totalClicks)}} />
    ```
  
     or
  
    ```handlebars
    {{my-child childClickCount=(mut totalClicks)}}
    ```
  
    The child `Component` can then modify the parent's value just by modifying its own
    property:
  
    ```javascript
    // my-child.js
    export default Component.extend({
      click() {
        this.incrementProperty('childClickCount');
      }
    });
    ```
  
    Note that for curly components (`{{my-component}}`) the bindings are already mutable,
    making the `mut` unnecessary.
  
    Additionally, the `mut` helper can be combined with the `action` helper to
    mutate a value. For example:
  
    ```handlebars
    <MyChild @childClickCount={{this.totalClicks}} @click-count-change={{action (mut totalClicks))}} />
    ```
  
    or
  
    ```handlebars
    {{my-child childClickCount=totalClicks click-count-change=(action (mut totalClicks))}}
    ```
  
    The child `Component` would invoke the action with the new click value:
  
    ```javascript
    // my-child.js
    export default Component.extend({
      click() {
        this.get('click-count-change')(this.get('childClickCount') + 1);
      }
    });
    ```
  
    The `mut` helper changes the `totalClicks` value to what was provided as the action argument.
  
    The `mut` helper, when used with `action`, will return a function that
    sets the value passed to `mut` to its first argument. This works like any other
    closure action and interacts with the other features `action` provides.
    As an example, we can create a button that increments a value passing the value
    directly to the `action`:
  
    ```handlebars
    {{! inc helper is not provided by Ember }}
    <button onclick={{action (mut count) (inc count)}}>
      Increment count
    </button>
    ```
  
    You can also use the `value` option:
  
    ```handlebars
    <input value={{name}} oninput={{action (mut name) value="target.value"}}>
    ```
  
    @method mut
    @param {Object} [attr] the "two-way" attribute that can be modified.
    @for Ember.Templates.helpers
    @public
  */


  const MUT_REFERENCE = (0, _utils.symbol)('MUT');
  const SOURCE = (0, _utils.symbol)('SOURCE');

  function isMut(ref) {
    return ref && ref[MUT_REFERENCE];
  }

  function unMut(ref) {
    return ref[SOURCE] || ref;
  }

  function mut(_vm, args) {
    let rawRef = args.positional.at(0);

    if (isMut(rawRef)) {
      return rawRef;
    } // TODO: Improve this error message. This covers at least two distinct
    // cases:
    //
    // 1. (mut "not a path") – passing a literal, result from a helper
    //    invocation, etc
    //
    // 2. (mut receivedValue) – passing a value received from the caller
    //    that was originally derived from a literal, result from a helper
    //    invocation, etc
    //
    // This message is alright for the first case, but could be quite
    // confusing for the second case.


    true && !rawRef[UPDATE] && (0, _debug.assert)('You can only pass a path to mut', rawRef[UPDATE]);
    let wrappedRef = Object.create(rawRef);
    wrappedRef[SOURCE] = rawRef;
    wrappedRef[INVOKE] = rawRef[UPDATE];
    wrappedRef[MUT_REFERENCE] = true;
    return wrappedRef;
  }
  /**
  @module ember
  */

  /**
    This is a helper to be used in conjunction with the link-to helper.
    It will supply url query parameters to the target route.
  
    Example
  
    ```handlebars
    {{#link-to 'posts' (query-params direction="asc")}}Sort{{/link-to}}
    ```
  
    @method query-params
    @for Ember.Templates.helpers
    @param {Object} hash takes a hash of query parameters
    @return {Object} A `QueryParams` object for `{{link-to}}`
    @public
  */


  function queryParams({
    positional,
    named
  }) {
    // tslint:disable-next-line:max-line-length
    true && !(positional.value().length === 0) && (0, _debug.assert)("The `query-params` helper only accepts hash parameters, e.g. (query-params queryParamPropertyName='foo') as opposed to just (query-params 'foo')", positional.value().length === 0);
    return new _routing.QueryParams((0, _polyfills.assign)({}, named.value()));
  }

  function queryParams$1(_vm, args) {
    return new InternalHelperReference(queryParams, args.capture());
  }
  /**
    The `readonly` helper let's you specify that a binding is one-way only,
    instead of two-way.
    When you pass a `readonly` binding from an outer context (e.g. parent component),
    to to an inner context (e.g. child component), you are saying that changing that
    property in the inner context does not change the value in the outer context.
  
    To specify that a binding is read-only, when invoking the child `Component`:
  
    ```app/components/my-parent.js
    export default Component.extend({
      totalClicks: 3
    });
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} // -> 3
    <MyChild @childClickCount={{readonly totalClicks}} />
    ```
    ```
    {{my-child childClickCount=(readonly totalClicks)}}
    ```
  
    Now, when you update `childClickCount`:
  
    ```app/components/my-child.js
    export default Component.extend({
      click() {
        this.incrementProperty('childClickCount');
      }
    });
    ```
  
    The value updates in the child component, but not the parent component:
  
    ```app/templates/components/my-child.hbs
    {{log childClickCount}} //-> 4
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} //-> 3
    <MyChild @childClickCount={{readonly totalClicks}} />
    ```
    or
    ```app/templates/components/my-parent.hbs
    {{log totalClicks}} //-> 3
    {{my-child childClickCount=(readonly totalClicks)}}
    ```
  
    ### Objects and Arrays
  
    When passing a property that is a complex object (e.g. object, array) instead of a primitive object (e.g. number, string),
    only the reference to the object is protected using the readonly helper.
    This means that you can change properties of the object both on the parent component, as well as the child component.
    The `readonly` binding behaves similar to the `const` keyword in JavaScript.
  
    Let's look at an example:
  
    First let's set up the parent component:
  
    ```app/components/my-parent.js
    import Component from '@ember/component';
  
    export default Component.extend({
      clicks: null,
  
      init() {
        this._super(...arguments);
        this.set('clicks', { total: 3 });
      }
    });
    ```
  
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 3
    <MyChild @childClicks={{readonly clicks}} />
    ```
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 3
    {{my-child childClicks=(readonly clicks)}}
    ```
  
    Now, if you update the `total` property of `childClicks`:
  
    ```app/components/my-child.js
    import Component from '@ember/component';
  
    export default Component.extend({
      click() {
        this.get('clicks').incrementProperty('total');
      }
    });
    ```
  
    You will see the following happen:
  
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 4
    <MyChild @childClicks={{readonly clicks}} />
    ```
    or
    ```app/templates/components/my-parent.hbs
    {{log clicks.total}} //-> 4
    {{my-child childClicks=(readonly clicks)}}
    ```
  
    ```app/templates/components/my-child.hbs
    {{log childClicks.total}} //-> 4
    ```
  
    @method readonly
    @param {Object} [attr] the read-only attribute.
    @for Ember.Templates.helpers
    @private
  */


  function readonly(_vm, args) {
    let ref = unMut(args.positional.at(0));
    return new ReadonlyReference(ref);
  }
  /**
  @module ember
  */

  /**
    The `{{unbound}}` helper disconnects the one-way binding of a property,
    essentially freezing its value at the moment of rendering. For example,
    in this example the display of the variable `name` will not change even
    if it is set with a new value:
  
    ```handlebars
    {{unbound name}}
    ```
  
    Like any helper, the `unbound` helper can accept a nested helper expression.
    This allows for custom helpers to be rendered unbound:
  
    ```handlebars
    {{unbound (some-custom-helper)}}
    {{unbound (capitalize name)}}
    {{! You can use any helper, including unbound, in a nested expression }}
    {{capitalize (unbound name)}}
    ```
  
    The `unbound` helper only accepts a single argument, and it return an
    unbound value.
  
    @method unbound
    @for Ember.Templates.helpers
    @public
  */


  function unbound(_vm, args) {
    true && !(args.positional.length === 1 && args.named.length === 0) && (0, _debug.assert)('unbound helper cannot be called with multiple params or hash params', args.positional.length === 1 && args.named.length === 0);
    return UnboundReference.create(args.positional.at(0).value());
  }

  const MODIFIERS = ['alt', 'shift', 'meta', 'ctrl'];
  const POINTER_EVENT_TYPE_REGEX = /^click|mouse|touch/;

  function isAllowedEvent(event, allowedKeys) {
    if (allowedKeys === null || allowedKeys === undefined) {
      if (POINTER_EVENT_TYPE_REGEX.test(event.type)) {
        return (0, _views.isSimpleClick)(event);
      } else {
        allowedKeys = '';
      }
    }

    if (allowedKeys.indexOf('any') >= 0) {
      return true;
    }

    for (let i = 0; i < MODIFIERS.length; i++) {
      if (event[MODIFIERS[i] + 'Key'] && allowedKeys.indexOf(MODIFIERS[i]) === -1) {
        return false;
      }
    }

    return true;
  }

  let ActionHelper = {
    // registeredActions is re-exported for compatibility with older plugins
    // that were using this undocumented API.
    registeredActions: _views.ActionManager.registeredActions,

    registerAction(actionState) {
      let {
        actionId
      } = actionState;
      _views.ActionManager.registeredActions[actionId] = actionState;
      return actionId;
    },

    unregisterAction(actionState) {
      let {
        actionId
      } = actionState;
      delete _views.ActionManager.registeredActions[actionId];
    }

  };

  class ActionState {
    constructor(element, actionId, actionName, actionArgs, namedArgs, positionalArgs, implicitTarget, dom, tag) {
      this.element = element;
      this.actionId = actionId;
      this.actionName = actionName;
      this.actionArgs = actionArgs;
      this.namedArgs = namedArgs;
      this.positional = positionalArgs;
      this.implicitTarget = implicitTarget;
      this.dom = dom;
      this.eventName = this.getEventName();
      this.tag = tag;
    }

    getEventName() {
      return this.namedArgs.get('on').value() || 'click';
    }

    getActionArgs() {
      let result = new Array(this.actionArgs.length);

      for (let i = 0; i < this.actionArgs.length; i++) {
        result[i] = this.actionArgs[i].value();
      }

      return result;
    }

    getTarget() {
      let {
        implicitTarget,
        namedArgs
      } = this;
      let target;

      if (namedArgs.has('target')) {
        target = namedArgs.get('target').value();
      } else {
        target = implicitTarget.value();
      }

      return target;
    }

    handler(event) {
      let {
        actionName,
        namedArgs
      } = this;
      let bubbles = namedArgs.get('bubbles');
      let preventDefault = namedArgs.get('preventDefault');
      let allowedKeys = namedArgs.get('allowedKeys');
      let target = this.getTarget();
      let shouldBubble = bubbles.value() !== false;

      if (!isAllowedEvent(event, allowedKeys.value())) {
        return true;
      }

      if (preventDefault.value() !== false) {
        event.preventDefault();
      }

      if (!shouldBubble) {
        event.stopPropagation();
      }

      (0, _runloop.join)(() => {
        let args = this.getActionArgs();
        let payload = {
          args,
          target,
          name: null
        };

        if (typeof actionName[INVOKE] === 'function') {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            actionName[INVOKE].apply(actionName, args);
          });
          return;
        }

        if (typeof actionName === 'function') {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            actionName.apply(target, args);
          });
          return;
        }

        payload.name = actionName;

        if (target.send) {
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            target.send.apply(target, [actionName, ...args]);
          });
        } else {
          true && !(typeof target[actionName] === 'function') && (0, _debug.assert)("The action '" + actionName + "' did not exist on " + target, typeof target[actionName] === 'function');
          (0, _instrumentation.flaggedInstrument)('interaction.ember-action', payload, () => {
            target[actionName].apply(target, args);
          });
        }
      });
      return shouldBubble;
    }

    destroy() {
      ActionHelper.unregisterAction(this);
    }

  } // implements ModifierManager<Action>


  class ActionModifierManager {
    create(element, _state, args, _dynamicScope, dom) {
      let {
        named,
        positional,
        tag
      } = args.capture();
      let implicitTarget;
      let actionName;
      let actionNameRef;

      if (positional.length > 1) {
        implicitTarget = positional.at(0);
        actionNameRef = positional.at(1);

        if (actionNameRef[INVOKE]) {
          actionName = actionNameRef;
        } else {
          let actionLabel = actionNameRef.propertyKey;
          actionName = actionNameRef.value();
          true && !(typeof actionName === 'string' || typeof actionName === 'function') && (0, _debug.assert)('You specified a quoteless path, `' + actionLabel + '`, to the ' + '{{action}} helper which did not resolve to an action name (a ' + 'string). Perhaps you meant to use a quoted actionName? (e.g. ' + '{{action "' + actionLabel + '"}}).', typeof actionName === 'string' || typeof actionName === 'function');
        }
      }

      let actionArgs = []; // The first two arguments are (1) `this` and (2) the action name.
      // Everything else is a param.

      for (let i = 2; i < positional.length; i++) {
        actionArgs.push(positional.at(i));
      }

      let actionId = (0, _utils.uuid)();
      return new ActionState(element, actionId, actionName, actionArgs, named, positional, implicitTarget, dom, tag);
    }

    install(actionState) {
      let {
        dom,
        element,
        actionId
      } = actionState;
      ActionHelper.registerAction(actionState);
      dom.setAttribute(element, 'data-ember-action', '');
      dom.setAttribute(element, "data-ember-action-" + actionId, actionId);
    }

    update(actionState) {
      let {
        positional
      } = actionState;
      let actionNameRef = positional.at(1);

      if (!actionNameRef[INVOKE]) {
        actionState.actionName = actionNameRef.value();
      }

      actionState.eventName = actionState.getEventName();
    }

    getTag(actionState) {
      return actionState.tag;
    }

    getDestructor(modifier) {
      return modifier;
    }

  } // Currently there are no capabilities for modifiers


  function capabilities$1(_managerAPI, _optionalFeatures) {
    return {};
  }

  class CustomModifierDefinition {
    constructor(name, ModifierClass, delegate, isInteractive) {
      this.name = name;
      this.ModifierClass = ModifierClass;
      this.delegate = delegate;
      this.state = {
        ModifierClass,
        name,
        delegate
      };
      this.manager = isInteractive ? CUSTOM_INTERACTIVE_MODIFIER_MANAGER : CUSTOM_NON_INTERACTIVE_MODIFIER_MANAGER;
    }

  }

  class CustomModifierState {
    constructor(element, delegate, modifier, args) {
      this.element = element;
      this.delegate = delegate;
      this.modifier = modifier;
      this.args = args;
    }

    destroy() {
      const {
        delegate,
        modifier,
        args
      } = this;
      delegate.destroyModifier(modifier, args.value());
    }

  }
  /**
    The CustomModifierManager allows addons to provide custom modifier
    implementations that integrate seamlessly into Ember. This is accomplished
    through a delegate, registered with the custom modifier manager, which
    implements a set of hooks that determine modifier behavior.
    To create a custom modifier manager, instantiate a new CustomModifierManager
    class and pass the delegate as the first argument:
  
    ```js
    let manager = new CustomModifierManager({
      // ...delegate implementation...
    });
    ```
  
    ## Delegate Hooks
  
    Throughout the lifecycle of a modifier, the modifier manager will invoke
    delegate hooks that are responsible for surfacing those lifecycle changes to
    the end developer.
    * `createModifier()` - invoked when a new instance of a modifier should be created
    * `installModifier()` - invoked when the modifier is installed on the element
    * `updateModifier()` - invoked when the arguments passed to a modifier change
    * `destroyModifier()` - invoked when the modifier is about to be destroyed
  */


  class InteractiveCustomModifierManager {
    create(element, definition, args) {
      const capturedArgs = args.capture();
      let instance = definition.delegate.createModifier(definition.ModifierClass, capturedArgs.value());
      return new CustomModifierState(element, definition.delegate, instance, capturedArgs);
    }

    getTag({
      args
    }) {
      return args.tag;
    }

    install(state) {
      let {
        element,
        args,
        delegate,
        modifier
      } = state;
      delegate.installModifier(modifier, element, args.value());
    }

    update(state) {
      let {
        args,
        delegate,
        modifier
      } = state;
      delegate.updateModifier(modifier, args.value());
    }

    getDestructor(state) {
      return state;
    }

  }

  class NonInteractiveCustomModifierManager {
    create() {
      return null;
    }

    getTag() {
      return _reference.CONSTANT_TAG;
    }

    install() {}

    update() {}

    getDestructor() {
      return null;
    }

  }

  const CUSTOM_INTERACTIVE_MODIFIER_MANAGER = new InteractiveCustomModifierManager();
  const CUSTOM_NON_INTERACTIVE_MODIFIER_MANAGER = new NonInteractiveCustomModifierManager();

  function hashToArgs(hash) {
    if (hash === null) return null;
    let names = hash[0].map(key => "@" + key);
    return [names, hash[1]];
  }

  let inputMacro;

  if (true
  /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
  ) {
      if (true
      /* DEBUG */
      ) {
          inputMacro = () => {
            throw (0, _util.unreachable)();
          };
        }
    } else {
    /**
      The `{{input}}` helper lets you create an HTML `<input />` component.
      It causes a `TextField` component to be rendered.  For more info,
      see the [TextField](/api/ember/release/classes/TextField) docs and
      the [templates guide](https://guides.emberjs.com/release/templates/input-helpers/).
         ```handlebars
      {{input value="987"}}
      ```
         renders as:
         ```HTML
      <input type="text" value="987" />
      ```
         ### Text field
         If no `type` option is specified, a default of type 'text' is used.
      Many of the standard HTML attributes may be passed to this helper.
      <table>
        <tr><td>`readonly`</td><td>`required`</td><td>`autofocus`</td></tr>
        <tr><td>`value`</td><td>`placeholder`</td><td>`disabled`</td></tr>
        <tr><td>`size`</td><td>`tabindex`</td><td>`maxlength`</td></tr>
        <tr><td>`name`</td><td>`min`</td><td>`max`</td></tr>
        <tr><td>`pattern`</td><td>`accept`</td><td>`autocomplete`</td></tr>
        <tr><td>`autosave`</td><td>`formaction`</td><td>`formenctype`</td></tr>
        <tr><td>`formmethod`</td><td>`formnovalidate`</td><td>`formtarget`</td></tr>
        <tr><td>`height`</td><td>`inputmode`</td><td>`multiple`</td></tr>
        <tr><td>`step`</td><td>`width`</td><td>`form`</td></tr>
        <tr><td>`selectionDirection`</td><td>`spellcheck`</td><td>&nbsp;</td></tr>
      </table>
      When set to a quoted string, these values will be directly applied to the HTML
      element. When left unquoted, these values will be bound to a property on the
      template's current rendering context (most typically a controller instance).
      A very common use of this helper is to bind the `value` of an input to an Object's attribute:
         ```handlebars
      Search:
      {{input value=searchWord}}
      ```
         In this example, the initial value in the `<input />` will be set to the value of `searchWord`.
      If the user changes the text, the value of `searchWord` will also be updated.
         ### Actions
         The helper can send multiple actions based on user events.
      The action property defines the action which is sent when
      the user presses the return key.
         ```handlebars
      {{input action="submit"}}
      ```
         The helper allows some user events to send actions.
         * `enter`
      * `insert-newline`
      * `escape-press`
      * `focus-in`
      * `focus-out`
      * `key-press`
      * `key-up`
         For example, if you desire an action to be sent when the input is blurred,
      you only need to setup the action name to the event name property.
         ```handlebars
      {{input focus-out="alertMessage"}}
      ```
      See more about [Text Support Actions](/api/ember/release/classes/TextField)
         ### Extending `TextField`
         Internally, `{{input type="text"}}` creates an instance of `TextField`, passing
      arguments from the helper to `TextField`'s `create` method. You can extend the
      capabilities of text inputs in your applications by reopening this class. For example,
      if you are building a Bootstrap project where `data-*` attributes are used, you
      can add one to the `TextField`'s `attributeBindings` property:
         ```javascript
      import TextField from '@ember/component/text-field';
      TextField.reopen({
        attributeBindings: ['data-error']
      });
      ```
         Keep in mind when writing `TextField` subclasses that `TextField`
      itself extends `Component`. Expect isolated component semantics, not
      legacy 1.x view semantics (like `controller` being present).
      See more about [Ember components](/api/ember/release/classes/Component)
         ### Checkbox
         Checkboxes are special forms of the `{{input}}` helper.  To create a `<checkbox />`:
         ```handlebars
      Emberize Everything:
      {{input type="checkbox" name="isEmberized" checked=isEmberized}}
      ```
         This will bind checked state of this checkbox to the value of `isEmberized`  -- if either one changes,
      it will be reflected in the other.
         The following HTML attributes can be set via the helper:
         * `checked`
      * `disabled`
      * `tabindex`
      * `indeterminate`
      * `name`
      * `autofocus`
      * `form`
         ### Extending `Checkbox`
         Internally, `{{input type="checkbox"}}` creates an instance of `Checkbox`, passing
      arguments from the helper to `Checkbox`'s `create` method. You can extend the
      capablilties of checkbox inputs in your applications by reopening this class. For example,
      if you wanted to add a css class to all checkboxes in your application:
         ```javascript
      import Checkbox from '@ember/component/checkbox';
         Checkbox.reopen({
        classNames: ['my-app-checkbox']
      });
      ```
         @method input
      @for Ember.Templates.helpers
      @param {Hash} options
      @public
    */
    let buildSyntax = function buildSyntax(type, params, hash, builder) {
      let definition = builder.compiler['resolver'].lookupComponentDefinition(type, builder.referrer);
      builder.component.static(definition, [params, hashToArgs(hash), null, null]);
      return true;
    };

    inputMacro = function inputMacro(_name, params, hash, builder) {
      if (params === null) {
        params = [];
      }

      if (hash !== null) {
        let keys = hash[0];
        let values = hash[1];
        let typeIndex = keys.indexOf('type');

        if (typeIndex > -1) {
          let typeArg = values[typeIndex];

          if (Array.isArray(typeArg)) {
            // there is an AST plugin that converts this to an expression
            // it really should just compile in the component call too.
            let inputTypeExpr = params[0];
            builder.dynamicComponent(inputTypeExpr, null, params.slice(1), hash, true, null, null);
            return true;
          }

          if (typeArg === 'checkbox') {
            true && !(keys.indexOf('value') === -1) && (0, _debug.assert)("`{{input type='checkbox' value=...}}` is not supported; " + "please use `{{input type='checkbox' checked=...}}` instead.", keys.indexOf('value') === -1);
            wrapComponentClassAttribute(hash);
            return buildSyntax('-checkbox', params, hash, builder);
          }
        }
      }

      return buildSyntax('-text-field', params, hash, builder);
    };
  }
  /**
  @module ember
  */

  /**
      The `let` helper receives one or more positional arguments and yields
      them out as block params.
  
      This allows the developer to introduce shorter names for certain computations
      in the template.
  
      This is especially useful if you are passing properties to a component
      that receives a lot of options and you want to clean up the invocation.
  
      For the following example, the template receives a `post` object with
      `content` and `title` properties.
  
      We are going to call the `my-post` component, passing a title which is
      the title of the post suffixed with the name of the blog, the content
      of the post, and a series of options defined in-place.
  
      ```handlebars
      {{#let
          (concat post.title ' | The Ember.js Blog')
          post.content
          (hash
            theme="high-contrast"
            enableComments=true
          )
          as |title content options|
      }}
        <MyPost @title={{title}} @content={{content}} @options={{options}} />
      {{/let}}
    ```
   or
    ```handlebars
      {{#let
          (concat post.title ' | The Ember.js Blog')
          post.content
          (hash
            theme="high-contrast"
            enableComments=true
          )
          as |title content options|
      }}
        {{my-post title=title content=content options=options}}
      {{/let}}
    ```
  
    @method let
    @for Ember.Templates.helpers
    @public
  */


  function blockLetMacro(params, _hash, template, _inverse, builder) {
    if (template !== null) {
      if (params !== null) {
        builder.compileParams(params);
        builder.invokeStaticBlock(template, params.length);
      } else {
        builder.invokeStatic(template);
      }
    }

    return true;
  }

  const CAPABILITIES$4 = {
    dynamicLayout: true,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    createCaller: true,
    dynamicScope: true,
    updateHook: true,
    createInstance: true
  };

  class MountManager extends AbstractManager {
    getDynamicLayout(state, _) {
      let template = state.engine.lookup('template:application');
      let layout = template.asLayout();
      return {
        handle: layout.compile(),
        symbolTable: layout.symbolTable
      };
    }

    getCapabilities() {
      return CAPABILITIES$4;
    }

    create(environment, state) {
      if (true
      /* DEBUG */
      ) {
          this._pushEngineToDebugStack("engine:" + state.name, environment);
        } // TODO
      // mount is a runtime helper, this shouldn't use dynamic layout
      // we should resolve the engine app template in the helper
      // it also should use the owner that looked up the mount helper.


      let engine = environment.owner.buildChildEngineInstance(state.name);
      engine.boot();
      let applicationFactory = engine.factoryFor("controller:application");
      let controllerFactory = applicationFactory || (0, _routing.generateControllerFactory)(engine, 'application');
      let controller;
      let self;
      let bucket;
      let tag;
      let modelRef = state.modelRef;

      if (modelRef === undefined) {
        controller = controllerFactory.create();
        self = new RootReference(controller);
        tag = _reference.CONSTANT_TAG;
        bucket = {
          engine,
          controller,
          self,
          tag
        };
      } else {
        let model = modelRef.value();
        let modelRev = modelRef.tag.value();
        controller = controllerFactory.create({
          model
        });
        self = new RootReference(controller);
        tag = modelRef.tag;
        bucket = {
          engine,
          controller,
          self,
          tag,
          modelRef,
          modelRev
        };
      }

      return bucket;
    }

    getSelf({
      self
    }) {
      return self;
    }

    getTag(state) {
      return state.tag;
    }

    getDestructor({
      engine
    }) {
      return engine;
    }

    didRenderLayout() {
      if (true
      /* DEBUG */
      ) {
          this.debugStack.pop();
        }
    }

    update(bucket) {
      let {
        controller,
        modelRef,
        modelRev
      } = bucket;

      if (!modelRef.tag.validate(modelRev)) {
        let model = modelRef.value();
        bucket.modelRev = modelRef.tag.value();
        controller.set('model', model);
      }
    }

  }

  const MOUNT_MANAGER = new MountManager();

  class MountDefinition {
    constructor(name, modelRef) {
      this.manager = MOUNT_MANAGER;
      this.state = {
        name,
        modelRef
      };
    }

  }

  function mountHelper(vm, args) {
    let env = vm.env;
    let nameRef = args.positional.at(0);
    let modelRef = args.named.has('model') ? args.named.get('model') : undefined;
    return new DynamicEngineReference(nameRef, env, modelRef);
  }
  /**
    The `{{mount}}` helper lets you embed a routeless engine in a template.
    Mounting an engine will cause an instance to be booted and its `application`
    template to be rendered.
  
    For example, the following template mounts the `ember-chat` engine:
  
    ```handlebars
    {{! application.hbs }}
    {{mount "ember-chat"}}
    ```
  
    Additionally, you can also pass in a `model` argument that will be
    set as the engines model. This can be an existing object:
  
    ```
    <div>
      {{mount 'admin' model=userSettings}}
    </div>
    ```
  
    Or an inline `hash`, and you can even pass components:
  
    ```
    <div>
      <h1>Application template!</h1>
      {{mount 'admin' model=(hash
          title='Secret Admin'
          signInButton=(component 'sign-in-button')
      )}}
    </div>
    ```
  
    @method mount
    @param {String} name Name of the engine to mount.
    @param {Object} [model] Object that will be set as
                            the model of the engine.
    @for Ember.Templates.helpers
    @public
  */


  function mountMacro(_name, params, hash, builder) {
    true && !(params.length === 1) && (0, _debug.assert)('You can only pass a single positional argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1);
    let expr = [_wireFormat.Ops.Helper, '-mount', params || [], hash];
    builder.dynamicComponent(expr, null, [], null, false, null, null);
    return true;
  }

  class DynamicEngineReference {
    constructor(nameRef, env, modelRef) {
      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.modelRef = modelRef;
      this.env = env;
      this._lastName = null;
      this._lastDef = null;
    }

    value() {
      let {
        env,
        nameRef,
        modelRef
      } = this;
      let name = nameRef.value();

      if (typeof name === 'string') {
        if (this._lastName === name) {
          return this._lastDef;
        }

        true && !env.owner.hasRegistration("engine:" + name) && (0, _debug.assert)("You used `{{mount '" + name + "'}}`, but the engine '" + name + "' can not be found.", env.owner.hasRegistration("engine:" + name));

        if (!env.owner.hasRegistration("engine:" + name)) {
          return null;
        }

        this._lastName = name;
        this._lastDef = (0, _runtime2.curry)(new MountDefinition(name, modelRef));
        return this._lastDef;
      } else {
        true && !(name === null || name === undefined) && (0, _debug.assert)("Invalid engine name '" + name + "' specified, engine name must be either a string, null or undefined.", name === null || name === undefined);
        this._lastDef = null;
        this._lastName = null;
        return null;
      }
    }

    get() {
      return _runtime2.UNDEFINED_REFERENCE;
    }

  }
  /**
   * Represents the root outlet.
   */


  class RootOutletReference {
    constructor(outletState) {
      this.outletState = outletState;
      this.tag = _reference.DirtyableTag.create();
    }

    get(key) {
      return new PathReference(this, key);
    }

    value() {
      return this.outletState;
    }

    update(state) {
      this.outletState.outlets.main = state;
      this.tag.inner.dirty();
    }

  }
  /**
   * Represents the connected outlet.
   */


  class OutletReference {
    constructor(parentStateRef, outletNameRef) {
      this.parentStateRef = parentStateRef;
      this.outletNameRef = outletNameRef;
      this.tag = (0, _reference.combine)([parentStateRef.tag, outletNameRef.tag]);
    }

    value() {
      let outletState = this.parentStateRef.value();
      let outlets = outletState === undefined ? undefined : outletState.outlets;
      return outlets === undefined ? undefined : outlets[this.outletNameRef.value()];
    }

    get(key) {
      return new PathReference(this, key);
    }

  }
  /**
   * Outlet state is dirtied from root.
   * This just using the parent tag for dirtiness.
   */


  class PathReference {
    constructor(parent, key) {
      this.parent = parent;
      this.key = key;
      this.tag = parent.tag;
    }

    get(key) {
      return new PathReference(this, key);
    }

    value() {
      let parent = this.parent.value();
      return parent && parent[this.key];
    }

  }
  /**
    The `{{outlet}}` helper lets you specify where a child route will render in
    your template. An important use of the `{{outlet}}` helper is in your
    application's `application.hbs` file:
  
    ```handlebars
    {{! app/templates/application.hbs }}
    <!-- header content goes here, and will always display -->
    <MyHeader />
    <div class="my-dynamic-content">
      <!-- this content will change based on the current route, which depends on the current URL -->
      {{outlet}}
    </div>
    <!-- footer content goes here, and will always display -->
    <MyFooter />
    ```
  
    You may also specify a name for the `{{outlet}}`, which is useful when using more than one
    `{{outlet}}` in a template:
  
    ```handlebars
    {{outlet "menu"}}
    {{outlet "sidebar"}}
    {{outlet "main"}}
    ```
  
    Your routes can then render into a specific one of these `outlet`s by specifying the `outlet`
    attribute in your `renderTemplate` function:
  
    ```app/routes/menu.js
    import Route from '@ember/routing/route';
  
    export default Route.extend({
      renderTemplate() {
        this.render({ outlet: 'menu' });
      }
    });
    ```
  
    See the [routing guide](https://guides.emberjs.com/release/routing/rendering-a-template/) for more
    information on how your `route` interacts with the `{{outlet}}` helper.
    Note: Your content __will not render__ if there isn't an `{{outlet}}` for it.
  
    @method outlet
    @param {String} [name]
    @for Ember.Templates.helpers
    @public
  */


  function outletHelper(vm, args) {
    let scope = vm.dynamicScope();
    let nameRef;

    if (args.positional.length === 0) {
      nameRef = new _reference.ConstReference('main');
    } else {
      nameRef = args.positional.at(0);
    }

    return new OutletComponentReference(new OutletReference(scope.outletState, nameRef));
  }

  function outletMacro(_name, params, hash, builder) {
    let expr = [_wireFormat.Ops.Helper, '-outlet', params || [], hash];
    builder.dynamicComponent(expr, null, [], null, false, null, null);
    return true;
  }

  class OutletComponentReference {
    constructor(outletRef) {
      this.outletRef = outletRef;
      this.definition = null;
      this.lastState = null; // The router always dirties the root state.

      this.tag = outletRef.tag;
    }

    value() {
      let state = stateFor(this.outletRef);

      if (validate(state, this.lastState)) {
        return this.definition;
      }

      this.lastState = state;
      let definition = null;

      if (state !== null) {
        definition = (0, _runtime2.curry)(new OutletComponentDefinition(state));
      }

      return this.definition = definition;
    }

    get(_key) {
      return _runtime2.UNDEFINED_REFERENCE;
    }

  }

  function stateFor(ref) {
    let outlet = ref.value();
    if (outlet === undefined) return null;
    let render = outlet.render;
    if (render === undefined) return null;
    let template = render.template;
    if (template === undefined) return null;
    return {
      ref,
      name: render.name,
      outlet: render.outlet,
      template,
      controller: render.controller
    };
  }

  function validate(state, lastState) {
    if (state === null) {
      return lastState === null;
    }

    if (lastState === null) {
      return false;
    }

    return state.template === lastState.template && state.controller === lastState.controller;
  }

  let textAreaMacro;

  if (true
  /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
  ) {
      if (true
      /* DEBUG */
      ) {
          textAreaMacro = () => {
            throw (0, _util.unreachable)();
          };
        }
    } else {
    textAreaMacro = function textAreaMacro(_name, params, hash, builder) {
      let definition = builder.compiler['resolver'].lookupComponentDefinition('-text-area', builder.referrer);
      wrapComponentClassAttribute(hash);
      builder.component.static(definition, [params || [], hashToArgs(hash), null, null]);
      return true;
    };
  }

  function refineInlineSyntax(name, params, hash, builder) {
    true && !!(builder.compiler['resolver']['resolver']['builtInHelpers'][name] && builder.referrer.owner.hasRegistration("helper:" + name)) && (0, _debug.assert)("You attempted to overwrite the built-in helper \"" + name + "\" which is not allowed. Please rename the helper.", !(builder.compiler['resolver']['resolver']['builtInHelpers'][name] && builder.referrer.owner.hasRegistration("helper:" + name)));

    if (!true
    /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
    && name.indexOf('-') === -1) {
      return false;
    }

    let handle = builder.compiler['resolver'].lookupComponentDefinition(name, builder.referrer);

    if (handle !== null) {
      builder.component.static(handle, [params === null ? [] : params, hashToArgs(hash), null, null]);
      return true;
    }

    return false;
  }

  function refineBlockSyntax(name, params, hash, template, inverse, builder) {
    if (!true
    /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
    && name.indexOf('-') === -1) {
      return false;
    }

    let handle = builder.compiler['resolver'].lookupComponentDefinition(name, builder.referrer);

    if (handle !== null) {
      wrapComponentClassAttribute(hash);
      builder.component.static(handle, [params, hashToArgs(hash), template, inverse]);
      return true;
    }

    true && !builder.referrer.owner.hasRegistration("helper:" + name) && (0, _debug.assert)("A component or helper named \"" + name + "\" could not be found", builder.referrer.owner.hasRegistration("helper:" + name));
    true && !!(() => {
      const resolver = builder.compiler['resolver']['resolver'];
      const {
        owner,
        moduleName
      } = builder.referrer;

      if (name === 'component' || resolver['builtInHelpers'][name]) {
        return true;
      }

      let options = {
        source: "template:" + moduleName
      };
      return owner.hasRegistration("helper:" + name, options) || owner.hasRegistration("helper:" + name);
    })() && (0, _debug.assert)("Helpers may not be used in the block form, for example {{#" + name + "}}{{/" + name + "}}. Please use a component, or alternatively use the helper in combination with a built-in Ember helper, for example {{#if (" + name + ")}}{{/if}}.", !(() => {
      const resolver = builder.compiler['resolver']['resolver'];
      const {
        owner,
        moduleName
      } = builder.referrer;

      if (name === 'component' || resolver['builtInHelpers'][name]) {
        return true;
      }

      let options = {
        source: "template:" + moduleName
      };
      return owner.hasRegistration("helper:" + name, options) || owner.hasRegistration("helper:" + name);
    })());
    return false;
  }

  const experimentalMacros = []; // This is a private API to allow for experimental macros
  // to be created in user space. Registering a macro should
  // should be done in an initializer.

  _exports._experimentalMacros = experimentalMacros;

  function registerMacros(macro) {
    experimentalMacros.push(macro);
  }

  function populateMacros(macros) {
    let {
      inlines,
      blocks
    } = macros;
    inlines.add('outlet', outletMacro);
    inlines.add('mount', mountMacro);

    if (!true
    /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
    ) {
        inlines.add('input', inputMacro);
        inlines.add('textarea', textAreaMacro);
      }

    inlines.addMissing(refineInlineSyntax);
    blocks.add('let', blockLetMacro);
    blocks.addMissing(refineBlockSyntax);

    for (let i = 0; i < experimentalMacros.length; i++) {
      let macro = experimentalMacros[i];
      macro(blocks, inlines);
    }

    return {
      blocks,
      inlines
    };
  }

  function setModifierManager(factory, obj) {
    return setManager({
      factory,
      internal: false,
      type: 'modifier'
    }, obj);
  }

  function getModifierManager(obj) {
    let wrapper = getManager(obj);

    if (wrapper && !wrapper.internal && wrapper.type === 'modifier') {
      return wrapper.factory;
    } else {
      return undefined;
    }
  }

  function instrumentationPayload$1(name) {
    return {
      object: "component:" + name
    };
  }

  function makeOptions(moduleName, namespace) {
    return {
      source: moduleName !== undefined ? "template:" + moduleName : undefined,
      namespace
    };
  }

  const BUILTINS_HELPERS = {
    if: inlineIf,
    action,
    array,
    concat: concat$1,
    get: get$1,
    hash,
    log: log$1,
    mut,
    'query-params': queryParams$1,
    readonly,
    unbound,
    unless: inlineUnless,
    '-class': classHelper$1,
    '-each-in': eachIn,
    '-input-type': inputTypeHelper$1,
    '-normalize-class': normalizeClassHelper,
    '-get-dynamic-var': _runtime2.getDynamicVar,
    '-mount': mountHelper,
    '-outlet': outletHelper,
    '-assert-implicit-component-helper-argument': componentAssertionHelper
  };
  const BUILTIN_MODIFIERS = {
    action: {
      manager: new ActionModifierManager(),
      state: null
    }
  };

  class RuntimeResolver {
    constructor(isInteractive) {
      this.handles = [undefined];
      this.objToHandle = new WeakMap();
      this.builtInHelpers = BUILTINS_HELPERS;
      this.builtInModifiers = BUILTIN_MODIFIERS; // supports directly imported late bound layouts on component.prototype.layout

      this.templateCache = new Map();
      this.componentDefinitionCache = new Map();
      this.customManagerCache = new Map();
      this.templateCacheHits = 0;
      this.templateCacheMisses = 0;
      this.componentDefinitionCount = 0;
      this.helperDefinitionCount = 0;
      let macros = new _opcodeCompiler.Macros();
      populateMacros(macros);
      this.compiler = new _opcodeCompiler.LazyCompiler(new CompileTimeLookup(this), this, macros);
      this.isInteractive = isInteractive;
    }
    /***  IRuntimeResolver ***/

    /**
     * public componentDefHandleCount = 0;
     * Called while executing Append Op.PushDynamicComponentManager if string
     */


    lookupComponentDefinition(name, meta) {
      let handle = this.lookupComponentHandle(name, meta);

      if (handle === null) {
        true && !false && (0, _debug.assert)("Could not find component named \"" + name + "\" (no component or template with that name was found)");
        return null;
      }

      return this.resolve(handle);
    }

    lookupComponentHandle(name, meta) {
      let nextHandle = this.handles.length;
      let handle = this.handle(this._lookupComponentDefinition(name, meta));
      true && !!(true
      /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
      && name === 'text-area' && handle === null) && (0, _debug.assert)('Could not find component `<TextArea />` (did you mean `<Textarea />`?)', !(true && name === 'text-area' && handle === null));

      if (nextHandle === handle) {
        this.componentDefinitionCount++;
      }

      return handle;
    }
    /**
     * Called by RuntimeConstants to lookup unresolved handles.
     */


    resolve(handle) {
      return this.handles[handle];
    } // End IRuntimeResolver

    /**
     * Called by CompileTimeLookup compiling Unknown or Helper OpCode
     */


    lookupHelper(name, meta) {
      let nextHandle = this.handles.length;

      let helper$$1 = this._lookupHelper(name, meta);

      if (helper$$1 !== null) {
        let handle = this.handle(helper$$1);

        if (nextHandle === handle) {
          this.helperDefinitionCount++;
        }

        return handle;
      }

      return null;
    }
    /**
     * Called by CompileTimeLookup compiling the
     */


    lookupModifier(name, meta) {
      return this.handle(this._lookupModifier(name, meta));
    }
    /**
     * Called by CompileTimeLookup to lookup partial
     */


    lookupPartial(name, meta) {
      let partial = this._lookupPartial(name, meta);

      return this.handle(partial);
    } // end CompileTimeLookup

    /**
     * Creates a template with injections from a directly imported template factory.
     * @param templateFactory the directly imported template factory.
     * @param owner the owner the template instance would belong to if resolved
     */


    createTemplate(factory, owner) {
      let cache = this.templateCache.get(owner);
      let template;

      if (cache === undefined) {
        cache = new Map();
        this.templateCache.set(owner, cache);
      } else {
        template = cache.get(factory);
      }

      if (template === undefined) {
        const {
          compiler
        } = this;
        const injections = {
          compiler
        };
        (0, _owner.setOwner)(injections, owner);
        template = factory.create(injections);
        cache.set(factory, template);
        this.templateCacheMisses++;
      } else {
        this.templateCacheHits++;
      }

      return template;
    } // needed for lazy compile time lookup


    handle(obj) {
      if (obj === undefined || obj === null) {
        return null;
      }

      let handle = this.objToHandle.get(obj);

      if (handle === undefined) {
        handle = this.handles.push(obj) - 1;
        this.objToHandle.set(obj, handle);
      }

      return handle;
    }

    _lookupHelper(_name, meta) {
      const helper$$1 = this.builtInHelpers[_name];

      if (helper$$1 !== undefined) {
        return helper$$1;
      }

      const {
        owner,
        moduleName
      } = meta;
      let name = _name;
      let namespace = undefined;

      if (false
      /* EMBER_MODULE_UNIFICATION */
      ) {
          const parsed = this._parseNameForNamespace(_name);

          name = parsed.name;
          namespace = parsed.namespace;
        }

      const options = makeOptions(moduleName, namespace);
      const factory = owner.factoryFor("helper:" + name, options) || owner.factoryFor("helper:" + name);

      if (!isHelperFactory(factory)) {
        return null;
      }

      return (vm, args) => {
        const helper$$1 = factory.create();

        if (isSimpleHelper(helper$$1)) {
          return SimpleHelperReference.create(helper$$1.compute, args.capture());
        }

        vm.newDestroyable(helper$$1);
        return ClassBasedHelperReference.create(helper$$1, args.capture());
      };
    }

    _lookupPartial(name, meta) {
      const template = (0, _views.lookupPartial)(name, meta.owner);

      if (template) {
        return new _opcodeCompiler.PartialDefinition(name, template);
      } else {
        throw new Error(name + " is not a partial");
      }
    }

    _lookupModifier(name, meta) {
      let builtin = this.builtInModifiers[name];

      if (builtin === undefined) {
        let {
          owner
        } = meta;
        let modifier = owner.factoryFor("modifier:" + name);

        if (modifier !== undefined) {
          let managerFactory = getModifierManager(modifier.class);
          let manager = managerFactory(owner);
          return new CustomModifierDefinition(name, modifier, manager, this.isInteractive);
        }
      }

      return builtin;
    }

    _parseNameForNamespace(_name) {
      let name = _name;
      let namespace = undefined;

      let namespaceDelimiterOffset = _name.indexOf('::');

      if (namespaceDelimiterOffset !== -1) {
        name = _name.slice(namespaceDelimiterOffset + 2);
        namespace = _name.slice(0, namespaceDelimiterOffset);
      }

      return {
        name,
        namespace
      };
    }

    _lookupComponentDefinition(_name, {
      moduleName,
      owner
    }) {
      true && !(true
      /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
      || _name !== 'textarea') && (0, _debug.assert)('Invoking `{{textarea}}` using angle bracket syntax or `component` helper is not yet supported.', true || _name !== 'textarea');
      true && !(true
      /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
      || _name !== 'input') && (0, _debug.assert)('Invoking `{{input}}` using angle bracket syntax or `component` helper is not yet supported.', true || _name !== 'input');
      let name = _name;
      let namespace = undefined;

      if (false
      /* EMBER_MODULE_UNIFICATION */
      ) {
          const parsed = this._parseNameForNamespace(_name);

          name = parsed.name;
          namespace = parsed.namespace;
        }

      let {
        layout,
        component
      } = (0, _views.lookupComponent)(owner, name, makeOptions(moduleName, namespace));
      let key = component === undefined ? layout : component;

      if (key === undefined) {
        return null;
      }

      let cachedComponentDefinition = this.componentDefinitionCache.get(key);

      if (cachedComponentDefinition !== undefined) {
        return cachedComponentDefinition;
      }

      let finalizer = (0, _instrumentation._instrumentStart)('render.getComponentDefinition', instrumentationPayload$1, name);
      let definition = null;

      if (layout !== undefined && component === undefined && _environment2.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
        definition = new TemplateOnlyComponentDefinition(layout);
      }

      if (component !== undefined && component.class !== undefined) {
        let wrapper = getManager(component.class);

        if (wrapper && wrapper.type === 'component') {
          let {
            factory
          } = wrapper;

          if (wrapper.internal) {
            true && !(layout !== undefined) && (0, _debug.assert)("missing layout for internal component " + name, layout !== undefined);
            definition = new InternalComponentDefinition(factory(owner), component.class, layout);
          } else {
            definition = new CustomManagerDefinition(name, component, factory(owner), layout || owner.lookup((0, _container.privatize)(_templateObject2())));
          }
        }
      }

      if (definition === null) {
        definition = new CurlyComponentDefinition(name, component || owner.factoryFor((0, _container.privatize)(_templateObject3())), null, layout // TODO fix type
        );
      }

      finalizer();
      this.componentDefinitionCache.set(key, definition);
      return definition;
    }

    _lookupComponentManager(owner, managerId) {
      if (this.customManagerCache.has(managerId)) {
        return this.customManagerCache.get(managerId);
      }

      let delegate = owner.lookup("component-manager:" + managerId);
      this.customManagerCache.set(managerId, delegate);
      return delegate;
    }

  } // factory for DI


  var TemplateCompiler = {
    create({
      environment
    }) {
      return new RuntimeResolver(environment.isInteractive).compiler;
    }

  };
  var ComponentTemplate = template({
    "id": "TfnYOdXy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/component.hbs"
    }
  });
  var InputTemplate = template({
    "id": "F5xmpEcE",
    "block": "{\"symbols\":[\"Checkbox\",\"TextField\",\"@__ARGS__\",\"&attrs\"],\"statements\":[[4,\"let\",[[29,\"component\",[\"-checkbox\"],null],[29,\"component\",[\"-text-field\"],null]],null,{\"statements\":[[4,\"if\",[[24,0,[\"isCheckbox\"]]],null,{\"statements\":[[6,[24,1,[]],[[14,4]],[[\"@target\",\"@__ARGS__\"],[[24,0,[\"caller\"]],[24,3,[]]]]]],\"parameters\":[]},{\"statements\":[[6,[24,2,[]],[[14,4]],[[\"@target\",\"@__ARGS__\"],[[24,0,[\"caller\"]],[24,3,[]]]]]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/input.hbs"
    }
  });
  var OutletTemplate = template({
    "id": "tzvJXv5n",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "packages/@ember/-internals/glimmer/lib/templates/outlet.hbs"
    }
  });
  const TOP_LEVEL_NAME = '-top-level';
  const TOP_LEVEL_OUTLET = 'main';

  class OutletView {
    constructor(_environment, renderer, owner, template) {
      this._environment = _environment;
      this.renderer = renderer;
      this.owner = owner;
      this.template = template;
      let ref = this.ref = new RootOutletReference({
        outlets: {
          main: undefined
        },
        render: {
          owner: owner,
          into: undefined,
          outlet: TOP_LEVEL_OUTLET,
          name: TOP_LEVEL_NAME,
          controller: undefined,
          template
        }
      });
      this.state = {
        ref,
        name: TOP_LEVEL_NAME,
        outlet: TOP_LEVEL_OUTLET,
        template,
        controller: undefined
      };
    }

    static extend(injections) {
      return class extends OutletView {
        static create(options) {
          if (options) {
            return super.create((0, _polyfills.assign)({}, injections, options));
          } else {
            return super.create(injections);
          }
        }

      };
    }

    static reopenClass(injections) {
      (0, _polyfills.assign)(this, injections);
    }

    static create(options) {
      let {
        _environment,
        renderer,
        template
      } = options;
      let owner = options[_owner.OWNER];
      return new OutletView(_environment, renderer, owner, template);
    }

    appendTo(selector) {
      let target;

      if (this._environment.hasDOM) {
        target = typeof selector === 'string' ? document.querySelector(selector) : selector;
      } else {
        target = selector;
      }

      (0, _runloop.schedule)('render', this.renderer, 'appendOutletView', this, target);
    }

    rerender() {
      /**/
    }

    setOutletState(state) {
      this.ref.update(state);
    }

    destroy() {
      /**/
    }

  }

  _exports.OutletView = OutletView;

  function setupApplicationRegistry(registry) {
    registry.injection('service:-glimmer-environment', 'appendOperations', 'service:-dom-tree-construction');
    registry.injection('renderer', 'env', 'service:-glimmer-environment'); // because we are using injections we can't use instantiate false
    // we need to use bind() to copy the function so factory for
    // association won't leak

    registry.register('service:-dom-builder', {
      create({
        bootOptions
      }) {
        let {
          _renderMode
        } = bootOptions;

        switch (_renderMode) {
          case 'serialize':
            return _node.serializeBuilder.bind(null);

          case 'rehydrate':
            return _runtime2.rehydrationBuilder.bind(null);

          default:
            return _runtime2.clientBuilder.bind(null);
        }
      }

    });
    registry.injection('service:-dom-builder', 'bootOptions', '-environment:main');
    registry.injection('renderer', 'builder', 'service:-dom-builder');
    registry.register((0, _container.privatize)(_templateObject4()), RootTemplate);
    registry.injection('renderer', 'rootTemplate', (0, _container.privatize)(_templateObject5()));
    registry.register('renderer:-dom', InteractiveRenderer);
    registry.register('renderer:-inert', InertRenderer);

    if (_browserEnvironment.hasDOM) {
      registry.injection('service:-glimmer-environment', 'updateOperations', 'service:-dom-changes');
    }

    registry.register('service:-dom-changes', {
      create({
        document
      }) {
        return new _runtime2.DOMChanges(document);
      }

    });
    registry.register('service:-dom-tree-construction', {
      create({
        document
      }) {
        let Implementation = _browserEnvironment.hasDOM ? _runtime2.DOMTreeConstruction : _node.NodeDOMTreeConstruction;
        return new Implementation(document);
      }

    });
  }

  function setupEngineRegistry(registry) {
    registry.register('view:-outlet', OutletView);
    registry.register('template:-outlet', OutletTemplate);
    registry.injection('view:-outlet', 'template', 'template:-outlet');
    registry.injection('service:-dom-changes', 'document', 'service:-document');
    registry.injection('service:-dom-tree-construction', 'document', 'service:-document');
    registry.register((0, _container.privatize)(_templateObject6()), ComponentTemplate);
    registry.register('service:-glimmer-environment', Environment$1);
    registry.register((0, _container.privatize)(_templateObject7()), TemplateCompiler);
    registry.injection((0, _container.privatize)(_templateObject8()), 'environment', '-environment:main');
    registry.injection('template', 'compiler', (0, _container.privatize)(_templateObject9()));
    registry.optionsForType('helper', {
      instantiate: false
    });
    registry.register('helper:loc', loc$1);
    registry.register('component:-text-field', TextField);
    registry.register('component:-checkbox', Checkbox);
    registry.register('component:link-to', LinkToComponent);

    if (true
    /* EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS */
    ) {
        registry.register('component:input', Input$1);
        registry.register('template:components/input', InputTemplate);
        registry.register('component:textarea', TextArea);
      } else {
      registry.register('component:-text-area', TextArea);
    }

    if (!_environment2.ENV._TEMPLATE_ONLY_GLIMMER_COMPONENTS) {
      registry.register((0, _container.privatize)(_templateObject10()), Component);
    }
  }

  function setComponentManager(stringOrFunction, obj) {
    let factory;

    if (_deprecatedFeatures.COMPONENT_MANAGER_STRING_LOOKUP && typeof stringOrFunction === 'string') {
      true && !false && (0, _debug.deprecate)('Passing the name of the component manager to "setupComponentManager" is deprecated. Please pass a function that produces an instance of the manager.', false, {
        id: 'deprecate-string-based-component-manager',
        until: '4.0.0',
        url: 'https://emberjs.com/deprecations/v3.x/#toc_component-manager-string-lookup'
      });

      factory = function (owner) {
        return owner.lookup("component-manager:" + stringOrFunction);
      };
    } else {
      factory = stringOrFunction;
    }

    return setManager({
      factory,
      internal: false,
      type: 'component'
    }, obj);
  }

  function getComponentManager(obj) {
    let wrapper = getManager(obj);

    if (wrapper && !wrapper.internal && wrapper.type === 'component') {
      return wrapper.factory;
    } else {
      return undefined;
    }
  }
  /**
    [Glimmer](https://github.com/tildeio/glimmer) is a templating engine used by Ember.js that is compatible with a subset of the [Handlebars](http://handlebarsjs.com/) syntax.
  
    ### Showing a property
  
    Templates manage the flow of an application's UI, and display state (through
    the DOM) to a user. For example, given a component with the property "name",
    that component's template can use the name in several ways:
  
    ```app/components/person-profile.js
    import Component from '@ember/component';
  
    export default Component.extend({
      name: 'Jill'
    });
    ```
  
    ```app/templates/components/person-profile.hbs
    {{name}}
    <div>{{name}}</div>
    <span data-name={{name}}></span>
    ```
  
    Any time the "name" property on the component changes, the DOM will be
    updated.
  
    Properties can be chained as well:
  
    ```handlebars
    {{aUserModel.name}}
    <div>{{listOfUsers.firstObject.name}}</div>
    ```
  
    ### Using Ember helpers
  
    When content is passed in mustaches `{{}}`, Ember will first try to find a helper
    or component with that name. For example, the `if` helper:
  
    ```handlebars
    {{if name "I have a name" "I have no name"}}
    <span data-has-name={{if name true}}></span>
    ```
  
    The returned value is placed where the `{{}}` is called. The above style is
    called "inline". A second style of helper usage is called "block". For example:
  
    ```handlebars
    {{#if name}}
    I have a name
    {{else}}
    I have no name
    {{/if}}
    ```
  
    The block form of helpers allows you to control how the UI is created based
    on the values of properties.
    A third form of helper is called "nested". For example here the concat
    helper will add " Doe" to a displayed name if the person has no last name:
  
    ```handlebars
    <span data-name={{concat firstName (
    if lastName (concat " " lastName) "Doe"
    )}}></span>
    ```
  
    Ember's built-in helpers are described under the [Ember.Templates.helpers](/api/ember/release/classes/Ember.Templates.helpers)
    namespace. Documentation on creating custom helpers can be found under
    [Helper](/api/classes/Ember.Helper.html).
  
    ### Invoking a Component
  
    Ember components represent state to the UI of an application. Further
    reading on components can be found under [Component](/api/ember/release/classes/Component).
  
    @module @ember/component
    @main @ember/component
    @public
   */

});
define("ember-leaflet/templates/div-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/9jtZyEI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[25,[\"isFastBoot\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"shouldRender\"]]],null,{\"statements\":[[4,\"in-element\",[[29,\"-clear-element\",[[25,[\"destinationElement\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[4,\"if\",[[25,[\"closePopup\"]]],null,{\"statements\":[[0,\"        \"],[15,1,[[29,\"action\",[[24,0,[]],[25,[\"closePopup\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/div-overlay.hbs"
    }
  });

  _exports.default = _default;
});